// @flow
import { all, fork } from "redux-saga/effects";
import getDashboardRequestSaga from "./sagas/getDashboard";
import getPermissionsRequestSaga from "./sagas/getPermissions";
import getWeeklyActivitySaga from "./sagas/getWeeklyActivity";

//companySaga
import getCompaniesSaga from "./sagas/company/getCompanies";
import createCompanySaga from "./sagas/company/createCompany";
import mergeCompanySaga from "./sagas/company/mergeCompany";
import deleteCompanySaga from "./sagas/company/deleteCompany";
import updateCompanySaga from "./sagas/company/updateCompany";

//role sagas
import getRolesRequestSaga from "./sagas/role/getRoles";
import createRoleSaga from "./sagas/role/createNewRole";
import updateRoleSaga from "./sagas/role/updateRole";
import deleteRoleSaga from "./sagas/role/deleteRole";
import getRoleByIdSaga from "./sagas/role/getRoleById";

//staff sagas
import getUsersSaga from "./sagas/staff/getUsers";
import updateUserSaga from "./sagas/staff/updateUser";
import deleteUserSaga from "./sagas/staff/deleteUser";
import createUserSaga from "./sagas/staff/createUser";
import importStaffSaga from "./sagas/staff/importStaff";
import getUserByIdSaga from "./sagas/staff/getUserById";

//card sagas
import getContractorCardsSaga from "./sagas/getContractorCards";
import createContractorCardSaga from "./sagas/createContractorCard";
import updateContractorCardSaga from "./sagas/updateContractorCard";

//site sagas
import getSitesRequestSaga from "./sagas/site/getSites";
import createSiteSaga from "./sagas/site/createSite";
import updateSiteSaga from "./sagas/site/updateSite";
import deleteSiteSaga from "./sagas/site/deleteSite";
import getSiteByIdSaga from "./sagas/site/getSiteById";
import getSiteStatsSaga from "./sagas/site/getSiteStats";
import getContractorsBySiteSaga from "./sagas/site/getContractorsBySite";
import getAllContractorsBySiteSaga from "./sagas/site/getAllContractorsBySite";

//people sagas
import updateContractorRequestSaga from "./sagas/people/updateContractor";
import getContractorListRequestSaga from "./sagas/people/getContractorList";
import getContactorStatsRequestSaga from "./sagas/people/getContractorStats";
import getStaffListRequestSaga from "./sagas/people/getStaffList";
import getVisitorListRequestSaga from "./sagas/people/getVisitorList";
import deleteContractorRequestSaga from "./sagas/people/deleteContractor";

//reports sagas
import getInductionsSaga from "./sagas/reports/getInductions";
import getAllInductionsSaga from "./sagas/reports/getAllInductions";
import getAttendanceReportsSaga from "./sagas/reports/getAttendance";
import getAllAttendaceReportsSaga from "./sagas/reports/getAllAttendace";
import getCardReportsSaga from "./sagas/reports/getCards";
import getAllCardReportsSaga from "./sagas/reports/getAllCards";
import getAttendanceDetailSaga from "./sagas/reports/getAttendanceDetail";

//regions
import getRegionsRequestSaga from "./sagas/regions/getRegions";
import deleteRegionSaga from "./sagas/regions/deleteRegions";
import editRegionRequestSaga from "./sagas/regions/editRegions";
import createRegionRequestSaga from "./sagas/regions/createRegions";

function* contractorListRequest() {
  yield all([
    fork(getContractorListRequestSaga),
    fork(getContactorStatsRequestSaga),
    fork(getDashboardRequestSaga),
    fork(getPermissionsRequestSaga),
    fork(getRolesRequestSaga),
    fork(createRoleSaga),
    fork(updateRoleSaga),
    fork(deleteRoleSaga),
    fork(getRoleByIdSaga),
    fork(getUsersSaga),
    fork(updateUserSaga),
    fork(deleteUserSaga),
    fork(createUserSaga),
    fork(importStaffSaga),
    fork(getUserByIdSaga),
    fork(getCompaniesSaga),
    fork(createCompanySaga),
    fork(mergeCompanySaga),
    fork(deleteCompanySaga),
    fork(updateCompanySaga),
    fork(getSitesRequestSaga),
    fork(createSiteSaga),
    fork(updateSiteSaga),
    fork(deleteSiteSaga),
    fork(getSiteByIdSaga),
    fork(getSiteStatsSaga),
    fork(getContractorsBySiteSaga),
    fork(updateContractorRequestSaga),
    fork(getInductionsSaga),
    fork(getAllInductionsSaga),
    fork(getInductionsSaga),
    fork(getAllInductionsSaga),
    fork(getAllAttendaceReportsSaga),
    fork(getAttendanceReportsSaga),
    fork(getCardReportsSaga),
    fork(getAllCardReportsSaga),
    fork(getWeeklyActivitySaga),
    fork(getAllContractorsBySiteSaga),
    fork(getVisitorListRequestSaga),
    fork(getStaffListRequestSaga),
    fork(getContractorCardsSaga),
    fork(getAttendanceDetailSaga),
    fork(createContractorCardSaga),
    fork(updateContractorCardSaga),
    fork(getRegionsRequestSaga),
    fork(deleteRegionSaga),
    fork(editRegionRequestSaga),
    fork(createRegionRequestSaga),
    fork(deleteContractorRequestSaga),
  ]);
}

export default contractorListRequest;
