import styled from "styled-components";

const ChoiseContainer = styled.div`
  height: 150px;
  width: 90%;
  background-image: url(${(props) => props.image});
  border-radius: 18px;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  align-items: center;
  position: relative;
  margin: 10px auto;
  &:before {
    border-radius: 18px;
    background-color: ${(props) => (props.checked ? "green" : "")};
    opacity: 0.4;
    content: "";
    display: block;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    width: 100%;
  }
`;

export { ChoiseContainer };
