// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";

function* updateCompany(data) {
  try {
    const params = new URLSearchParams();
    params.append("name", data.values.name);
    params.append("address1", data.values.address1);
    params.append("address2", data.values.address2);
    params.append("id", data.values.id);
    params.append("jdenumber", data.values.jdeNumber);

    const response = yield call(axios.put, `/companies/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_COMPANY_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: UPDATE_COMPANY_SUCCESS,
        data: response.data,
      });
    }
    yield call(success, `Company Updated Succesfully`);
    yield put(push(data.route));
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_ERROR,
      data: error,
    });
  }
}

export default function* updateCompanySaga() {
  yield takeLatest(UPDATE_COMPANY, updateCompany);
}
