import React from "react";
import ReactPlayer from "react-player/vimeo";
import { useIntl } from "react-intl";
import { isNil } from "lodash";

const VideoPlayer = ({ formatID }) => {
  const intl = useIntl();

  const url = intl.formatMessage({
    id: formatID,
  });

  if (isNil(url)) {
    return null;
  }
  return (
    <ReactPlayer
      style={{ marginTop: "20px" }}
      height={"282px"}
      width={"100%"}
      url={url}
      controls={true}
      playsinline={true}
      autopause={"true"}
    />
  );
};

export default VideoPlayer;
