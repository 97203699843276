import {
  ASSESMENT_REQUEST,
  SET_ASSESMENT_REQUEST,
  CREATE_ASSESMENT_REQUEST,
  RESET_ASSESMENT_REQUEST,
} from "./constants";

export const getAssesmentRequest = (payload) => {
  return {
    type: ASSESMENT_REQUEST,
    ...payload,
  };
};

export const setAssesmentRequest = (payload) => {
  return {
    type: SET_ASSESMENT_REQUEST,
    ...payload,
  };
};
export const createAssesmentRequest = (payload) => {
  return {
    type: CREATE_ASSESMENT_REQUEST,
    ...payload,
  };
};

export const resetAssesmentResult = (payload) => {
  return {
    type: RESET_ASSESMENT_REQUEST,
    ...payload,
  };
};
