import React from "react";
import Contractors from "./Contractors";
import Staff from "./Staff";
import Visitors from "./Visitors";

import Menu from "components/Supervisor/menu";
import classes from "./people.module.scss";

import { Route, Switch, Redirect } from "react-router-dom";

const Settings = () => {
  const pages = [
    {
      title: "Contractors",
      active: true,
      component: <Contractors />,
      pathName: "/supervisor-people-contractors",
      path: "contractors",
    },
    {
      title: "Staff",
      active: false,
      component: <Staff />,
      pathName: "/supervisor-people-staff",
      path: "staff",
    },
    {
      title: "Visitors",
      active: false,
      component: <Visitors />,
      pathName: "/supervisor-people-visitors",
      path: "visitors",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Menu pages={pages} />
        <div className={classes.section}>
          <Switch>
            {
              /* Redirect from supervisor root URL to /supervisor translations */
              <Redirect
                exact={true}
                from="/supervisor-people"
                to="/supervisor-people-contractors"
              />
            }
            <Route path="/supervisor-people-contractors" exact={true}>
              {({ history, match }) => <Contractors history={history} />}
            </Route>
            <Route path="/supervisor-people-visitors" exact={true}>
              {({ history, match }) => <Visitors history={history} />}
            </Route>

            <Route path="/supervisor-people-staff">
              {({ history, match }) => <Staff history={history} exact={true} />}
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Settings;
