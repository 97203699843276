import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//styles
import classes from "./company.module.scss";

//actions
import {
  getCompaniesRequest,
  deleteCompany,
  mergeCompany,
} from "store/supervisor/actions";

//selectors
import {
  makeSelectCompanies,
  makeSelectLoading,
} from "store/supervisor/selector";

//components
import Table from "components/Supervisor/table";
import Button from "components/Supervisor/button";
import DeleteModal from "components/Supervisor/modal";
import MergeModal from "components/Supervisor/modal/merge";
import Pagination from "components/Supervisor/pagination";

//assets
import DeleteIcon from "assets/supervisorIcons/deleteIcon.js";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";

//utils
import { delay } from "utils/helper";
import { useSortableData } from "utils/hooks";
import { isNil } from "lodash";
import queryString from "query-string";

const Company = ({
  companies,
  getCompanies,
  loading,
  deleteCompanyReq,
  history,
  mergeCompanyReq,
}) => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  const [mergeModal, setMergeModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  const [companyOptions, setCompanyOptions] = useState([]);
  const [rawCompanies, setRawCompanies] = useState([]);
  const { requestSort, sortConfig } = useSortableData(rawCompanies, {
    key: "name",
    direction: "ascending",
  });
  const [selectedPage, setSelectedPage] = useState(1);

  const [searchState, setSearchState] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const parsed = queryString.parse(window.location.search);

  useEffect(() => {
    if (parsed?.direction !== "ascending") {
      requestSort("name");
    }

    if (!isNil(parsed?.direction)) {
      getCompanies({
        sort:
          parsed?.direction?.toString() === "ascending"
            ? "-name"
            : parsed?.direction?.toString() === "descending"
            ? "name"
            : null,
        search: parsed?.search,
        page: parsed.page ? Number(parsed.page) : 1,
      });
      if (!isNil(parsed.page)) {
        setSelectedPage(Number(parsed.page));
      }
    } else {
      getCompanies({
        sort: "name",
        search: "",
        page: 1,
      });
    }
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getCompanies({ search: event.target.value });
    }
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) =>
        history.push(
          `/supervisor-company-edit?id=${val.id}&key=${sortConfig?.key}&direction=${sortConfig?.direction}&search=${searchState}&page=${selectedPage}`
        ),
    },
    {
      name: "Delete",
      action: (val) => {
        setDeleteModal({
          open: true,
          type: "delete",
          action: () => {
            deleteCompanyReq({
              id: val.id,
            });
            setDeleteModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setDeleteModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        });
      },
    },
  ];

  const companyTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: a.name,
            id: a.id,
            raw: item,
          },
          {
            value: a.jdenumber,
          },
          {
            value: a.address1,
          },
          {
            value: a.address2,
          },
        ],
      };
    });

    const labels = [
      { label: "Name", sortable: true, key: "name" },
      { label: "JDE Number" },
      { label: "Address 1" },
      { label: "Address 2" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  const sortBy = (key) => {
    if (!isNil(sortConfig)) {
      getCompanies({
        search: searchState,
        page: selectedPage,
        sort:
          sortConfig.direction.toString() === "descending"
            ? `-${key}`
            : `${key}`,
      });
    }
  };

  useEffect(() => {
    if (companies) {
      setCompanyList(companyTableData(companies.results));
      setRawCompanies(companies.results);

      const newRows = companies.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      const options = companies.results.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCompanyOptions(options);
    }
  }, [companies]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getCompanies({ search: e.target.value });
    }
  };

  return (
    <>
      <div className={classes.companyContainer}>
        <h1 className={classes.titleHead}>Company</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
          }}
        >
          {selectedRows &&
            selectedRows.filter((item) => item.checked).length > 0 && (
              <Button
                onClick={() => {
                  setDeleteModal({
                    open: true,
                    type: "delete",
                    action: () => {
                      selectedRows
                        .filter((item) => item.checked)
                        .forEach((item, index) => {
                          deleteCompanyReq({
                            id: item.id,
                          });
                          delay(2000);
                        });
                      setDeleteModal({
                        open: false,
                        type: null,
                        action: null,
                        cancel: null,
                      });
                    },
                    cancel: () =>
                      setDeleteModal({
                        open: false,
                        type: null,
                        action: null,
                        cancel: null,
                      }),
                  });
                }}
                icon={() => {
                  return <DeleteIcon />;
                }}
                text={"Delete"}
                color={"#E26C82"}
              />
            )}
          {selectedRows.filter((item) => item.checked).length === 2 && (
            <Button
              onClick={() => {
                setMergeModal({
                  open: true,
                  action: (val) => mergeCompanyReq(val),
                  callback: () => {
                    getCompanies({
                      page: selectedPage,
                      search: searchState,
                      sort:
                        !isNil(sortConfig) &&
                        sortConfig?.direction?.toString() === "descending"
                          ? `name`
                          : `-name`,
                    });
                  },
                  cancel: () =>
                    setMergeModal({
                      open: false,
                      type: null,
                      action: null,
                      cancel: null,
                    }),
                });
              }}
              icon={() => {
                return <PlusIcon />;
              }}
              text={"Merge"}
            />
          )}
          <Button
            width={"200px"}
            onClick={() => history.push("/supervisor-company-create")}
            icon={() => {
              return <PlusIcon />;
            }}
            text={"New Company"}
          />
        </div>
        <div className={classes.companyContainer}>
          <Table
            width={"100%"}
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={companyList}
            rowActions={rowActions}
            clickable={true}
            sortByDesc={(val) => {
              requestSort(val);
              sortBy(val);
            }}
            sortConfig={sortConfig}
          />
        </div>
        <>
          <Pagination
            totalCount={companies?.count}
            itemsPerPage={companies?.items_per_page}
            onChange={(val) => {
              getCompanies({
                page: val,
                search: searchState,
                sort:
                  !isNil(sortConfig) &&
                  sortConfig?.direction?.toString() === "descending"
                    ? `${sortConfig?.key}`
                    : `-${sortConfig?.key}`,
              });
              setSelectedPage(val);
            }}
            currentPage={selectedPage}
          />
        </>
        <MergeModal
          open={mergeModal.open}
          cancel={mergeModal.cancel}
          action={mergeModal.action}
          type={mergeModal.type}
          callback={mergeModal.callback}
          companyOptions={companyOptions}
          selectedRows={selectedRows?.filter((item) => item.checked)}
          getCompanies={() => getCompanies()}
        />
        <DeleteModal
          open={deleteModal.open}
          cancel={deleteModal.cancel}
          action={deleteModal.action}
          type={deleteModal.type}
          loading={loading}
          deleteTitle={"You are about to delete a company."}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  companies: makeSelectCompanies(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    deleteCompanyReq: (val) => dispatch(deleteCompany(val)),
    mergeCompanyReq: (val) => dispatch(mergeCompany(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
