import React, { useEffect, useState } from "react";
import classes from "./input.module.scss";
import { useIntl } from "react-intl";
import cn from "classnames";
import { ClickOutside } from "utils/helper";
import { isEmpty, isNil } from "lodash";

const Input = (props) => {
  const intl = useIntl();

  const placeholder = intl.formatMessage({
    id: props.placeholder,
    defaultMessage: props.defaultMessage,
  });
  const label = intl.formatMessage({
    id: props.label,
    defaultMessage: props.defaultLabel,
  });

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!isNil(props?.value?.id)) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [props.value]);

  return (
    <ClickOutside onClick={() => setOpen(false)}>
      <div className={classes.Container}>
        <label className={classes.BlockLabel}>{label}</label>
        <input
          className={`${classes.BlockInput} ${props.error && classes.error}`}
          type={props.type}
          placeholder={placeholder}
          onChange={(e) => {
            props.onChange({
              id: null,
              value: e.target.value,
            });
          }}
          value={props.value.value}
          spellCheck="false"
          autoComplete="off"
        />
        {!isNil(props.data) && !isEmpty(props.data) && (
          <div
            className={cn(
              classes.select,
              props.data && props.value.value && open && classes.open
            )}
          >
            <ul>
              {props?.data?.map((item) => (
                <li
                  onClick={() => {
                    props.onChange({
                      id: item.id,
                      value: item.name,
                    });
                    setOpen(false);
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        )}
        {props.error && (
          <span className={classes.errorMessage}>
            Please provide valid information.
          </span>
        )}
      </div>
    </ClickOutside>
  );
};

export default Input;
