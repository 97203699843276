// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CHECK_IN_STATUS_REQUEST,
  CHECK_IN_STATUS_REQUEST_SUCCESS,
  CHECK_IN_STATUS_REQUEST_ERROR,
  CHECK_IN_REQUEST_ERROR,
  CHECK_IN_REQUEST_SUCCESS,
  CHECK_OUT_REQUEST_SUCCESS,
} from "../constants";
import axios from "../../../services/public";
import { isNil } from "lodash";
import { push } from "connected-react-router";

function* checkInStatus(data) {
  try {
    const values = data.payload;
    const params = new URLSearchParams();
    params.append("site", values.site);
    params.append("checkin_id", values.id);
    const token = localStorage.getItem("AUTH_TOKEN");
    const access_token = window.localStorage.getItem("access_token");

    delete axios.defaults.headers.common["X-Access-Token"];
    delete axios.defaults.headers.common["X-Token"];

    if (!isNil(token) && isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = token;
    } else {
      axios.defaults.headers.common["X-Access-Token"] = access_token;
    }
    const response = yield call(axios.post, "/check-in-status/", params);
    if (response.data.type === 0) {
      if (!isNil(values?.status)) {
        yield put({
          type: CHECK_IN_REQUEST_SUCCESS,
          data: { check_in: response?.data?.checkin, ...values?.status },
        });
      } else {
        yield put({
          type: CHECK_IN_REQUEST_ERROR,
          data: { ...values?.error },
        });
      }
    } else {
      const obj = {
        check_in: response?.data?.check_in,
        duration: response.data.duration,
        check_out: response?.data?.check_out,
        message: "User checked out",
        messageId: 19,
        site: response.data.site,
        success: 1,
        type: "checked_in",
      };

      yield put({
        type: CHECK_OUT_REQUEST_SUCCESS,
        data: obj,
      });

      yield put(push(`/check-out?site=${values.site}&id=${values.id}`));
    }

    yield put({
      type: CHECK_IN_STATUS_REQUEST_SUCCESS,
      data: response?.data,
    });
  } catch (error) {
    yield put({
      type: CHECK_IN_STATUS_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* checkInStatusSaga() {
  yield takeLatest(CHECK_IN_STATUS_REQUEST, checkInStatus);
}
