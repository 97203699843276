import React, { useEffect, useRef } from "react";
import { format, parseISO, intervalToDuration } from "date-fns";
import { isNil } from "lodash";
export function createMarkup(val) {
  return { __html: val };
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const incrementString = function (str, rawList) {
  var regexp = /\d+$/g;
  if (str.match(regexp)) {
    var trailingNumbers = str.match(regexp)[0];
    var number = parseInt(trailingNumbers);
    number += 1;

    // Replace the trailing numbers and put back incremented
    str = str.replace(/\d+$/g, "");
    str += number;
  } else {
    str += " Copy 1";
  }
  return rawList.filter((item) => item.name === str).length > 0
    ? `${str} Copy 1`
    : str;
};

export const msToTime = (ms) => {
  const seconds = ms / 1000;
  let hours = Math.floor(seconds / (60 * 60));

  let divisor_for_minutes = seconds % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let sec = Math.ceil(divisor_for_seconds);

  let obj = {
    h: hours < 10 ? `0${hours}` : hours,
    m: minutes < 10 ? `0${minutes}` : minutes,
    s: sec < 10 ? `0${sec}` : sec,
  };

  return `${obj.h}:${obj.m}:${obj.s}`;
};

const isInDom = (obj) => {
  return Boolean(obj.closest("body"));
};

const hasParent = (element, root) => {
  return root && root.contains(element) && isInDom(element);
};

export const ClickOutside = ({ active = true, onClick, children }) => {
  const innerRef = useRef();

  const handleClick = (event) => {
    if (!hasParent(event.target, innerRef?.current)) {
      if (typeof onClick === "function") {
        onClick(event);
      }
    }
    if (event.keyCode === 27) {
      onClick(event);
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClick);
      document.addEventListener("touchstart", handleClick);
      document.addEventListener("keydown", handleClick);
    }

    return () => {
      if (active) {
        document.removeEventListener("mousedown", handleClick);
        document.removeEventListener("touchstart", handleClick);
        document.removeEventListener("keydown", handleClick);
      }
    };
  });

  return React.cloneElement(children, { ref: innerRef });
};

export const formatDate = (val) =>
  !isNil(val) ? format(parseISO(val), `dd/MM/yyyy`) : "-";

export const formatTime = (val) =>
  !isNil(val) ? format(parseISO(val), `hh:mm:ss`) : "-";

export const getFilterValues = (options) => {
  const values = options?.filter((a) => a.checked)?.map((item) => item.value);

  return values?.length ? values : null;
};

export const secondToHours = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const hours = duration.hours < 10 ? `0${duration.hours}` : duration.hours;
  const minutes =
    duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes;
  const sec = duration.seconds < 10 ? `0${duration.seconds}` : duration.seconds;

  return `${hours}:${minutes}:${sec}`;
};
