// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  LOGIN_REQUEST_ERROR,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST,
} from "../constants";
import { push } from "connected-react-router";
import axios from "../../../services/api";
import { error } from "../../../main.js";
import { isNil } from "lodash";
function* loginRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("email", values.email);
    params.append("first_name", values.firstName);
    params.append("last_name", values.lastName);

    if (!isNil(values.company.id)) {
      params.append("company_relation", values.company.id);
    } else {
      params.append("company", values.company.value);
    }
    params.append("phone", values.phoneNumber);
    params.append("language", values.language ? values.language : "en");
    params.append("user_type", values.userType);

    delete axios.defaults.headers.common["X-Token"];
    delete axios.defaults.headers.common["X-Access-Token"];

    const response = yield call(axios.post, "/dummy-login/", params);
    if (response.data.error) {
      yield put({
        type: LOGIN_REQUEST_ERROR,
        data: response.data.error,
      });
      yield call(error, `${response.data.error}`);
    } else {
      const token = response.data.token;
      axios.defaults.headers.common["X-Token"] = `${token}`;
      yield call([localStorage, localStorage.setItem], "AUTH_TOKEN", token);
      yield put({
        type: LOGIN_REQUEST_SUCCESS,
        data: response.data,
      });

      if (!isNil(values.site?.site) && !isNil(values.siteManager)) {
        yield put(
          push(
            `/site-induction?site=${values.site.site}&sitemanager=${values.siteManager}`
          )
        );
      } else if (!isNil(values.site?.site)) {
        yield put(push(`/${values.redirect}?site=${values.site.site}`));
      } else {
        yield put(push("/post-login"));
      }
    }
  } catch (error) {
    yield put({
      type: LOGIN_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* loginRequestSaga() {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
}
