import React from "react";

import MenuIcon from "./menuIcon";
import classes from "./header.module.scss";
import DrawerNavigation from "./drawerNavigation";
import { withRouter, Link } from "react-router-dom";
import { Keyframes, animated } from "react-spring/renderprops";
import delay from "delay";
import Blur from "../blur";

import { selectLanguage } from "store/language/actions";

import { setMeRequest } from "store/auth/actions";

import {
  makeSelectLanguages,
  makeSelectLoading,
  makeSelectSelectedLanguage,
} from "store/language/selector";
import { makeSelectUser } from "store/auth/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const Sidebar =
  Keyframes &&
  Keyframes.Spring({
    // Slots can take arrays/chains,
    peek: [
      { x: 0, from: { x: 100 }, delay: 500 },
      { x: -100, delay: 800 },
    ],
    // single items,
    open: { delay: 0, x: 0 },
    // or async functions with side-effects
    close: async (call) => {
      await delay(0);
      await call({ delay: 0, x: 110 });
    },
  });

//const MainLogo = process.env.PUBLIC_URL + "/logo.png";
const MainLogo = process.env.PUBLIC_URL + "/logo/frl_logo.png";

const Header = ({
  open,
  transparent,
  setOpen,
  menu,
  languages,
  selectedLanguage,
  setSelectLanguage,
  user,
  setMe,
}) => {
  const state = open ? "open" : "close";

  return (
    <div
      style={{ background: transparent && "transparent", position: "relative" }}
      className={open ? classes.open : classes.headerContainer}
    >
      <Blur
        style={{ display: "flex" }}
        radius={open ? "5px" : "0"}
        transition="400ms"
      >
        <Link to="/">
          <img alt={"logo"} className={classes.headerLogo} src={MainLogo} />
        </Link>
      </Blur>

      {menu && (
        <React.Fragment>
          <MenuIcon onClick={() => setOpen(true)} />
          <Sidebar native state={state}>
            {({ x }) => (
              <animated.div
                className={classes.DrawerContainer}
                style={{
                  transform: x.interpolate((x) => `translate3d(${x}%,0,0)`),
                }}
              >
                <DrawerNavigation show={open} action={() => setOpen(false)} />
              </animated.div>
            )}
          </Sidebar>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  loading: makeSelectLoading(),
  selectedLanguage: makeSelectSelectedLanguage(),
  user: makeSelectUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectLanguage: (val) => dispatch(selectLanguage(val)),
    setMe: (val) => dispatch(setMeRequest(val)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
