import React, { useEffect, useState, useRef } from "react";
//styling
import classes from "../reporting.module.scss";

//components
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";
import Filters from "./filters";
import DateRangeFilter from "components/Supervisor/dateRangeFilter";
import ReactLoading from "react-loading";

//redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getCompaniesRequest,
  getCardReportsRequest,
  getAllCardReportsRequest,
  getUsersRequest,
  getContactorListRequest,
  setDateFilterReq,
} from "../../../../store/supervisor/actions";
import {
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectAllReportsLoading,
  makeSelectAllCardsReports,
  makeSelectCardReports,
  makeSelectUsers,
  makeSelectContractorList,
  makeSelectDateFilter,
  makeSelectFilterLoading,
} from "../../../../store/supervisor/selector";

//utils
import { isNil, isEmpty } from "lodash";
import { format, getUnixTime } from "date-fns";
import { CSVLink } from "react-csv";
import { useSortableData } from "../../../../utils/hooks";
import { getFilterValues } from "../../../../utils/helper";
import { typeDefault, reportingTableData } from "./data";

const CardsReporting = ({
  getReports,
  reports,
  companies,
  getCompanies,
  loading,
  getAllReports,
  aloading,
  siteManagers,
  getManagers,
  menu,
  contractors,
  getContractorList,
  filterDate,
  setDateFilter,
  filterLoading,
}) => {
  const [reportingList, setReportingList] = useState([]);

  // const [filterDate, setFilterDate] = useState();

  const [companyOptions, setCompanyOptions] = useState([]);
  const [contractorOptions, setContractorOptions] = useState([]);
  const [issuerOptions, setIssuerOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const [selectedPage, setSelectedPage] = useState(1);

  const [csvData, setcsvData] = useState([]);

  const csvLink = useRef();

  const [searchState, setSearchState] = useState("");

  const [rawReports, setRawReports] = useState([]);

  useEffect(() => {
    if (!isNil(companies?.results)) {
      const opts = companies.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });

      const checkedOptions = companyOptions?.filter((item) => item.checked);

      if (checkedOptions?.length > 0) {
        const filteredNewOptions = opts.filter((item) =>
          isEmpty(checkedOptions.find((a) => item.id !== a.id))
        );

        let newOpts = [...filteredNewOptions, ...checkedOptions];
        setCompanyOptions(newOpts);
      } else {
        setCompanyOptions(opts);
      }
    }
  }, [companies]);

  useEffect(() => {
    if (!isNil(siteManagers)) {
      const options = siteManagers?.results?.map((item) => {
        return {
          value: item.id,
          label: `${item.first_name} ${item.last_name} - ${item.email}`,
          checked: false,
        };
      });
      const checkedOptions = issuerOptions?.filter((item) => item.checked);

      if (checkedOptions.length > 0) {
        const filteredNewOptions = options.filter((item) =>
          isEmpty(checkedOptions.find((a) => item.id !== a.id))
        );

        let newOpts = [...filteredNewOptions, ...checkedOptions];
        setIssuerOptions(newOpts);
      } else {
        setIssuerOptions(options);
      }
    }
  }, [siteManagers]);

  useEffect(() => {
    if (!isNil(contractors)) {
      const options = contractors?.data?.map((item) => {
        return {
          value: item.id,
          label: `${item.first_name} ${item.last_name} - ${item.email}`,
          checked: false,
        };
      });

      const checkedOptions = contractorOptions?.filter((item) => item.checked);

      if (checkedOptions?.length > 0) {
        const filteredNewOptions = options.filter((item) =>
          isEmpty(checkedOptions.find((a) => item.id !== a.id))
        );

        let newOpts = [...filteredNewOptions, ...checkedOptions];
        setContractorOptions(newOpts);
      } else {
        setContractorOptions(options);
      }
    }
  }, [contractors]);

  useEffect(() => {
    if (!isNil(typeDefault)) {
      setTypeOptions(typeDefault);
    }
  }, [typeDefault]);

  const runFilters = (companyOpt, contractorOpt, issuerOpt, typeOpt) => {
    getReports({
      search: searchState,
      enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
      startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
      companyIds: getFilterValues(companyOpt),
      contractorIds: getFilterValues(contractorOpt),
      issuerIds: getFilterValues(issuerOpt),
      statusType: getFilterValues(typeOpt),
    });
  };

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getReports({
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        companyIds: getFilterValues(companyOptions),
        contractorIds: getFilterValues(contractorOptions),
        issuerIds: getFilterValues(issuerOptions),
        statusType: getFilterValues(typeOptions),
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
      setSearchState("");
      setSelectedPage(1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getReports({
        search: event.target.value,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        companyIds: getFilterValues(companyOptions),
        contractorIds: getFilterValues(contractorOptions),
        issuerIds: getFilterValues(issuerOptions),
        type: getFilterValues(typeOptions),
      });
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const printReports = async (data) => {
    const { labels, columns } = reportingTableData(data);
    const labelData = labels?.map((item) => item.label);
    const columnData = columns?.map((item) => item.values);
    const mergedData = [labelData, ...columnData];

    const handledCsvData = mergedData?.map((item, index) => {
      if (index === 0) {
        return item;
      } else return item.map((item) => item.value);
    });

    setcsvData(handledCsvData);
    await delay(1000);

    csvLink.current.link.click();
  };

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
  });

  useEffect(() => {
    if (!isNil(reports)) {
      setReportingList(reportingTableData(reports.results));
      setRawReports(reports.results);
    }
  }, [reports]);

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
    //api call
    getCompanies();
    getManagers();
    getContractorList({
      page: 1,
      searchItem: "",
      sort: "",
    });
  }, []);

  useEffect(() => {
    if (filterDate) {
      getReports({
        enddate: filterDate?.enddate,
        startdate: filterDate?.startdate,
        search: searchState,
        companyIds: getFilterValues(companyOptions),
        contractorIds: getFilterValues(contractorOptions),
        issuerIds: getFilterValues(issuerOptions),
        statusType: getFilterValues(typeOptions),
      });
    }
  }, [filterDate]);

  const { requestSort, sortConfig } = useSortableData(rawReports);
  const sortBy = (key) => {
    requestSort(key);
    if (!isNil(sortConfig)) {
      getReports({
        search: searchState,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        companyIds: getFilterValues(companyOptions),
        contractorIds: getFilterValues(contractorOptions),
        issuerIds: getFilterValues(issuerOptions),
        statusType: getFilterValues(typeOptions),
        sort:
          sortConfig.direction.toString() === "descending" ? `-${key}` : key,
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headContainer}>
        <DateRangeFilter
          value={filterDate}
          onChange={(val) => {
            setDateFilter({
              enddate: getUnixTime(val[0].endDate),
              startdate: getUnixTime(val[0].startDate),
            });
          }}
        />
      </div>
      <div>{menu}</div>

      <div className={classes.filterContainer}>
        <Filters
          contractorOptions={contractorOptions}
          setContractorOptions={setContractorOptions}
          issuerOptions={issuerOptions}
          setIssuerOptions={setIssuerOptions}
          companyOptions={companyOptions}
          setCompanyOptions={setCompanyOptions}
          typeOptions={typeOptions}
          setTypeOptions={setTypeOptions}
          runFilters={runFilters}
          getCompanies={getCompanies}
          getContractorList={getContractorList}
          getManagers={getManagers}
          loading={filterLoading}
        />
        <div className={classes.indicatorWrapper}>
          <span className={classes.indicatorTitle}>Total Cards Issued</span>
          <div className={classes.indicatorType1}>
            <span>{reports?.type_1_count}</span>
          </div>
          <div className={classes.indicatorType2}>
            <span>{reports?.type_2_count}</span>
          </div>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          handleKeyDown={handleKeyDown}
          isSearchable={true}
          searchState={searchState}
          loading={loading}
          onSearch={(e) => onSearch(e)}
          data={reportingList}
          sortByDesc={(val) => sortBy(val)}
          sortConfig={sortConfig}
        />
      </div>
      <Pagination
        totalCount={reports?.count}
        itemsPerPage={reports?.items_per_page}
        currentPage={selectedPage}
        onChange={(val) => {
          getReports({
            page: val,
            search: searchState,
            enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
            companyIds: getFilterValues(companyOptions),
            contractorIds: getFilterValues(contractorOptions),
            issuerIds: getFilterValues(issuerOptions),
            statusType: getFilterValues(typeOptions),
            startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
            sort:
              !isNil(sortConfig) &&
              sortConfig?.direction?.toString() === "descending"
                ? `-${sortConfig?.key}`
                : sortConfig?.key,
          });
          setSelectedPage(val);
        }}
      />

      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
            <button
              disabled={aloading}
              className={classes.darkButton}
              onClick={() => {
                getAllReports({
                  callBack: (val) => printReports(val),
                  search: searchState,
                  enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
                  startdate:
                    !isNil(filterDate?.startdate) && filterDate.startdate,
                  companyIds: getFilterValues(companyOptions),
                  contractorIds: getFilterValues(contractorOptions),
                  issuerIds: getFilterValues(issuerOptions),
                  statusType: getFilterValues(typeOptions),
                  sort:
                    !isNil(sortConfig) &&
                    sortConfig?.direction?.toString() === "descending"
                      ? `-${sortConfig?.key}`
                      : sortConfig?.key,
                });
              }}
            >
              {aloading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#40507e"}
                  height={30}
                  width={30}
                />
              ) : (
                "Export"
              )}
            </button>
            <CSVLink
              filename={`${
                !isNil(filterDate) &&
                !isNil(filterDate?.startdate) &&
                !isNil(filterDate?.enddate)
                  ? `${format(
                      new Date(filterDate?.startdate * 1000),
                      "yyyyMMdd"
                    )}-${format(
                      new Date(filterDate?.enddate * 1000),
                      "yyyyMMdd"
                    )}`
                  : "reports"
              }.csv`}
              className="hidden"
              data={csvData}
              ref={csvLink}
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  reports: makeSelectCardReports(),
  companies: makeSelectCompanies(),
  allReports: makeSelectAllCardsReports(),
  aloading: makeSelectAllReportsLoading(),
  siteManagers: makeSelectUsers(),
  contractors: makeSelectContractorList(),
  filterDate: makeSelectDateFilter(),
  filterLoading: makeSelectFilterLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getReports: (val) => dispatch(getCardReportsRequest(val)),
    getAllReports: (val) => dispatch(getAllCardReportsRequest(val)),
    getManagers: (val) => dispatch(getUsersRequest(val)),
    getContractorList: (val) => dispatch(getContactorListRequest(val)),
    setDateFilter: (val) => dispatch(setDateFilterReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsReporting);
