// @flow
import { put, call, takeEvery } from "redux-saga/effects";
import {
  DELETE_CONTRACTOR_REQUEST,
  DELETE_CONTRACTOR_REQUEST_SUCCESS,
  DELETE_CONTRACTOR_REQUEST_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success, error as err } from "../../../../main.js";
import { isNil } from "lodash";

function deleteReq(data) {
  return axios.delete("/contractors/", { data: { id: data.id } });
}

function* deleteContractor(data) {
  try {
    const response = yield call(deleteReq, data);
    yield put({
      type: DELETE_CONTRACTOR_REQUEST_SUCCESS,
      data: { data, response },
    });

    yield put(push(data.redirectUrl));
    yield call(success, `Contractor Deleted Succesfully`);
  } catch (error) {
    yield call(
      err,
      !isNil(error?.response?.data?.detail)
        ? error.response.data.detail
        : "Something went wrong"
    );
    console.log({ error });
    yield put({
      type: DELETE_CONTRACTOR_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* deleteContractorSaga() {
  yield takeEvery(DELETE_CONTRACTOR_REQUEST, deleteContractor);
}
