import { combineReducers } from "redux";
import Auth from "./auth/reducer";
import Layout from "./layouts/reducer";
import Assesment from "./assesment/reducer";
import Progress from "./progress/reducer";
import Steps from "./steps/reducer";
import Supervisor from "./supervisor/reducer";
import Language from "./language/reducer";
import Time from "./time/reducer";

import { connectRouter } from "connected-react-router";

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    layout: Layout,
    assesment: Assesment,
    progress: Progress,
    steps: Steps,
    supervisor: Supervisor,
    sidebarShow: changeState,
    language: Language,
    time: Time,
  });

export default createRootReducer;
