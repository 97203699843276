import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//action start
import {
  postLoginRequest,
  checkInRequest,
  getMeRequest,
} from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectReason,
} from "store/auth/selector";
import { createStructuredSelector } from "reselect";
//selectors end

//components start
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import ItemCheckbox from "components/UI/itemCheckbox";
import { Formik, Form, Field } from "formik";
//component end

import classes from "./postLogin.module.scss";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";

const PostLogin = (props) => {
  const options = [
    {
      id: 1,
      title: "I have a current Sitesafe Card",
      checked: false,
      formatID: "postlogin.card.1",
      hidden: false
    },
    {
      id: 2,
      title: "I have read & understood my Company Task Analysis",
      checked: false,
      formatID: "postlogin.card.2",
      hidden: false
    },
  ];
  const [cards, setCards] = useState(options);
  const [site, setSite] = useState(undefined);
  const [isInduction, setIsInduction] = useState(undefined);
  const [redirectRoute, setRedirectRoute] = useState();

  useEffect(() => {
    const parsed = queryString?.parse(window.location.search);
    setSite(parsed.site);
    setRedirectRoute(parsed.redirect);
    //setIsInduction(parsed.induction);
    
    //halilk: if this is SSO, disable the first option
    if(parsed.isStaff == true)
    {
      //halilk: hide it but also make it checked for validators
      const newOptions = cards.map((item) => {
        if (item.id === 1) {
          return {
            id: item.id,
            title: item.title,
            checked: true,
            formatID: item.formatID,
            hidden: true
          };
        } else {
          return item;
        }
      });
      setCards(newOptions);
    }

    props.getMe();
  }, []);

  const setCheckBoxHandler = (val) => {
    const newOptions = cards.map((item) => {
      if (item.id === val) {
        return {
          id: item.id,
          title: item.title,
          checked: !item.checked,
          formatID: item.formatID,
          hidden: item.hidden
        };
      } else {
        return item;
      }
    });
    setCards(newOptions);
  };

  const onSubmit = () => {
    const validator = cards.filter((item) => {
      if (item.checked === true) {
        return null;
      } else {
        return item.id;
      }
    });
    return props.onSubmitForm({
      items: validator,
      site: site,
      redirect: redirectRoute,
      callBack: () =>
        props.checkIn({
          site: site,
          token: props.user.token,
          login: true,
        }),
    });
  };

  return (
    <React.Fragment>
      <Formik enableReinitialize initialValues={cards} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <Form className={classes.Form}>
              <span className={classes.Heading}>
                <FormattedMessage id={"postlogin.title"} />
              </span>
              <div className={classes.ItemContainer}>
                {values.map((item) => (
                  item.hidden === false && (
                  <Field
                    value={item.checked}
                    key={item.id}
                    onPress={() => setCheckBoxHandler(item.id)}
                    onClick={() => setCheckBoxHandler(item.id)}
                    title={item.title}
                    checked={item.checked}
                    formatID={item.formatID}
                    component={ItemCheckbox}
                  />
                  )
                ))}
              </div>
              <Footer>
                <Button
                  formatID={"postlogin.button"}
                  id="apply-button"
                  type="submit"
                  text="Next"
                />
              </Footer>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  reason: makeSelectReason(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(postLoginRequest(val)),
    checkIn: (val) => dispatch(checkInRequest(val)),
    getMe: (val) => dispatch(getMeRequest(val)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PostLogin));
