import React, { useEffect } from "react";
import classes from "./cardsystem.module.scss";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import { Link } from "react-router-dom";

import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";
import { makeSelectProgress } from "store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
const CardSystemImage = process.env.PUBLIC_URL + "/cardsystem.jpg";

const CardSystem = (props) => {
  useEffect(() => {
    props.setSection({ section: 9 });
    props.getProgress();
  }, []);

  useEffect(() => {
    if (props.progressData.section < 9) {
      props.setProgress({ section: 9, progress: 0, induction_id: 2 });
    }
  }, [props.progressData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "45px",
        position: "relative",
      }}
    >
      <span className={classes.Title}>
        <FormattedMessage id={"cardsystem.title"} />
      </span>
      <span className={classes.Content}>
        <FormattedMessage id={"cardsystem.description.1"} />
      </span>
      <span className={classes.Content}>
        <FormattedMessage id={"cardsystem.description.2"} />
      </span>
      <img
        className={classes.Image}
        src={CardSystemImage}
        alt={"card system"}
      />
      <Footer>
        <Link className={classes.Link} to="/site-rules">
          <Button
            formatID={"cardsystem.button"}
            id="apply-button"
            text="Next"
            type="submit"
          />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardSystem);
