import produce, { enableES5 } from "immer";
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_ERROR,
  LOGIN_REQUEST_SUCCESS,
  GET_ME_REQUEST,
  GET_ME_REQUEST_ERROR,
  GET_ME_REQUEST_SUCCESS,
  SUPERVISOR_LOGIN_REQUEST,
  SUPERVISOR_LOGIN_REQUEST_ERROR,
  SUPERVISOR_LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_INDUCTION_LOGIN_REQUEST_SUCCESS,
  POST_LOGIN_REQUEST,
  POST_LOGIN_REQUEST_SUCCESS,
  POST_LOGIN_REQUEST_FAIL,
  POST_LOGIN_REQUEST_ERROR,
  SET_ME_REQUEST,
  SET_ME_REQUEST_ERROR,
  SET_ME_REQUEST_SUCCESS,
  SUPERVISOR_LOGOUT,
  RESET_ERROR,
  REMOVE_TOKEN,
  STAFF_VERIFY_REQUEST,
  STAFF_VERIFY_REQUEST_ERROR,
  STAFF_VERIFY_REQUEST_SUCCESS,
  CHECK_IN_REQUEST,
  CHECK_IN_REQUEST_ERROR,
  CHECK_IN_REQUEST_SUCCESS,
  CHECK_OUT_REQUEST,
  CHECK_OUT_REQUEST_ERROR,
  CHECK_OUT_REQUEST_SUCCESS,
  SITE_INDUCTION_REQUEST,
  SITE_INDUCTION_REQUEST_ERROR,
  SITE_INDUCTION_REQUEST_SUCCESS,
  GET_COVID_QUESTION_REQUEST,
  GET_COVID_QUESTION_REQUEST_SUCCESS,
  GET_COVID_QUESTION_REQUEST_ERROR,
  SET_COVID_QUESTION_REQUEST,
  SET_COVID_QUESTION_REQUEST_ERROR,
  SET_COVID_QUESTION_REQUEST_SUCCESS,
  CHECK_IN_STATUS_REQUEST,
  CHECK_IN_STATUS_REQUEST_ERROR,
  CHECK_IN_STATUS_REQUEST_SUCCESS,
  ROLE_CLAIM_REQUEST,
  ROLE_CLAIM_REQUEST_ERROR,
  ROLE_CLAIM_REQUEST_SUCCESS,
} from "./constants";

enableES5();

export const initialState = {
  error: null,
  loading: false,
  user: null,
  supervisor: null,
  supervisorToken: null,
  hasCards: null,
  reason: null,
  me: null,
  checkIn: null,
  checkOut: null,
  siteInduction: null,
  covidQuestions: null,
  checkInStatus: null,
  supervisorInductionLogin: null,
};

const AuthReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case LOGIN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.user = action.data;
        break;
      case LOGIN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ME_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_ME_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.me = action.data;
        draftState.user = {
          ...state.user,
          first_name: action.data.first_name,
          last_name: action.data.last_name,
        };
        break;
      case GET_ME_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case STAFF_VERIFY_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case STAFF_VERIFY_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        break;
      case STAFF_VERIFY_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case ROLE_CLAIM_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case ROLE_CLAIM_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        break;
      case ROLE_CLAIM_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CHECK_IN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.checkIn = null;
        break;
      case CHECK_IN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.checkIn = action.data;
        break;
      case CHECK_IN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CHECK_IN_STATUS_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.checkInStatus = null;
        break;
      case CHECK_IN_STATUS_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.checkInStatus = action.data;
        break;
      case CHECK_IN_STATUS_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_COVID_QUESTION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.covidQuestions = null;
        break;
      case GET_COVID_QUESTION_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.covidQuestions = action.data;
        break;
      case GET_COVID_QUESTION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SET_COVID_QUESTION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case SET_COVID_QUESTION_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        break;
      case SET_COVID_QUESTION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SITE_INDUCTION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.siteInduction = null;
        break;
      case SITE_INDUCTION_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.siteInduction = action.data;
        break;
      case SITE_INDUCTION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CHECK_OUT_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CHECK_OUT_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.checkOut = action.data;
        draftState.checkIn = null;
        break;
      case CHECK_OUT_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;

      case SET_ME_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case SET_ME_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.me = action.data;
        break;
      case SET_ME_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SUPERVISOR_LOGIN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case SUPERVISOR_LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.supervisor = action.data;
        break;
      case SUPERVISOR_INDUCTION_LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.supervisorInductionLogin = true;
        break;
      case SUPERVISOR_LOGOUT:
        draftState.loading = false;
        draftState.error = null;
        draftState.hasCards = null;
        draftState.supervisor = null;
        draftState.user = null;
        break;
      case SUPERVISOR_LOGIN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case POST_LOGIN_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case POST_LOGIN_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.hasCards = action.data;
        break;
      case POST_LOGIN_REQUEST_FAIL:
        draftState.loading = false;
        draftState.error = null;
        draftState.reason = action.data;
        break;
      case POST_LOGIN_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case RESET_ERROR:
        draftState.loading = false;
        draftState.error = null;
        break;
      case REMOVE_TOKEN:
        draftState.error = null;
        draftState.loading = null;
        draftState.user = null;
        draftState.supervisor = null;
        draftState.supervisorToken = null;
        draftState.hasCards = null;
        draftState.reason = null;
        draftState.me = null;
        draftState.checkIn = null;
        draftState.checkOut = null;
        draftState.siteInduction = null;
        draftState.supervisorInductionLogin = null;
        break;
      default:
        break;
    }
  });
};

export default AuthReducer;
