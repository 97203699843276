import { useEffect, useState, useRef } from "react";
import {
  setSectionTime,
  getSectionTime,
  setCountTime,
} from "../store/time/actions";
import {
  makeSelectCurrentSection,
  makeSelectCountTime,
  makeSelectSectionTime,
  makeSelectLoading,
} from "../store/time/selector";

import { makeSelectProgress } from "../store/progress/selector";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { usePrevious } from "./hooks/index";
import { isNil } from "lodash";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Timer = ({
  currentSection,
  setSectionTimeReq,
  loading,
  time,
  setTime,
  progressData,
}) => {
  const induction_id = 2;

  //calculated time for current section

  //previous section
  const prevSection = usePrevious(currentSection);

  const setTimeRequest = () => {
    if (!loading && currentSection >= progressData?.section) {
      setSectionTimeReq({
        induction_id,
        totalseconds: time,
        section: currentSection,
      });
    }
  };

  useInterval(() => {
    if (!loading) {
      setTimeRequest();
    }
  }, 10000);

  useInterval(() => {
    if (!loading) {
      setTime({ time: time + 1 });
    }
  }, 1000);

  useEffect(() => {
    if (!isNil(prevSection)) {
      if (!loading && prevSection >= progressData?.section) {
        if (time !== 0) {
          setSectionTimeReq({
            induction_id,
            totalseconds: time,
            section: prevSection,
          });
        }
      }
    }
  }, [currentSection]);

  return null;
};

const mapStateToProps = createStructuredSelector({
  currentSection: makeSelectCurrentSection(),
  sectionTime: makeSelectSectionTime(),
  time: makeSelectCountTime(),
  loading: makeSelectLoading(),
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSectionTimeReq: (val) => dispatch(setSectionTime(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
    setTime: (val) => dispatch(setCountTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
