import React, { useEffect } from "react";
import classes from "./siterules.module.scss";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";

import { Link } from "react-router-dom";

import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectProgress } from "store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";

const SiteRules = ({
  getProgress,
  setProgress,
  progressData,
  setSection,
  getSectionTimeReq,
}) => {
  useEffect(() => {
    getProgress();
    setSection({
      section: 10,
      callBack: () => getSectionTimeReq({ induction_id: "2", section: 10 }),
    });
  }, []);

  useEffect(() => {
    if (progressData.section < 10) {
      setProgress({ section: 10, progress: 0, induction_id: 2 });
    }
  }, [progressData]);

  return (
    <div className={classes.SiteRuleContainer}>
      <span className={classes.Title}>
        <FormattedMessage id={"siterules.title"} />
      </span>
      <div className={classes.ImageContainer}>
        <div
          className={classes.Image}
          style={{
            backgroundImage:
              "url(https://hs.immersivetechnology.nz/siterules.png)",
          }}
        />
      </div>
      <div
        style={{
          width: "90%",
          margin: "20px auto 100px auto",

          paddingBottom: "50px",
        }}
      >
        <span className={classes.Content}>
          <FormattedHTMLMessage id={"siterules.subtitle"} />
        </span>
        <ul className={classes.List}>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.1"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.2"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.3"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.4"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.5"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.6"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.7"} />
          </li>
          <li className={classes.Item}>
            <FormattedMessage id={"siterules.item.8"} />
          </li>
        </ul>
      </div>
      <Footer>
        <Link className={classes.Link} to="/complete">
          <Button
            formatID={"siterules.button"}
            id="apply-button"
            text="Next"
            type="submit"
          />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteRules);
