// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_COMPANIES,
} from "../../constants";
import axios from "../../../../services/public";
import { isNil } from "lodash";

function* getCompaniesRequest(data) {
  try {
    const params = new URLSearchParams();

    if (!isNil(data.sort)) {
      params.append("sort", data.sort);
    }
    if (!isNil(data.page)) {
      params.append("page", data.page);
    }
    if (!isNil(data.search)) {
      params.append("search", data.search);
    }
    const response = yield call(axios.get, "/companies/", { params });
    if (response?.data?.error) {
      yield put({
        type: GET_COMPANIES_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_COMPANIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_COMPANIES_ERROR,
      data: error,
    });
  }
}

export default function* getCompaniesRequestSaga() {
  yield takeLatest(GET_COMPANIES, getCompaniesRequest);
}
