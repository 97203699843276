// @flow
import { put, call, takeEvery } from "redux-saga/effects";
import {
  SUPERVISOR_LOGIN_REQUEST_ERROR,
  SUPERVISOR_LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_INDUCTION_LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_LOGIN_REQUEST,
} from "../constants";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* supervisorloginRequest(data) {
  try {
    yield put({
      type: SUPERVISOR_LOGIN_REQUEST_SUCCESS,
      data: "logged_in",
    });

    if (!isNil(data.isInduction)) {
      yield call([localStorage, localStorage.removeItem], "induction");
      yield put({
        type: SUPERVISOR_INDUCTION_LOGIN_REQUEST_SUCCESS,
      });
      yield put(push("/post-login?isStaff=1"));
    } else {
      if (!isNil(data?.site)) {
        const route = yield call(
          [localStorage, localStorage.getItem],
          "redirectRoute"
        );
        const siteManager = yield call(
          [localStorage, localStorage.getItem],
          "siteManager"
        );
        yield call([localStorage, localStorage.removeItem], "site");
        yield call([localStorage, localStorage.removeItem], "redirect");
        yield call([localStorage, localStorage.removeItem], "redirect");

        yield put(
          push(`/${route}?site=${data.site}&sitemanager=${siteManager}`)
        );
      } else {
        yield put(push("/supervisor-dashboard"));
      }
    }
  } catch (error) {
    yield put({
      type: SUPERVISOR_LOGIN_REQUEST_ERROR,
      data: error.message,
    });
  }
}

export default function* supervisorloginRequestSaga() {
  yield takeEvery(SUPERVISOR_LOGIN_REQUEST, supervisorloginRequest);
}
