import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classes from "./postLogin.module.scss";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import queryString from "query-string";
import { connect } from "react-redux";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectReason,
} from "store/auth/selector";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import { isNil } from "lodash";

const PostLoginFailure = (props) => {
  const [site, setSite] = useState(undefined);
  const [siteManager, setSiteManager] = useState(undefined);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setSite(parsed.site);
    setSiteManager(parsed.siteManager);
  }, []);

  return (
    <div style={{ background: "#F86C6B" }} className={classes.PopUpContainer}>
      <div className={classes.PopUpTextWrapper}>
        <span className={classes.PopUpTextFailure}>
          <span className={classes.PopUpName}>
            <FormattedMessage
              id={"postlogin.error.title"}
              values={{ name: props.user && props.user.first_name }}
            />
          </span>
          ,
          <br />
          <br />
          {props.reason && props.reason.length === 2 ? (
            <span>
              <FormattedHTMLMessage id={"postlogin.error.case.1.description"} />
            </span>
          ) : props.reason[0].id === 1 ? (
            <span>
              <FormattedHTMLMessage id={"postlogin.error.case.2.description"} />
            </span>
          ) : (
            <span>
              <FormattedHTMLMessage id={"postlogin.error.case.3.description"} />
            </span>
          )}
        </span>
      </div>
      <Footer>
        <Button
          formatID={"postlogin.error.button"}
          theme={"gray"}
          textColor={"#1d1d1d"}
          text={"OK! I'll get it sorted!"}
          onClick={() => {
            if (!isNil(site) && !isNil(siteManager)) {
              props.history.push(`/login?site=${site}`);
            } else if (!isNil(site)) {
              props.history.push(`/login?site=${site}`);
            } else {
              props.history.push("/login");
            }
          }}
          color={"#F0F3F5"}
        />
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  reason: makeSelectReason(),
});

export default connect(mapStateToProps)(withRouter(PostLoginFailure));
