import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ClickOutside } from "utils/helper";

import classes from "./siteSelector.module.scss";
import { isNil } from "lodash";
import Checkbox from "../checkbox";
import { connect } from "react-redux";

import {
  makeSelectLoading,
  makeSelectRegions,
} from "store/supervisor/selector";

import { createStructuredSelector } from "reselect";
import filtericon from "./sitefilter.svg";
// const filtericon = process.env.PUBLIC_URL + "/icons/sitefilter.svg";

const SiteSelector = ({ title, regions, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [siteList, setSiteList] = useState([]);

  useEffect(() => {
    if (!isNil(regions)) {
      setSiteList(
        regions?.results.map((item) => {
          return { ...item, checked: false };
        })
      );
    }
  }, [regions]);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div className={cn(classes.selectbox)}>
        <img
          className={classes.filtericon}
          src={filtericon}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        <span className={classes.title}></span>
        <div className={cn(classes.dropdown, isDropdownOpen && classes.open)}>
          <ul>
            {siteList &&
              !!siteList.length &&
              siteList.map((option, index) => {
                return (
                  <li
                    key={index}
                    onClick={(e) => {
                      const newOptions = siteList.map((item, i) => {
                        return {
                          ...item,
                          checked: index === i ? !item.checked : item.checked,
                        };
                      });
                      setSiteList(newOptions);

                      onChange(
                        newOptions
                          .filter((item) => item.checked)
                          ?.map((a) => a.id)
                          ?.toString()
                      );
                    }}
                  >
                    <Checkbox checked={option.checked} />
                    <div className={classes.indicatorItem}>
                      <div style={{ background: option.color }} />
                      <span>{option.code}</span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </ClickOutside>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  regions: makeSelectRegions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    //getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelector);
