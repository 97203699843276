import React from "react";
import Checkbox from "../checkbox";
import { FormattedMessage } from "react-intl";
import {
  CardContainer,
  TitleWrapper,
  Title,
  ImageBox,
  Thumbnail,
  ProgressBar,
  Progress,
  CorrectBox,
} from "./styles";

const Card = ({
  onClick,
  color,
  image,
  completed,
  progress,
  title,
  icon,
  id,
}) => {
  return (
    <CardContainer onClick={onClick} color={color} image={image}>
      <TitleWrapper>
        <ImageBox>
          <Thumbnail src={icon}></Thumbnail>
        </ImageBox>
        <Title>
          <FormattedMessage
            id={`induction.${id}.title`}
            defaultMessage={title}
          />
        </Title>
      </TitleWrapper>
      <ProgressBar>
        <Progress progress={progress} />
      </ProgressBar>
      <CorrectBox>{completed && <Checkbox checked={true} />}</CorrectBox>
    </CardContainer>
  );
};

export default Card;
