// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  ROLE_CLAIM_REQUEST,
  ROLE_CLAIM_REQUEST_SUCCESS,
  ROLE_CLAIM_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { error as err } from "../../../main.js";
import { isNil } from "lodash";

function* roleClaim(data) {
  try {
    const params = new URLSearchParams();
    params.append("idtoken", data.payload.idtoken);
    axios.defaults.headers.common[
      "X-Access-Token"
    ] = `${data.payload.access_token}`;

    const response = yield call(axios.post, "/role-claim/", params);

    if (response.data.success === 0) {
      yield put({
        type: ROLE_CLAIM_REQUEST_ERROR,
        data: response.data.message,
      });
      yield call(err, response.data.message);
      data.payload.callBack();
    } else if (response.data.error) {
      yield put({
        type: ROLE_CLAIM_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: ROLE_CLAIM_REQUEST_SUCCESS,
        data: response.data.languages,
      });

      if (!isNil(data?.payload?.callBack)) {
        yield call(data.payload.callBack());
      }
    }
  } catch (error) {
    yield put({
      type: ROLE_CLAIM_REQUEST_ERROR,
      data: error,
    });
    data.payload.callBack();
  }
}

export default function* roleClaimSaga() {
  yield takeLatest(ROLE_CLAIM_REQUEST, roleClaim);
}
