import React, { useEffect, useState } from "react";
import classes from "./Covid.module.scss";
import Footer from "components/UI/footer";
import Button from "components/UI/button";
import RedTick from "./red-tick.svg";
import { includes } from "lodash";
import { connect } from "react-redux";
import queryString from "query-string";
import WarningIcon from "./warning.svg";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";

//action start
import {
  getCovidQuestionRequest,
  seCovidQuestionsRequest,
} from "store/auth/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectLoading,
  makeSelectCovidQuestions,
} from "store/auth/selector";

const CovidQuestions = ({
  covidQuestions,
  getCovidQuestions,
  setCovidQuestions,
}) => {
  const [site, setSite] = useState(undefined);
  const [redirectRoute, setRedirectRoute] = useState();

  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [error, setError] = useState(false);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    getCovidQuestions();
  }, []);

  useEffect(() => {
    if (covidQuestions?.results?.length > 0) {
      let newArr = [];
      newArr.push(1);
      setCheckedQuestions(newArr);
    }
  }, [covidQuestions]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setSite(parsed.site);
    setRedirectRoute(parsed.redirect);
  }, []);

  const CovidCheckList = [
    {
      id: 1,
      text: "I am fully vaccinated",
      textId: "covid.question.1",
      key: "is_fully_vaccinated",
    },
    {
      id: 2,
      text: "I do not have any Covid-19 symptoms",
      textId: "covid.question.2",
      key: "is_no_symptoms",
    },
    {
      id: 3,
      text: "I am not awaiting the result of a Covid-19 test",
      textId: "covid.question.3",
      key: "is_not_awaiting_results",
    },
    {
      id: 4,
      text: "I am not under instruction to isolate due to Covid-19",
      textId: "covid.question.4",
      key: "is_not_isolating",
    },
  ];

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        <FormattedMessage
          defaultMessage={"Covid-19 questionnaire"}
          id={"covid.title"}
        />
      </h1>
      {error ? (
        <>
          <img
            src={WarningIcon}
            className={classes.warningIcon}
            alt={"Warning Icon"}
          />
          <p className={classes.errorText}>
            <FormattedHTMLMessage
              value={
                "Given the risk of Covid, <b>you are not</b> able to come on-site."
              }
              id={"covid.error.title"}
            />
          </p>
          <p className={classes.errorText}>
            <FormattedMessage
              defaultMessage={"Please make other arrangements with your host."}
              id={"covid.error.description"}
            />
          </p>
        </>
      ) : (
        <>
          {CovidCheckList.map((item, index) => (
            <div key={index} className={classes.personaItem}>
              <div
                className={classes.checkbox}
                onClick={() => {
                  if (includes(checkedQuestions, item.id)) {
                    const newArr = checkedQuestions.filter(
                      (a) => a !== item.id
                    );
                    setCheckedQuestions(newArr);
                  } else {
                    setCheckedQuestions([item.id, ...checkedQuestions]);
                  }
                }}
              >
                {includes(checkedQuestions, item.id) && (
                  <img alt={"tick"} src={RedTick} className={classes.tick} />
                )}
              </div>
              <div className={classes.textWrapper}>
                <span className={classes.personaTitle}>
                  <FormattedMessage
                    defaultMessage={item?.text}
                    id={item.textId}
                  />
                </span>
              </div>
            </div>
          ))}

          <Footer>
            <Button
              formatID="login.button"
              id="apply-button"
              text="Continue"
              type="submit"
              onClick={() => {
                if (checkedQuestions.length === 4) {
                  setCovidQuestions({
                    is_fully_vaccinated: 1,
                    is_no_symptoms: 1,
                    is_not_awaiting_results: 1,
                    is_not_isolating: 1,
                    site: site,
                    redirect: redirectRoute,
                  });
                } else {
                  setCovidQuestions({
                    is_fully_vaccinated: includes(checkedQuestions, 1) ? 1 : 0,
                    is_no_symptoms: includes(checkedQuestions, 2) ? 1 : 0,
                    is_not_awaiting_results: includes(checkedQuestions, 3)
                      ? 1
                      : 0,
                    is_not_isolating: includes(checkedQuestions, 4) ? 1 : 0,
                    site: site,
                    redirect: redirectRoute,
                  });
                  setError(true);
                }
              }}
            />
          </Footer>
        </>
      )}
      <div className={classes.covidPopup}>
        <h3 className={classes.covidTitle}>
          <FormattedMessage
            defaultMessage={"If you have COVID-19"}
            id={"covid.text.1"}
          />
        </h3>
        <p className={classes.covidDescription}>
          <FormattedMessage
            defaultMessage={
              "What to do if you have tested positive for COVID-19 or are a probable case."
            }
            id={"covid.text.2"}
          />
        </p>
        <div className={classes.buttonWrapper}>
          <button
            onClick={() => {
              openInNewTab("https://covid19.health.nz/advice/");
            }}
          >
            <FormattedMessage
              defaultMessage={"Covid 19 Health Hub"}
              id={"covid.text.3"}
            />
          </button>
          <button
            onClick={() => {
              openInNewTab(
                "https://covid19.govt.nz/testing-and-tracing/covid-19-testing/"
              );
            }}
          >
            <FormattedMessage
              defaultMessage={"Covid 19 Testing"}
              id={"covid.text.4"}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  covidQuestions: makeSelectCovidQuestions(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCovidQuestions: (val) => dispatch(getCovidQuestionRequest(val)),
    setCovidQuestions: (val) => dispatch(seCovidQuestionsRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidQuestions);
