import React from "react";
import { useIntl } from "react-intl";

const FormattedHTMLMessage = ({ id, value }) => {
  const intl = useIntl();
  const translated = intl.formatMessage({ id: id }, { value: value });
  return <div dangerouslySetInnerHTML={{ __html: translated }} />;
};

export default FormattedHTMLMessage;
