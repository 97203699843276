import React, { useEffect, useState } from "react";

import queryString from "query-string";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//action start
import { checkOutRequest } from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectUser,
  makeSelectCheckInStatus,
  makeSelectCheckOutStatus,
} from "store/auth/selector";
//selectors end

import classes from "./checkout.module.scss";
import { isNil } from "lodash";
import { msToTime } from "utils/helper";
import { types } from "../CheckIn/types";
import { FormattedMessage } from "react-intl";
import Loading from "components/Supervisor/loading";

const CheckIn = ({
  loading,
  user,
  checkOut,
  status,
  checkOutStatus,
  history,
}) => {
  const [time, setTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);
  const [success, setSuccess] = useState(false);
  const [checkOutTime, setCheckOutTime] = useState(null);

  const [variable, setVariable] = useState(null);

  useEffect(() => {
    const counter = () => {
      setTimeDiff(timeDiff + 1000);
      setTime(msToTime(timeDiff + 1000));
    };
    const id = setInterval(counter, 1000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    checkOutAction();
  }, []);

  useEffect(() => {
    if (!isNil(checkOutStatus)) {
      const checkInTime = new Date(checkOutStatus.check_in);
      const checkOutTime = new Date(checkOutStatus.check_out);
      const totalTime = Math.abs(checkOutTime - checkInTime);
      setCheckOutTime(msToTime(totalTime));
      const selectedVariable = types.find(
        (item) => item.id === checkOutStatus?.messageId
      );
      setVariable(selectedVariable);
    }
  }, [checkOutStatus]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    var date = new Date(status?.check_in);
    var now = new Date();

    const diffTime = Math.abs(date - now);
    setTimeDiff(diffTime);
    setTime(msToTime(diffTime));
  }, []);

  const checkOutAction = () => {
    const parsed = queryString.parse(window.location.search);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    if (isNil(parsed?.id)) {
      checkOut({
        site: !isNil(parsed.site) ? parsed.site : status?.siteID,
        callBack: () => setSuccess(true),
        autoLogout: async () => {
          //automatic logout for tablet
          await delay(5000);
          history.push("/logout");
          window.localStorage.removeItem("AUTH_TOKEN");
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("refresh_token");
          window.localStorage.removeItem("expiry_date");
        },
      });
    } else {
      setSuccess(true);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      {success && variable && (
        <>
          <div
            className={classes.container}
            style={{ background: variable.color }}
          >
            {!isNil(variable?.popUp) && (
              <div className={classes.timeCountContainer}>
                <span>{checkOutTime}</span>
              </div>
            )}

            <img
              alt={variable?.icon?.icon}
              className={classes.icon}
              style={{
                width: variable.icon.iconWidth,
                height: variable.icon.iconHeight,
              }}
              src={variable.icon.icon}
            />

            <span className={classes.name}>
              {user && user.first_name} {user && user.last_name}
            </span>
            {variable?.title && (
              <span className={classes.title}>
                <FormattedMessage
                  defaultMessage={variable.title}
                  id={variable.titleId}
                />
              </span>
            )}
            {variable?.description && (
              <span className={classes.description}>
                <FormattedMessage
                  defaultMessage={variable.description}
                  id={variable.descriptionId}
                  values={{ site: checkOutStatus?.site?.name }}
                />
              </span>
            )}

            <div className={classes.infoContainer}>
              <div className={classes.infoWrapper}>
                <span className={classes.infoTitle}>
                  <FormattedMessage
                    defaultMessage={"Company"}
                    id={"checkin.company"}
                  />
                </span>
                <span className={classes.text}>
                  {checkOutStatus?.site?.company?.name}
                </span>
              </div>
              <div className={classes.infoWrapper}>
                <span className={classes.infoTitle}>
                  <FormattedMessage
                    defaultMessage={"Site"}
                    id={"checkin.site"}
                  />
                </span>
                <span className={classes.text}>
                  {checkOutStatus?.site?.name}
                </span>
              </div>
            </div>
            {variable?.bottomDescription && (
              <span className={classes.bottomDescription}>
                <FormattedMessage
                  defaultMessage={variable?.bottomDescription}
                  id={variable.bottomDescriptionId}
                />
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
  user: makeSelectUser(),
  status: makeSelectCheckInStatus(),
  checkOutStatus: makeSelectCheckOutStatus(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    checkOut: (val) => dispatch(checkOutRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
