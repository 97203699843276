import React from "react";
import classes from "./menu.module.scss";
import { withRouter, useLocation } from "react-router";
import { isNil } from "lodash";

const Menu = ({ pages, history, id }) => {
  const location = useLocation();
  return (
    <>
      <div className={classes.menuContainer}>
        <div className={classes.menuWrapper}>
          {pages.map((item) => (
            <span
              onClick={() => {
                if (!isNil(id)) {
                  history.push(`${item.pathName}?id=${id}`);
                } else {
                  history.push(`${item.pathName}`);
                }
              }}
              className={
                item.path === `${location.pathname.split("-")[2]}`
                  ? classes.menuItem
                  : classes.inActiveMenuItem
              }
            >
              {item.title}
            </span>
          ))}
        </div>
        <div className={classes.divider} />
      </div>
    </>
  );
};

export default withRouter(Menu);
