import React from "react";
import TimeIcon from "../assets/icons/time.svg";
import ContractorIcon from "../assets/icons/engineering.svg";
import OkeyIcon from "../assets/icons/completed.svg";
import SiteIcon from "../assets/icons/site.svg";

import classes from "./overview.module.scss";
import { intervalToDuration } from "date-fns";
import { isNil, isNaN } from "lodash";

function minutesToHMS(d) {
  const duration = Number(d);

  if (!isNil(duration) && !isNaN(duration)) {
    const seconds = duration / 0.016667;

    const calculatedTime =
      !isNaN(seconds) &&
      intervalToDuration({
        start: 0,
        end: seconds * 1000,
      });

    const hour =
      calculatedTime?.hours < 10
        ? `0${calculatedTime.hours}`
        : calculatedTime?.hours;
    const minute =
      calculatedTime.minutes < 10
        ? `0${calculatedTime.minutes}`
        : calculatedTime?.minutes;
    const second =
      calculatedTime.seconds < 10
        ? `0${calculatedTime?.seconds}`
        : calculatedTime?.seconds;

    return `${hour}:${minute}:${second}`;
  }
}

const OverviewStats = ({ dashboardData }) => {
  return (
    <>
      <div className={classes.OverviewContainer}>
        <div className={classes.OverviewCard}>
          <img className={classes.cardIcon} src={SiteIcon}></img>
          <span className={classes.cardData}>{dashboardData?.sites}</span>
          <span className={classes.cardDesc} style={{ marginBottom: "0px" }}>
            Sites
          </span>
        </div>
        <div className={classes.OverviewCard}>
          <img className={classes.cardIcon} src={ContractorIcon}></img>
          <span className={classes.cardData}>
            {dashboardData?.total_contractors}
          </span>
          <span className={classes.cardDesc}>Contractors Registered</span>
        </div>
        <div className={classes.OverviewCard}>
          <img
            alt={"Okey Icon"}
            className={classes.cardIcon}
            src={OkeyIcon}
          ></img>
          <span className={classes.cardData}>
            {dashboardData?.total_completed_induction}
          </span>
          <span className={classes.cardDesc}>Inductions Completed</span>
        </div>

        {/* <div className={classes.OverviewCard}>
          <img
            alt={"time icon"}
            className={classes.cardIcon}
            src={TimeIcon}
          ></img>
          <span className={classes.cardData}>
            {minutesToHMS(dashboardData?.average_time_to_completion)}
          </span>
          <span className={classes.cardDesc} style={{ marginBottom: "0px" }}>
            Avg. Time to complete
          </span>
        </div> */}
      </div>
    </>
  );
};

export default OverviewStats;
