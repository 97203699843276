import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import LanguageSelection from "pages/LanguageSelection";
import PersonaSelection from "../pages/PersonaSelection";

import Auth from "../pages/Auth";
import CovidQuestions from "../pages/CovidQuestions";
import PostLogin from "../pages/PostLogin";
import PostLoginSuccess from "../pages/PostLogin/success";
import PostLoginFailure from "../pages/PostLogin/failure";

//Dummy Logout
import Logout from "../pages/DummyLogout";

// Pages
import Welcome from "../pages/Welcome";
import LookAround from "../pages/LookAround";
import Induction from "../pages/Induction";
import CardSystem from "../pages/CardSystem";
import SiteRules from "../pages/SiteRules";
import Complete from "../pages/Complete";

//Supervisor Related Page
import SupervisorAuth from "../pages/Supervisor/Auth";
import SupervisorStaffLogin from "../pages/Supervisor/StaffLogin";

import SupervisorStaffVerify from "../pages/Supervisor/StaffVerify";
import SupevisorPeople from "../pages/Supervisor/People";
import ContractorDetail from "../pages/Supervisor/ContractorStats";
import SupervisorDashboard from "../pages/Supervisor/Dashboard";
import SupervisorCompany from "../pages/Supervisor/Company";
import SupervisorCreateForm from "../pages/Supervisor/Company/form";

import SupervisorSettings from "../pages/Supervisor/Settings";
import SupervisorSites from "../pages/Supervisor/Site";
import SupervisorSiteDetailForm from "../pages/Supervisor/Site/Detail";
import SupervisorSiteCreateForm from "../pages/Supervisor/Site/Create";
import SupervisorSiteQrCode from "../pages/Supervisor/Site/QrCode";
import SupervisorSiteContractors from "../pages/Supervisor/Site/Contractors";
import SupervisorSiteContractorsDetail from "../pages/Supervisor/Site/Contractors/Detail";
import SupervisorReporting from "../pages/Supervisor/Reporting";

import CheckOut from "../pages/CheckOut";
import AutoLogout from "pages/AutoLogout";

const routes = [
  {
    path: "/supervisor-dashboard",
    component: SupervisorDashboard,
    type: "supervisor",
  },

  {
    path: "/supervisor-reporting",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-inductions",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-cards",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-attendance",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-attendance-detail",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-people",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-people-contractors",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-people-visitors",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-people-staff",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-company-create",
    component: SupervisorCreateForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-company-edit",
    component: SupervisorCreateForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-company",
    component: SupervisorCompany,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-create",
    component: SupervisorSiteCreateForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-detail",
    component: SupervisorSiteDetailForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-qr",
    component: SupervisorSiteQrCode,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-contractors-detail",
    component: SupervisorSiteContractorsDetail,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-contractors",
    component: SupervisorSiteContractors,
    type: "supervisor",
  },

  {
    path: "/supervisor-sites",
    component: SupervisorSites,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-translations",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-roles-create",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-roles-edit",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-roles",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users-create",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users-edit",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users-import-csv",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-branch",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-branch-create",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-branch-edit",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-contractor-detail",
    component: ContractorDetail,
    type: "supervisor",
  },
  { path: "/welcome", component: Welcome, type: "induction" },
  { path: "/take-a-look", component: LookAround, type: "induction" },
  { path: "/induction", component: Induction, type: "induction" },
  { path: "/card-system", component: CardSystem, type: "induction" },
  { path: "/site-rules", component: SiteRules, type: "induction" },
  { path: "/complete", component: Complete, type: "induction" },

  // this route should be at the end of all other routes
  { path: "/", component: () => <Redirect to="/welcome" /> },
];

const authRoutes = [
  {
    path: "/supervisor-login",
    component: SupervisorAuth,
    type: "supervisor",
  },
  {
    path: "/autologout",
    component: AutoLogout,
  },
  {
    path: "/staff-login",
    component: SupervisorStaffLogin,
    type: "supervisor",
  },
  { path: "/logout", component: Logout },
  {
    path: "/staff-verify",
    component: SupervisorStaffVerify,
    type: "supervisor",
  },
  { path: "/check-out", component: CheckOut },
  { path: "/persona-selection", component: PersonaSelection },
  { path: "/language-selection", component: LanguageSelection },
  { path: "/login", component: Auth },
  // { path: "/covid-check", component: CovidQuestions },
  { path: "/post-login-success", component: PostLoginSuccess },
  { path: "/post-login-failure", component: PostLoginFailure },
  { path: "/post-login", component: PostLogin },
];

export { routes, authRoutes };
