import React from "react";
//styling
import classes from "../reporting.module.scss";

//assets
import ThumpsUpIcon from "./thumpsup.png";
import ThumpsDownIcon from "./thumpsdown.png";
import { format, parseISO } from "date-fns";

//utils
import { isNil } from "lodash";

const formatDate = (val) =>
  !isNil(val) ? format(parseISO(val), `dd/MM/yyyy`) : "-";

export const typeDefault = [
  { value: 1, label: "Green Card", checked: false },
  { value: 2, label: "Red Card", checked: false },
];

const labels = [
  { label: "Company", sortable: true, key: "company" },
  { label: "Site Name", sortable: true, key: "site" },
  { label: "Name", sortable: true, key: "name" },
  { label: "Issued by", sortable: true, key: "issuer" },
  { label: "Issued", sortable: true, key: "issued" },
  { label: "Summary", sortable: true, key: "summary" },
  { label: "Action", sortable: true, key: "action" },
  { label: "Card" },
];

export const reportingTableData = (item) => {
  const data = item?.map((a) => {
    return {
      id: a.id,
      email: a.email,
      values: [
        {
          value: !isNil(a?.company?.name) ? a?.company?.name : "-",
        },
        {
          value: !isNil(a?.site?.name) ? a.site.name : "-",
        },
        {
          value: !isNil(a.user)
            ? `${a?.user?.first_name} ${a.user.last_name}`
            : "-",
        },
        {
          value: !isNil(a.issuer)
            ? `${a.issuer?.first_name} ${a.issuer?.last_name}`
            : "-",
        },
        {
          value: !isNil(a?.issuer?.date_joined)
            ? formatDate(a.issuer.date_joined)
            : "-",
        },
        {
          value: !isNil(a.summary) ? a.summary : "-",
        },
        {
          value: !isNil(a.action) ? a.action : "-",
        },
        {
          value:
            !isNil(a.type) && a.type === 1
              ? "Green"
              : a.type === 2
              ? "Red"
              : "-",
          component: (
            <img
              className={classes.thumpsIcon}
              alt={`Thump Icon ${a.id}`}
              src={a.type === 1 ? ThumpsUpIcon : ThumpsDownIcon}
            />
          ),
        },
      ],
    };
  });

  return {
    columns: data,
    labels: labels,
  };
};
