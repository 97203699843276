import { baseUrl } from "./config";
import axios from "axios";
import { history } from "../store";

const refreshAccessToken = async () => {
  var bodyFormData = new FormData();
  bodyFormData.append("client_id", process.env.REACT_APP_CLIENT_ID);
  bodyFormData.append("redirect_uri", process.env.REACT_APP_REDIRECT_URI);
  bodyFormData.append("response_mode", "query");
  bodyFormData.append("scope", "openid");
  bodyFormData.append("grant_type", "refresh_token");
  bodyFormData.append(
    "refresh_token",
    window.localStorage.getItem("refresh_token")
  );

  const tenant_id = process.env.REACT_APP_TENANT_ID;

  const config = {
    method: "post",
    url: `https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/token`,
    headers: {
      "Content-Type": "application/x-www-url-form-urlencoded",
      Origin: process.env.REACT_APP_REDIRECT_URI,
    },
    data: bodyFormData,
  };
  axios(config)
    .then(function (response) {
      //handle success

      window.localStorage.setItem("access_token", response.data.access_token);
      instance.defaults.headers.common[
        "X-Access-Token"
      ] = `${response.data.access_token}`;
      window.localStorage.setItem("refresh_token", response.data.refresh_token);
      var now = new Date();
      let expiryDate = new Date(
        now.getTime() + response.data.expires_in * 1000
      );
      window.localStorage.setItem("expiry_date", expiryDate.getTime());

      return response.data.access_token;
    })
    .catch(function (err) {
      history.push("/logout");
      window.localStorage.removeItem("AUTH_TOKEN");
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("expiry_date");
      console.log(err);
    });
};

const instance = axios.create({
  baseURL: baseUrl,
});

const token = localStorage.getItem("AUTH_TOKEN");
const access_token = localStorage.getItem("access_token");

if (token) {
  instance.defaults.headers.common["Content-Type"] =
    "Origin,Accept, X-ACCESS_TOKEN, X-Token, HTTP_X_TOKEN, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers";
  instance.defaults.headers.common["X-Token"] = `${token}`;
}
if (access_token) {
  instance.defaults.headers.common["Content-Type"] =
    "Origin,Accept, X-ACCESS_TOKEN, X-Access-Token, HTTP_X_TOKEN, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers";
  instance.defaults.headers.common["X-Access-Token"] = `${access_token}`;
}
instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (access_token) {
      var now = new Date();
      var expiryDate = window.localStorage.getItem("expiry_date");
      const diff = Math.abs(expiryDate - now.getTime());
      const diffInMinutes = diff / 1000 / 60;
      if (diffInMinutes < 10) {
        refreshAccessToken();
      }
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      history.push("/logout");
      window.localStorage.removeItem("AUTH_TOKEN");
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("expiry_date");
    }

    return Promise.reject(error);
  }
);

export default instance;
