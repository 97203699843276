import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
} from "@coreui/react";

import TheHeaderDropdown from "./TheHeaderDropdown";
import cn from "classnames";
import { capitalize, isEmpty, isNil } from "lodash";

const Logo = process.env.PUBLIC_URL + "/logo/frl_logo.png";

const TheHeader = ({ no_permission }) => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow?.sidebarShow);
  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const splittedHref = window.location.pathname
    .slice(1, window.location.pathname.length)
    .split("-");

  return (
    <CHeader
      withSubheader
      className={cn(!no_permission && "supervisor-logout permission-nav")}
    >
      {!no_permission && (
        <>
          <CToggler
            inHeader
            className="ml-md-3 d-lg-none"
            onClick={toggleSidebarMobile}
          />
          <CToggler
            inHeader
            className="ml-3 d-md-down-none"
            onClick={toggleSidebar}
          />
          <CHeaderBrand className="mx-auto d-lg-none" to="/">
            <img alt={"Logo"} src={Logo} name="logo" height="86" />
          </CHeaderBrand>
          <CHeaderNav className="d-md-down-none mr-auto">
            {!isEmpty(splittedHref[0]) && (
              <CHeaderNavItem className="">
                <CHeaderNavLink to="/supervisor-dashboard">
                  Living Life
                </CHeaderNavLink>
              </CHeaderNavItem>
            )}

            <span>{isEmpty(splittedHref[1]) ? "" : `-`}</span>
            <CHeaderNavItem className="">
              <CHeaderNavLink to={`/supervisor-${splittedHref[1]}`}>
                {isEmpty(splittedHref[1])
                  ? ""
                  : ` ${capitalize(splittedHref[1])}`}
              </CHeaderNavLink>
            </CHeaderNavItem>
            <span>{isEmpty(splittedHref[2]) ? "" : `-`}</span>
            <CHeaderNavItem className="">
              <CHeaderNavLink
                to={`/supervisor-${splittedHref[1]}-${splittedHref[2]}`}
              >
                {" "}
                {isEmpty(splittedHref[2])
                  ? ""
                  : capitalize(splittedHref[2])}{" "}
              </CHeaderNavLink>
            </CHeaderNavItem>
            <span>{isEmpty(splittedHref[3]) ? "" : `-`}</span>
            <CHeaderNavItem className="">
              <CHeaderNavLink>
                {isEmpty(splittedHref[3]) ? "" : capitalize(splittedHref[3])}
              </CHeaderNavLink>
            </CHeaderNavItem>
          </CHeaderNav>
        </>
      )}
      <CHeaderNav className="px-3 supervisor-logout">
        <TheHeaderDropdown />
      </CHeaderNav>
    </CHeader>
  );
};

export default TheHeader;
