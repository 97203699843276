import { baseUrl } from "./config";
import axios from "axios";
import { error as notifyError } from "../main";

const publicInstance = axios.create({
  baseURL: baseUrl,
});

publicInstance.defaults.headers.common["Content-Type"] =
  "Origin,Accept, X-ACCESS_TOKEN, X-Token, HTTP_X_TOKEN, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers";

delete publicInstance.defaults.headers.common["X-Token"];
delete publicInstance.defaults.headers.common["X-Access-Token"];

publicInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
publicInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      notifyError("Permission denied");
    }
    return Promise.reject(error);
  }
);

export default publicInstance;
