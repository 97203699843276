import React from "react";
import classes from "./textArea.module.scss";
const Input = (props) => {
  return (
    <>
      <div
        className={classes.Container}
        style={{
          flexDirection: props.order === "column" && "column",
          alignItems: props.order === "column" && "flex-start",
        }}
      >
        {props.label && (
          <label
            style={{
              marginLeft: props.order === "column" && "10px",
              width: props.order === "column" && "100%",
            }}
            className={classes.BlockLabel}
          >
            {props.label}
          </label>
        )}
        <div className={classes.inputContainer}>
          <textarea
            style={{
              width: props.order === "column" && "100%",
            }}
            className={`${classes.BlockInput} ${props.error && classes.error}`}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            spellCheck="false"
            autoComplete="off"
          />
          {props.error && (
            <span className={classes.errorMessage}>
              {props.error && typeof props.error === "string"
                ? props.error
                : "Please provide valid information."}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Input;
