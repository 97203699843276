import React, { useState, useEffect } from "react";
import classes from "./multipleChoise.module.scss";
import WrongIcon from "../assets/wrongIcon.svg";
import CorrectIcon from "../assets/correctIcon.svg";
import { FormattedMessage } from "react-intl";

const MultipleChoise = ({
  questionData,
  assesmentData,
  setSelectedAssesment,
  setSelectedOption,
  result,
  section,
  retry,
  setRetry,
}) => {
  useEffect(() => {

    if (retry) {
      const newOptions = questionData.options.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });

      setOptions(newOptions);
      setRetry(false);
    }
  });
  const handleOptions = questionData.options.map((item) => {
    return {
      ...item,
      checked: retry ? false : item.checked ? item.checked : false,
    };
  });
  const [options, setOptions] = useState(handleOptions);

  const setCheckBoxHandler = (val) => {
    if (result === null) {
      const newOptions = options.map((item) => {
        if (item.id === val) {
          return {
            ...item,
            checked: true,
          };
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      });
      const newAssesmentData = assesmentData.questions.map((item) => {
        if (item.id === questionData.id) {
          return { ...questionData, options: newOptions };
        } else {
          return item;
        }
      });

      const newData = {
        ...assesmentData,
        questions: newAssesmentData,
      };

      setSelectedOption({ questionId: questionData.id, optionId: val });
      setOptions(newOptions);
      setSelectedAssesment(newData);
    }
  };
  return (
    <div
      style={{
        paddingBottom: "200px",
        position: "relative",
      }}
    >
      {result !== null && result?.is_correct === 1 ? (
        <>
          <div className={classes.successContainer}>
            <span>
              <FormattedMessage
                defaultMessage={"Correct - great work"}
                id={"induction.assesment.success.message"}
              />
            </span>
          </div>
        </>
      ) : (
        result !== null &&
        result?.is_correct === 0 && (
          <div className={classes.errorContainer}>
            <span>
              <FormattedMessage
                defaultMessage={"Incorrect - please click ’Retry’ to continue."}
                id={"induction.assesment.error.message"}
              />
            </span>
          </div>
        )
      )}
      <img
        alt={"question"}
        src={questionData?.image}
        // style={{ backgroundImage: `url(${questionData.image})` }}
        className={classes.ImageWrapper}
      />
      <div style={{ width: "90%", margin: "20px auto" }}>
        <span className={classes.QuestionTitle}>
          <FormattedMessage
            defaultMessage={questionData.name}
            id={`dynamic.question.${questionData.id}`}
          />
        </span>
      </div>
      {options &&
        options.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: item.checked ? section.color : "",
            }}
            className={classes.ChoiseContainer}
            onClick={() => setCheckBoxHandler(item.id)}
          >
            <div className={classes.ChoiseTextWrapper}>
              <span
                style={{ color: item.checked ? "white" : "" }}
                className={classes.ChoiseText}
              >
                <FormattedMessage
                  id={`dynamic.questionoption.${item.id}`}
                  defaultMessage={item.name}
                />
              </span>
            </div>
            <div className={classes.SelectedCheckboxWrapper}></div>
            {item.checked && result?.is_correct === 1 ? (
              <img
                alt={"correct"}
                className={classes.statusIcon}
                src={CorrectIcon}
              />
            ) : (
              item.checked &&
              result?.is_correct === 0 && (
                <img
                  alt="status wrong"
                  className={classes.statusIcon}
                  src={WrongIcon}
                />
              )
            )}
          </div>
        ))}
    </div>
  );
};

export default MultipleChoise;
