import React from "react";
import classes from "./switch.module.scss";
import cn from "classnames";
const Switch = ({ checked, option1, option2, onChange }) => {
  return (
    <div className={classes.switchContainer} id="switch">
      <span className={cn(classes.option1, !checked && classes.activated)}>
        {option1}
      </span>
      <div onClick={onChange} className={classes.switch}>
        <div className={checked ? classes.activeDanger : classes.disabled} />
      </div>
      <span className={cn(classes.option2, checked && classes.activatedDanger)}>
        {option2}
      </span>
    </div>
  );
};

export default Switch;
