import React, { useEffect } from "react";
import classes from "./dashboard.module.scss";
import {
  getDashboardRequest,
  getWeeklyActivity,
  getRegionRequest,
} from "store/supervisor/actions";
import { getLanguagesRequest } from "store/language/actions";

import SiteSelector from "components/Supervisor/siteSelector";

import {
  makeSelectDashboard,
  makeSelectLoading,
  makeSelectWeeklyActivity,
  makeSelectWeeklyLoading,
  makeSelectRegions,
} from "store/supervisor/selector";

import { makeSelectLanguages } from "store/language/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import LanguageStats from "./LanguageStats";
import SectionStats from "./SectionStats";
import OverviewStats from "./OverviewStats";
import WeeklyActivity from "./WeeklyActivity";

import ReactLoading from "react-loading";

const Dashboard = ({
  dashboardData,
  getDashboard,
  getLanguages,
  loading,
  languages,
  regions,
  getRegions,
  weeklyActivityData,
  getWeekly,
  weeklyLoading,
}) => {
  useEffect(() => {
    getDashboard();
    getLanguages();
    getRegions();
    getWeekly();
  }, []);

  return (
    <>
      <div className={classes.dashboardContainer}>
        <h1 className={classes.title}>Dashboard</h1>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <>
            <h4 className={classes.midTitle}>Overview</h4>
            <OverviewStats dashboardData={dashboardData} />
            <div className={classes.dashboardWrapper}>
              <div
                style={{ display: "flex", flex: "1", flexDirection: "column" }}
              >
                <h4 className={classes.midTitle}>Life Saving Rules</h4>
                <SectionStats dashboardData={dashboardData} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "1",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  position: "relative",
                }}
              >
                <h4 className={classes.midTitleRight}>Weekly activity</h4>
                <div className={classes.filterButton}>
                  <SiteSelector
                    title={"Status"}
                    onChange={(val) => {
                      getWeekly({ regionIds: val });
                    }}
                    regions={regions}
                  />
                </div>
                <WeeklyActivity
                  dashboardData={weeklyActivityData}
                  loading={weeklyLoading}
                />

                <h4 className={classes.midTitleRight}>Languages</h4>
                <LanguageStats
                  languages={languages}
                  dashboardData={dashboardData}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  dashboardData: makeSelectDashboard(),
  loading: makeSelectLoading(),
  languages: makeSelectLanguages(),
  regions: makeSelectRegions(),
  weeklyActivityData: makeSelectWeeklyActivity(),
  weeklyLoading: makeSelectWeeklyLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: (val) => dispatch(getDashboardRequest(val)),
    getLanguages: (val) => dispatch(getLanguagesRequest(val)),
    getRegions: (val) => dispatch(getRegionRequest(val)),
    getWeekly: (val) => dispatch(getWeeklyActivity(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
