import React, { useEffect, useState, useRef } from "react";
import classes from "../reporting.module.scss";

//components
import Table from "components/Supervisor/table";
import DateRangeFilter from "components/Supervisor/dateRangeFilter";
import Pagination from "components/Supervisor/pagination";
import ReactLoading from "react-loading";
import Filters from "./filters";

//redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getCompaniesRequest,
  getAllAttendaceReportsRequest,
  getAttendanceReportsRequest,
  getRegionRequest,
  getSitesRequest,
  setDateFilterReq,
} from "store/supervisor/actions";
import {
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectAllReportsLoading,
  makeSelectAttendanceReports,
  makeSelectAllAttendancesReports,
  makeSelectRegions,
  makeSelectSites,
  makeSelectDateFilter,
  makeSelectFilterLoading,
} from "store/supervisor/selector";

//utils
import { isNil, isEmpty } from "lodash";
import { format, getUnixTime } from "date-fns";
import { CSVLink } from "react-csv";
import { useSortableData } from "utils/hooks";
import { getFilterValues } from "utils/helper";
import { tableIndicator, personaDefault, reportingTableData } from "./data";

const AttendanceReporting = ({
  history,
  getReports,
  getCompanies,
  reports,
  companies,
  loading,
  getAllReports,
  aloading,
  menu,
  branches,
  getBranches,
  sites,
  getSites,
  setDateFilter,
  filterDate,
  filterLoading,
}) => {
  //states
  const [branchOptions, setBranchOptions] = useState([]);
  const [reportingList, setReportingList] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);

  const [personaOptions, setPersonaOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [csvData, setcsvData] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [rawReports, setRawReports] = useState([]);

  //refs
  const csvLink = useRef();

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "100px";
    }

    if (!isNil(personaDefault)) {
      setPersonaOptions(personaDefault);
    }
    //api call
    getCompanies();
    getBranches();
    getSites();
  }, []);

  const runFilters = (branchOpt, siteOpt, companyOpt, personaOpt) => {
    getReports({
      search: searchState,
      enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
      startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
      companyIds: getFilterValues(companyOpt),
      siteIds: getFilterValues(siteOpt),
      regionIds: getFilterValues(branchOpt),
      personaId: getFilterValues(personaOpt),
    });
  };

  useEffect(() => {
    if (!isNil(branches?.results)) {
      const opts = branches.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      setBranchOptions(opts);
    }
  }, [branches]);

  useEffect(() => {
    if (!isNil(companies?.results)) {
      const opts = companies.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      const checkedOptions = companyOptions?.filter((item) => item.checked);

      if (checkedOptions.length > 0) {
        const filteredNewOptions = opts.filter((item) =>
          isEmpty(checkedOptions.find((a) => item.id !== a.id))
        );

        let newOpts = [...filteredNewOptions, ...checkedOptions];
        setCompanyOptions(newOpts);
      } else {
        setCompanyOptions(opts);
      }
    }
  }, [companies]);

  useEffect(() => {
    if (!isNil(sites?.results)) {
      const opts = sites.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      setSiteOptions(opts);
    }
  }, [sites]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getReports({
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        companyIds: getFilterValues(companyOptions),
        siteIds: getFilterValues(siteOptions),
        regionIds: getFilterValues(branchOptions),
        personaId: getFilterValues(personaOptions),
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
      setSearchState("");
      setSelectedPage(1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getReports({
        search: event.target.value,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        companyIds: getFilterValues(companyOptions),
        siteIds: getFilterValues(siteOptions),
        regionIds: getFilterValues(branchOptions),
        personaId: getFilterValues(personaOptions),
      });
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const printReports = async (data) => {
    const { labels, columns } = reportingTableData(data, branches);
    const labelData = labels?.map((item) => item.label);
    const columnData = columns?.map((item) => item.values);
    const mergedData = [labelData, ...columnData];

    const handledCsvData = mergedData?.map((item, index) => {
      if (index === 0) {
        return item;
      } else return item.map((item) => item.value);
    });

    setcsvData(handledCsvData);
    await delay(1000);

    csvLink.current.link.click();
  };

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
  });

  useEffect(() => {
    if (!isNil(reports)) {
      setReportingList(reportingTableData(reports.results, branches));
      setRawReports(reports.results);
    }
  }, [reports]);

  useEffect(() => {
    if (filterDate?.enddate && filterDate?.startdate) {
      getReports({
        enddate: filterDate.enddate,
        startdate: filterDate.startdate,
        search: searchState,
        companyIds: getFilterValues(companyOptions),
        siteIds: getFilterValues(siteOptions),
        regionIds: getFilterValues(branchOptions),
        personaId: getFilterValues(personaOptions),
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
    }
  }, [filterDate]);

  const { requestSort, sortConfig } = useSortableData(rawReports);
  const sortBy = (key) => {
    requestSort(key);
    if (!isNil(sortConfig)) {
      getReports({
        search: searchState,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        companyIds: getFilterValues(companyOptions),
        siteIds: getFilterValues(siteOptions),
        regionIds: getFilterValues(branchOptions),
        personaId: getFilterValues(personaOptions),
        sort:
          sortConfig.direction.toString() === "descending" ? `-${key}` : key,
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headContainer}>
        <DateRangeFilter
          value={filterDate}
          onChange={(val) => {
            setDateFilter({
              startdate: getUnixTime(val[0].startDate),
              enddate: getUnixTime(val[0].endDate),
            });
            // setFilterDate({
            //   startdate: getUnixTime(val[0].startDate),
            //   enddate: getUnixTime(val[0].endDate),
            // });
          }}
        />
      </div>
      {menu}
      <div className={classes.filterContainer}>
        <Filters
          filterLoading={filterLoading}
          runFilters={runFilters}
          branchOptions={branchOptions}
          setBranchOptions={setBranchOptions}
          siteOptions={siteOptions}
          setSiteOptions={setSiteOptions}
          companyOptions={companyOptions}
          setCompanyOptions={setCompanyOptions}
          personaOptions={personaOptions}
          setPersonaOptions={setPersonaOptions}
        />
        <div className={classes.indicatorWrapper}>
          {tableIndicator.map((item) => (
            <div className={classes.indicatorItem}>
              <div style={{ background: item.color }} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          handleKeyDown={handleKeyDown}
          isSearchable={true}
          searchState={searchState}
          loading={loading}
          onSearch={(e) => onSearch(e)}
          data={reportingList}
          sortByDesc={(val) => sortBy(val)}
          sortConfig={sortConfig}
          clickable={true}
          onRowClick={(val) => {
            history.push(
              `/supervisor-reporting-attendance-detail?id=${val.id}&firstName=${val.firstName}&lastName=${val.lastName}`
            );
          }}
        />
      </div>
      <Pagination
        totalCount={reports?.count}
        itemsPerPage={reports?.items_per_page}
        currentPage={selectedPage}
        onChange={(val) => {
          getReports({
            page: val,
            search: searchState,
            enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
            startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
            companyIds: getFilterValues(companyOptions),
            siteIds: getFilterValues(siteOptions),
            regionIds: getFilterValues(branchOptions),
            personaId: getFilterValues(personaOptions),
            sort:
              !isNil(sortConfig) &&
              sortConfig?.direction?.toString() === "descending"
                ? `-${sortConfig?.key}`
                : sortConfig?.key,
          });
          setSelectedPage(val);
        }}
      />

      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
            <button
              disabled={aloading}
              className={classes.darkButton}
              onClick={() => {
                getAllReports({
                  callBack: (val) => printReports(val),
                  search: searchState,
                  enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
                  startdate:
                    !isNil(filterDate?.startdate) && filterDate.startdate,
                  companyIds: getFilterValues(companyOptions),
                  siteIds: getFilterValues(siteOptions),
                  regionIds: getFilterValues(branchOptions),
                  personaId: getFilterValues(personaOptions),
                  sort:
                    !isNil(sortConfig) &&
                    sortConfig?.direction?.toString() === "descending"
                      ? `-${sortConfig?.key}`
                      : sortConfig?.key,
                });
              }}
            >
              {aloading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#40507e"}
                  height={30}
                  width={30}
                />
              ) : (
                "Export"
              )}
            </button>
            <CSVLink
              filename={`${
                !isNil(filterDate) &&
                !isNil(filterDate?.startdate) &&
                !isNil(filterDate?.enddate)
                  ? `${format(
                      new Date(filterDate?.startdate * 1000),
                      "yyyyMMdd"
                    )}-${format(
                      new Date(filterDate?.enddate * 1000),
                      "yyyyMMdd"
                    )}`
                  : "reports"
              }.csv`}
              className="hidden"
              data={csvData}
              ref={csvLink}
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  filterLoading: makeSelectFilterLoading(),
  reports: makeSelectAttendanceReports(),
  companies: makeSelectCompanies(),
  branches: makeSelectRegions(),
  allReports: makeSelectAllAttendancesReports(),
  aloading: makeSelectAllReportsLoading(),
  sites: makeSelectSites(),
  filterDate: makeSelectDateFilter(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getReports: (val) => dispatch(getAttendanceReportsRequest(val)),
    getAllReports: (val) => dispatch(getAllAttendaceReportsRequest(val)),
    getBranches: (val) => dispatch(getRegionRequest(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
    setDateFilter: (val) => dispatch(setDateFilterReq(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceReporting);
