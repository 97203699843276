import React, { useEffect, useState, createRef } from "react";
import classes from "./translations.module.scss";
import {
  getLanguagesRequest,
  createLanguageRequest,
  deleteLanguageRequest,
} from "store/language/actions";
import {
  makeSelectLanguages,
  makeSelectLoading,
} from "store/language/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactLoading from "react-loading";
import { defaultLanguages } from "./defaultLanguages";
import SelectInput from "components/Supervisor/select";
import Modal from "components/Supervisor/modal";

// const TickIcon = process.env.PUBLIC_URL + "/icons/tick.svg";
const TickIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99989 10.17L2.52989 6.70001C2.13989 6.31001 1.50989 6.31001 1.11989 6.70001C0.729893 7.09001 0.729893 7.72001 1.11989 8.11001L5.29989 12.29C5.68989 12.68 6.31989 12.68 6.70989 12.29L17.2899 1.71001C17.6799 1.32001 17.6799 0.690007 17.2899 0.300007C16.8999 -0.0899927 16.2699 -0.0899927 15.8799 0.300007L5.99989 10.17Z"
          fill="#03CE91"
        />
      </svg>
    </>
  );
};
const SupervisorSettings = ({
  languages,
  createLanguage,
  getLanguages,
  loading,
  deleteLanguage,
}) => {
  useEffect(() => {
    getLanguages();
  }, []);

  const [defaultLanguageList, setDefaultLanguageList] = useState([]);
  const [languageList, setLanguageList] = useState(false);
  const [number, setNumber] = useState(0);
  const [elRefs, setElRefs] = useState([]);
  const [modal, setModal] = useState([]);
  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(languageList.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [languageList.length]);

  const setProgress = () => {
    const counter = setInterval(() => {
      setNumber((number) =>
        number !== 100 && number !== 80 ? number + 1 : 80
      );
      if (number === 80) {
        return clearInterval(counter);
      }
    }, 10);
  };

  useEffect(() => {
    if (languages.length) {
      const langs = languages.map((item, index) => {
        return {
          name: item.name,
          code: item.code,
          data: item.data,
          uploading: false,
          error: false,
          success: true,
        };
      });

      setLanguageList(langs);
    }
  }, [languages]);

  useEffect(() => {
    const dfList = defaultLanguages.map((item) => {
      return {
        value: item.code,
        label: item.name,
      };
    });

    setDefaultLanguageList(dfList);
  }, [defaultLanguages]);

  const setCreateLanguageState = ({ success, item }) => {
    const newList = languageList?.map((a) => {
      if (a.code === item.code) {
        return item;
      } else {
        return a;
      }
    });
    setNumber(100);
    return setLanguageList(newList);
  };

  const deleteLanguageSuccess = ({ success, code }) => {
    const newList = languageList.filter((a) => a.code !== code);
    return setLanguageList(newList);
  };

  const onChangeFile = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setNumber(0);
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      // The file's text will be printed here
      setProgress();
      const newList = languageList.map((a) => {
        if (a.code === item.code) {
          return { ...item, uploading: true };
        } else {
          return a;
        }
      });
      setLanguageList(newList);
      createLanguage({
        name: item.name,
        code: item.code,
        data: e.target.result,
        callback: ({ success, item }) =>
          setCreateLanguageState({ success, item }),
        item: item,
      });
    };

    reader.onerror = function (e) {
      console.log("error", e);
    };

    reader.readAsText(file);
  };
  return (
    <>
      {loading ? (
        <div>
          <div className={classes.loadingContainer}>
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.container}>
            <h3 className={classes.header}>
              Export induction text as JSON file for translation
            </h3>
            <span className={classes.text}>
              The file contains induction text and user interface elements also
              called labels. In case you're not familiar with JSON editing visit
              our help centre.
            </span>
            <a href={"../../../translations/en.json"} download>
              <button className={classes.button}>Export JSON file</button>
            </a>
            <div className={classes.divider} />
            <h3 className={classes.header}>Import translated induction text</h3>
            <span className={classes.text}>
              Chose the desired language and upload translated JSON file.
            </span>
            <>
              {languageList &&
                languageList.map((item, index) => (
                  <div className={classes.uploadLanguageContainer} key={index}>
                    {item.success ? (
                      <div className={classes.successLanguageContainer}>
                        <span>{item.name}</span>
                        <TickIcon />
                        {/* <img
                          src={TickIcon}
                          alt={"tick"}
                          className={classes.tickIcon}
                        /> */}
                      </div>
                    ) : (
                      <div style={{ position: "relative" }}>
                        <SelectInput
                          error={item.error}
                          onChange={(val) => {
                            const newItem = {
                              name: val.label,
                              code: val.value,
                              data: null,
                              success: false,
                              error: false,
                              uploading: false,
                            };

                            const newList = languageList.map((item, i) => {
                              if (i === index) {
                                return newItem;
                              } else {
                                return item;
                              }
                            });
                            setLanguageList(newList);
                          }}
                          tabSelectsValue={item.code}
                          value={defaultLanguageList.filter(
                            (option) => option.value === item.code
                          )}
                          placeholder={"Select Language"}
                          options={defaultLanguageList}
                        />
                        {item.error && (
                          <span className={classes.error}>{item.error}</span>
                        )}
                      </div>
                    )}
                    <div className={classes.inputContainer}>
                      <button
                        onClick={() => elRefs[index].current.click()}
                        disabled={!item.name}
                        className={classes.button}
                      >
                        {item.uploading && (
                          <span style={{ width: `${number}%` }}></span>
                        )}
                        <p
                          className={classes.buttonText}
                          style={{ color: item.uploading && "white" }}
                        >
                          {item.success
                            ? "Import New Version"
                            : "Import Induction Text"}
                        </p>
                      </button>

                      {item.fileName && <span>{item.fileName}</span>}
                      <input
                        onChange={(e) => onChangeFile(e, item)}
                        type="file"
                        id={`file${index}`}
                        ref={elRefs[index]}
                        style={{ display: "none" }}
                      />
                    </div>
                    {item.success && (
                      <>
                        <div className={classes.configContainer}>
                          <svg
                            width="4"
                            height="12"
                            viewBox="0 0 4 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.00008 3.33332C2.73341 3.33332 3.33341 2.73332 3.33341 1.99999C3.33341 1.26666 2.73341 0.666656 2.00008 0.666656C1.26675 0.666656 0.666748 1.26666 0.666748 1.99999C0.666748 2.73332 1.26675 3.33332 2.00008 3.33332ZM2.00008 4.66666C1.26675 4.66666 0.666748 5.26666 0.666748 5.99999C0.666748 6.73332 1.26675 7.33332 2.00008 7.33332C2.73341 7.33332 3.33341 6.73332 3.33341 5.99999C3.33341 5.26666 2.73341 4.66666 2.00008 4.66666ZM2.00008 8.66666C1.26675 8.66666 0.666748 9.26666 0.666748 9.99999C0.666748 10.7333 1.26675 11.3333 2.00008 11.3333C2.73341 11.3333 3.33341 10.7333 3.33341 9.99999C3.33341 9.26666 2.73341 8.66666 2.00008 8.66666Z"
                              fill="#7185BE"
                            />
                          </svg>
                          <div className={classes.configContent}>
                            <span
                              onClick={() => {
                                setModal({
                                  open: true,
                                  type: "delete",
                                  action: () => {
                                    deleteLanguage({
                                      code: item.code,
                                      callback: ({ success, code }) =>
                                        deleteLanguageSuccess({
                                          success,
                                          code,
                                        }),
                                    });
                                    setModal({
                                      open: false,
                                      type: null,
                                      action: null,
                                      cancel: null,
                                    });
                                  },
                                  cancel: () =>
                                    setModal({
                                      open: false,
                                      type: null,
                                      action: null,
                                      cancel: null,
                                    }),
                                });
                              }}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              <>
                <button
                  onClick={() => {
                    const item = {
                      name: "",
                      code: "",
                      data: "",
                      progress: false,
                      success: false,
                      error: false,
                    };
                    if (!languageList) {
                      setLanguageList([item]);
                    } else {
                      const newList = [...languageList, item];
                      setLanguageList(newList);
                    }
                  }}
                  className={classes.addbutton}
                >
                  New Language
                </button>
              </>
            </>
          </div>
        </div>
      )}
      <Modal
        open={modal.open}
        cancel={modal.cancel}
        action={modal.action}
        type={modal.type}
        loading={loading}
        deleteTitle={"You are about to delete translation file."}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: (val) => dispatch(getLanguagesRequest(val)),
    createLanguage: (val) => dispatch(createLanguageRequest(val)),
    deleteLanguage: (val) => dispatch(deleteLanguageRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorSettings);
