import React from "react";
import classes from "./footer.module.scss";
const Footer = ({ children, transparent }) => {
  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: transparent && "transparent" }}
        className={classes.DesktopFooter}
      />
      <div
        style={{ backgroundColor: transparent && "transparent" }}
        className={classes.FooterContainer}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default React.memo(Footer);
