import React, { useState, useEffect } from "react";
import Card from "components/Supervisor/card";
import classes from "./form.module.scss";
import Input from "components/Supervisor/input";
import { isNaN, isNil } from "lodash";

import {
  deleteRegionRequest,
  editRegionRequest,
  createRegionRequest,
  getUsersRequest,
} from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectRegions,
  makeSelectUsers,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik, Form, Field } from "formik";
import ReactLoading from "react-loading";
import queryString from "query-string";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const RegionForm = ({
  deleteRegion,
  regions,
  history,
  createRegion,
  updateRegion,
  loading,
  siteManagers,
  getManagers,
}) => {
  const [siteManagerOptions, setSiteManagerOptions] = useState([]);

  useEffect(() => {
    getManagers({
      report: 1,
    });
  }, []);

  useEffect(() => {
    if (!isNil(siteManagers)) {
      const options = siteManagers.map((item) => {
        return {
          value: item.id,
          label: `${item.first_name} ${item.last_name} - ${item.email}`,
        };
      });
      setSiteManagerOptions(options);
    }
  }, [siteManagers]);

  const id = Number(queryString.parse(window.location.search).id);

  const initialValues = {
    id: id,
    code: !isNaN(id)
      ? regions?.results?.find((item) => item.id === id)?.code
      : "",
    name: !isNaN(id)
      ? regions?.results?.find((item) => item.id === id)?.name
      : "",
    sites: !isNaN(id)
      ? regions?.results?.find((item) => item.id === id)?.address2
      : "",
    branch_managers: !isNaN(id)
      ? regions?.results
          ?.find((item) => item.id === id)
          ?.branch_managers.map((item) => {
            return {
              label: `${item.user.first_name} ${item.user.last_name} `,
              value: item.user.id,
            };
          })
      : [],
    contracts_managers: !isNaN(id)
      ? regions?.results
          ?.find((item) => item.id === id)
          ?.contracts_managers.map((item) => {
            return {
              label: `${item.user.first_name} ${item.user.last_name} `,
              value: item.user.id,
            };
          })
      : [],
    hse_managers: !isNaN(id)
      ? regions?.results
          ?.find((item) => item.id === id)
          ?.hse_managers.map((item) => {
            return {
              label: `${item.user.first_name} ${item.user.last_name} `,
              value: item.user.id,
            };
          })
      : [],
  };
  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div className={classes.container}>
          <img
            alt={"back icon"}
            onClick={() => history.push("/supervisor-settings-branch")}
            src={BackIcon}
            className={classes.backIcon}
          />
          <div className={classes.form}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                //Only run on edit page
                if (!isNaN(id)) {
                  updateRegion({
                    values: values,
                  });
                }
                //Only runs on create page
                if (isNaN(id)) {
                  createRegion({
                    values: values,
                  });
                }
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Form>
                  <Card title={"Branch code"}>
                    <Field
                      name={"code"}
                      value={values.code}
                      onChange={(e) => {
                        setFieldValue("code", e.target.value);
                      }}
                      label={"Branch Code"}
                      placeholder={"Branch Code"}
                      component={Input}
                    />
                    <Field
                      name={"name"}
                      value={values.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                      label={"Full name"}
                      placeholder={"name"}
                      component={Input}
                    />
                  </Card>
                  <Card title={"Managers"}>
                    <Field
                      onChange={(val, type) => {
                        setFieldValue("branch_managers", val);
                      }}
                      value={values?.branch_managers?.map((item) => {
                        return {
                          label: item?.label?.split("-")[0],
                          value: item.value,
                        };
                      })}
                      options={siteManagerOptions}
                      type={"select"}
                      label={"Branch Manager"}
                      placeholder={"Select from the list"}
                      isMulti
                      component={Input}
                    />
                    <Field
                      onChange={(val, type) => {
                        setFieldValue("contracts_managers", val);
                      }}
                      value={values?.contracts_managers?.map((item) => {
                        return {
                          label: item.label.split("-")[0],
                          value: item.value,
                        };
                      })}
                      options={siteManagerOptions}
                      type={"select"}
                      label={"Contracts manager"}
                      placeholder={"Select from the list"}
                      isMulti
                      component={Input}
                    />
                    <Field
                      onChange={(val, type) => {
                        setFieldValue("hse_managers", val);
                      }}
                      value={values?.hse_managers?.map((item) => {
                        return {
                          label: item.label.split("-")[0],
                          value: item.value,
                        };
                      })}
                      options={siteManagerOptions}
                      type={"select"}
                      label={"EHS manager"}
                      placeholder={"Select from the list"}
                      isMulti
                      component={Input}
                    />
                  </Card>

                  <div className={classes.buttonContainer}>
                    <div className={classes.buttonWrapper}>
                      <div className={classes.flexStart}>
                        {!isNaN(id) && (
                          <button
                            className={classes.lightButton}
                            onClick={() => deleteRegion({ id: id })}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                      <div className={classes.flexEnd}>
                        <button
                          onClick={() =>
                            history.push("/supervisor-settings-branch")
                          }
                          className={classes.lightButton}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={loading}
                          className={classes.darkButton}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  regions: makeSelectRegions(),
  siteManagers: makeSelectUsers(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    deleteRegion: (val) => dispatch(deleteRegionRequest(val)),
    updateRegion: (val) => dispatch(editRegionRequest(val)),
    createRegion: (val) => dispatch(createRegionRequest(val)),
    getManagers: (val) => dispatch(getUsersRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionForm);
