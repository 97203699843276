import React, { useEffect, useState } from "react";
import Card from "components/Supervisor/card";
import classes from "./roles.module.scss";
import Input from "components/Supervisor/input";
import Checkbox from "components/Supervisor/checkbox";
import { capitalize, isEmpty, isNil, map, groupBy } from "lodash";

import {
  getRolesRequest,
  getPermissionsRequest,
  createRole,
  deleteRole,
  updateRole,
  getRoleByIdRequest,
} from "store/supervisor/actions";
import {
  makeSelectRoles,
  makeSelectLoading,
  makeSelectPermissions,
  makeSelectCurrentRole,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactLoading from "react-loading";
import ArrowIcon from "assets/supervisorIcons/arrowIcon";
import CloseIcon from "assets/supervisorIcons/closeIcon";
import queryString from "query-string";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const Form = ({
  permissions,
  createRoleRequest,
  updateRoleRequest,
  deleteRoleRequest,
  roles,
  history,
  getRoleById,
  currentRole,
  loading,
}) => {
  const id = queryString.parse(window.location.search).id;

  const [currentList, setCurrentList] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(false);

  const [name, setName] = useState("");

  const [selectedRole, setSelectedRole] = useState(null);
  const [prevList, setPrevlist] = useState([]);

  const [permissionsList, setPermissionsList] = useState(null);

  const [rawPermission, setRawPermissions] = useState([]);

  const [changes, setChanges] = useState([]);

  //reset function for resetting the page settings to start
  const resetChanges = (item) => {
    const newList = currentList.map((a, index) => {
      if (index === item.index) {
        return prevList[index];
      } else {
        return a;
      }
    });

    const undoChanges = changes.filter((a) => {
      return a.content_type !== item.name;
    });
    setCurrentList(newList);
    setChanges(undoChanges);
    setSelectedPermission(false);
  };

  //handler function for group by permissions according to content_type
  const handleSelectedPermissions = (item) =>
    map(groupBy(item, "content_type"), (clist) =>
      clist.map((item) => {
        return { checked: true, ...item };
      })
    );

  //handler function for group by permissions according to content_type
  const handlePermissions = (item) =>
    map(groupBy(item, "content_type"), (clist) =>
      clist.map((item) => {
        return { checked: false, ...item };
      })
    );

  //sets permissions if permissions exist
  useEffect(() => {
    setPermissionsList(handlePermissions(permissions));
    setRawPermissions(permissions);
  }, [permissions]);

  useEffect(() => {
    //only runs on edit page
    //sets the current data
    if (!isNil(id) && !isNil(currentRole)) {
      if (currentRole) {
        setPageSettings();
        setSelectedRole(handleSelectedPermissions(currentRole.permissions));
        setName(currentRole.name);
      }
    }
  }, [currentRole]);

  useEffect(() => {
    if (!isNil(id)) {
      getRoleById({ id: id });
    }
  }, [id]);

  const setPageSettings = () => {
    //only run on create page
    if (!isEmpty(permissionsList) && isNil(id)) {
      const newList = permissionsList.map((item, index) => {
        return {
          name: item[0].content_type,
          checked: false,
          selected: false,
          data: item,
          index: index,
        };
      });
      setCurrentList(newList);
      setPrevlist(newList);
    }

    //Only runs on edit page
    if (!isEmpty(permissionsList) && !isNil(id) && selectedRole) {
      const editData = selectedRole?.map((item, index) => {
        return {
          name: item[0].content_type,
          checked: true,
          selected: false,
          data: item,
          index: index,
        };
      });

      const newList = permissionsList.map((item, index) => {
        return {
          name: item[0].content_type,
          checked: !isNil(editData.find((a) => a.name === item[0].content_type))
            ? editData.find((a) => a.name === item[0].content_type).checked
              ? true
              : false
            : false,
          selected: false,
          data: !isNil(editData.find((a) => a.name === item[0].content_type))
            ? item.map((c) => {
                return {
                  ...c,
                  checked: editData
                    .find((a) => a.name === item[0].content_type)
                    .data.find((item) => item.codename === c.codename)
                    ? true
                    : false,
                };
              })
            : item,
          index: index,
        };
      });

      setCurrentList(newList);
      setPrevlist(newList);
    }
  };

  useEffect(() => {
    if (isNil(id)) {
      setPageSettings();
    }
  }, [permissionsList]);

  useEffect(() => {
    if (!isNil(id)) {
      setPageSettings();
    }
  }, [selectedRole, permissionsList]);

  const selectItem = (index) => {
    const newList = currentList.map((item, i) => {
      return i === index
        ? { ...item, selected: true }
        : { ...item, selected: false };
    });

    const selectedItem = {
      data: newList[index].data,
      ...newList[index],
    };

    setCurrentList(newList);
    setSelectedPermission(selectedItem);
  };

  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        currentList &&
        rawPermission && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "150px",
            }}
          >
            <img
              alt={"back icon"}
              onClick={() => history.push("/supervisor-settings-roles")}
              src={BackIcon}
              style={{
                width: "50px",
                height: "50px",
                cursor: "pointer",
                margin: "10px 10px 10px 0px",
              }}
            />
            <div className={classes.form}>
              <Card title={"Details"}>
                <Input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  label={"Name"}
                  placeholder={"Name"}
                />
              </Card>
              <Card title={"User permissions"}>
                <span className={classes.descriptionText}>
                  Specify what this role can do. For each module type, control
                  access to specific actions and resources.
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    minHeight: "400px",
                    height: "100%",
                    overflow: "scroll",
                  }}
                >
                  {/* Permission List */}
                  <div className={classes.permissionsList}>
                    <div className={classes.permissionsItem}>
                      <Checkbox
                        checked={
                          currentList
                            .filter((item) => item.checked)
                            .map((item) => item.data)
                            .flatMap((item) => item.map((a) => a))
                            .filter((item) => item.checked)
                            .map((item) => item.codename).length ===
                          rawPermission.length
                        }
                        onClick={() => {
                          const newList = currentList.map((item) => {
                            return {
                              ...item,
                              selected: false,
                              checked: !checkAll,
                              data: item.data.map((a) => {
                                return {
                                  ...a,
                                  checked: !checkAll,
                                };
                              }),
                            };
                          });
                          setChanges(newList);
                          setCurrentList(newList);
                          setSelectedPermission(false);
                          setCheckAll(!checkAll);
                        }}
                      />
                      <span className={classes.permissionName}>
                        All modules & all actions
                      </span>
                    </div>
                    {!isEmpty(currentList) &&
                      currentList.map((item, index) => (
                        <div
                          className={
                            !item.selected
                              ? classes.permissionsItem
                              : classes.selectedPermissionsItem
                          }
                        >
                          <Checkbox
                            onClick={() => {
                              const newList = currentList.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    checked: !item.checked,
                                    data: currentList[i].data.map((b) => {
                                      return {
                                        ...b,
                                        checked: !item.checked,
                                      };
                                    }),
                                  };
                                } else {
                                  return item;
                                }
                              });
                              setCurrentList(newList);

                              const changeList = [
                                ...changes,
                                { content_type: item.name },
                              ];
                              setChanges(changeList);
                            }}
                            checked={
                              item.data.filter((a) => a.checked === true)
                                .length > 0
                            }
                          />
                          <div
                            style={{ display: "flex", width: "100%" }}
                            onClick={() => selectItem(index)}
                          >
                            <span
                              onClick={() => selectItem(index)}
                              className={classes.permissionName}
                            >
                              {capitalize(item.name)}
                            </span>
                            {item.data.filter((a) => a.checked === true)
                              .length === item.data.length && (
                              <div
                                onClick={() => selectItem(index)}
                                style={{
                                  backgroundColor: item.selected
                                    ? "white"
                                    : "#f6f7fa",
                                }}
                                className={classes.fullAccessBadge}
                              >
                                <span>Full Access</span>
                              </div>
                            )}
                            <div
                              style={{
                                width: "5px",
                                height: "5px",
                                marginRight: "20px",
                                marginBottom: "20px",
                              }}
                              onClick={() => selectItem(index)}
                            >
                              <ArrowIcon
                                style={{
                                  alignSelf: "center",
                                  justifySelf: "center",
                                  marginRight: "20px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* if selected permission item exist shows selected container item */}
                  <div
                    style={{ background: !selectedPermission && "white" }}
                    className={classes.selectedContainer}
                  >
                    {selectedPermission && (
                      <>
                        <span className={classes.selectedTitle}>
                          {capitalize(selectedPermission.name)}
                        </span>
                        <div className={classes.closeIconContainer}>
                          <CloseIcon
                            onClick={() => setSelectedPermission(false)}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              display: "flex",
                              padding: "20px",
                              height: "67px",
                            }}
                          >
                            <span className={classes.selectedItemActionTitle}>
                              Actions
                            </span>
                            {changes.filter(
                              (item) =>
                                (item.content_type ===
                                  selectedPermission.name) |
                                (item.name === selectedPermission.name)
                            ).length > 0 && (
                              <div
                                className={classes.restoreButton}
                                onClick={() => resetChanges(selectedPermission)}
                              >
                                <span>Restore role defaults</span>
                              </div>
                            )}
                          </div>
                          {currentList.filter((a) => a.selected).length > 0 &&
                            currentList
                              .filter((a) => a.selected)[0]
                              .data.map((item, index) => (
                                <div
                                  className={classes.selectedItemContainer}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  >
                                    <Checkbox
                                      onClick={() => {
                                        const newData = selectedPermission.data.map(
                                          (a, i) => {
                                            return i === index
                                              ? { ...a, checked: !a.checked }
                                              : {
                                                  ...a,
                                                  checked: a.checked
                                                    ? a.checked
                                                    : false,
                                                };
                                          }
                                        );
                                        setSelectedPermission({
                                          ...selectedPermission,
                                          data: newData,
                                        });

                                        const newList = currentList.map(
                                          (element, i) => {
                                            return i ===
                                              selectedPermission.index
                                              ? {
                                                  ...element,
                                                  data: newData,
                                                }
                                              : element;
                                          }
                                        );
                                        setCurrentList(newList);
                                        const changeList = [...changes, item];
                                        setChanges(changeList);
                                      }}
                                      checked={item.checked}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span className={classes.selectItemTitle}>
                                      {capitalize(item.name.split(" ")[1])}
                                    </span>
                                    <span
                                      className={classes.selectItemDescription}
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                </div>
                              ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div className={classes.buttonContainer}>
              <div
                style={{
                  width: "75%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ justifyContent: "flex-start" }}
                  className={classes.flex}
                >
                  {!isNil(id) && (
                    <button
                      className={classes.lightButton}
                      onClick={() => deleteRoleRequest({ id: id })}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div
                  style={{ justifyContent: "flex-end" }}
                  className={classes.flex}
                >
                  <button
                    onClick={() => history.push("/supervisor-settings/roles")}
                    className={classes.lightButton}
                  >
                    Cancel
                  </button>

                  <button
                    className={classes.darkButton}
                    onClick={() => {
                      const rawList = currentList
                        .filter((item) => item.checked)
                        .map((item) => item.data)
                        .flatMap((item) => item.map((a) => a))
                        .filter((item) => item.checked)
                        .map((item) => item.codename);

                      !isNil(id) &&
                        updateRoleRequest({
                          id: id,
                          name: name,
                          permissions: rawList.toString(),
                        });
                      isNil(id) &&
                        createRoleRequest({
                          name: name,
                          permissions: rawList.toString(),
                        });
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
  loading: makeSelectLoading(),
  permissions: makeSelectPermissions(),
  currentRole: makeSelectCurrentRole(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: (val) => dispatch(getRolesRequest(val)),
    getPermissions: (val) => dispatch(getPermissionsRequest(val)),
    createRoleRequest: (val) => dispatch(createRole(val)),
    deleteRoleRequest: (val) => dispatch(deleteRole(val)),
    updateRoleRequest: (val) => dispatch(updateRole(val)),
    getRoleById: (val) => dispatch(getRoleByIdRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
