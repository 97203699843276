import React from "react";
import BackArrow from "./back.svg";
import classes from "./privacy.module.scss";
import cn from "classnames";

//components start
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Header from "components/UI/header";
import { FormattedMessage } from "react-intl";
//components endUI

const PrivacyPolicy = ({
  isPrivacyPolicyOpen,
  setPrivacyPolicyOpen,
  setPrivacyPolicyAgreed,
  setFieldValue,
}) => {
  return (
    <div className={cn(classes.container, isPrivacyPolicyOpen && classes.open)}>
      <Header transparent />
      <div className={classes.policyWrapper}>
        <div
          className={classes.backArrowWrapper}
          onClick={() => {
            setPrivacyPolicyOpen(false);
          }}
        >
          <img
            alt={"Back Arrow"}
            className={classes.backArrow}
            src={BackArrow}
          />
        </div>
        <h1>
          <FormattedMessage id={"privacy.policy.main.title"} />
        </h1>
        {/* <h1> Privacy Policy </h1> */}
        <p>
          <FormattedMessage id={"privacy.policy.description.1"} />
          {/* This Privacy Policy describes how Fletcher Residential Limited (“we”,
          “us”, “our”) collect, hold and use your personal information in
          connection with our Environment, Health and Safety (“EHS”) System. */}
          <br /> <br />
          <FormattedMessage id={"privacy.policy.description.2"} />
          {/* We operate in accordance with the Privacy Act 2020. */}
          <br /> <br />
          <FormattedMessage id={"privacy.policy.description.3"} />
          {/* By agreeing to enter your contact details you are deemed to accept the
          terms and conditions of our Privacy Policy. */}
        </p>
        <h1>
          <FormattedMessage id={"privacy.policy.title.1"} />
          {/* Collection of Information */}
        </h1>
        <p>
          <FormattedMessage id={"privacy.policy.description.4"} />
          {/* The personal information we may collect includes, but is not limited
          to: */}
        </p>
        <ul>
          <li>
            <FormattedMessage id={"privacy.policy.description.5"} />
            {/* Your name; */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.6"} />
            {/* Name of company you are employed by; */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.7"} />
            {/* Your contact information (including your mail address and mobile
            number); */}
          </li>
          <li>
            {" "}
            <FormattedMessage id={"privacy.policy.description.8"} />
            {/* Your sign in and sign out activities on our sites; and */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.9"} />
            {/* Details from your written communications with us (whether electronic
            or otherwise). */}
          </li>
        </ul>
        <h1>
          <FormattedMessage id={"privacy.policy.title.2"} />
          {/* How We Use Your Information */}
        </h1>
        <p>
          <FormattedMessage id={"privacy.policy.description.10"} />
          {/* We (and any third parties as authorised by this Privacy Policy)
          collect this information in order to; */}
        </p>
        <ul>
          <li>
            <FormattedMessage id={"privacy.policy.description.11"} />
            <FormattedMessage id={"privacy.policy.description.12"} />
            {/* See who is present on our sites, including being aware of your
            presence in the case of an emergency;{" "} */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.13"} />
            {/* Keep you safe by ensuring you are inducted into our EHS system prior
            to working on our sites */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.14"} />
            {/* Induct you into our Fletcher Living Life Saving Rules; and */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.15"} />
            {/* Remind you when you need to renew your EHS Inductions – either via
            our Online Portal, or at one of our sites. */}
          </li>
        </ul>
        <h1>
          <FormattedMessage id={"privacy.policy.title.3"} />
          {/* Methods of Collection */}
        </h1>
        <p>
          <FormattedMessage id={"privacy.policy.description.16"} />
          {/* We collect personal information about you when you: */}
        </p>
        <ul>
          <li>
            <FormattedMessage id={"privacy.policy.description.17"} />
            {/* are inducted into our EHS system; */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.18"} />
            {/* visit one of our sites (please be aware we collect some information
            through video and camera surveillance) */}
          </li>
        </ul>
        <p>
          <FormattedMessage id={"privacy.policy.description.19"} />
          {/* Providing some information is optional; */}
        </p>
        <ul>
          <li>
            <FormattedMessage id={"privacy.policy.description.20"} />
            {/* If you choose not to enter your contact details we will be unable to
            register you on our sites, or induct you into our Life Saving Rules */}
          </li>
          <li>
            <FormattedMessage id={"privacy.policy.description.21"} />
            {/* If you choose not to enter your email address we will be unable to
            send you reminders about renewing your EHS Induction(s). */}
          </li>
        </ul>
        <h1>
          <FormattedMessage id={"privacy.policy.title.4"} />
          {/* Storage of Information */}
        </h1>
        <p>
          <FormattedMessage id={"privacy.policy.description.22"} />
          {/* We keep your information safe by storing it */}

          <FormattedMessage id={"privacy.policy.description.23"} />
          {/* on our secure database and email communication platforms. To the
          extent permitted by law we may share the personal information you have
          provided us with, but we will only allow access to staff members that
          require it to perform their job and third parties connected with our
          EHS management and reporting requirements. */}
        </p>
        <p>
          <FormattedMessage id={"privacy.policy.description.24"} />
          {/* Except as set out in this Privacy Policy, or where required or
          permitted by law, we will not disclose your personal information to
          any other person or agencies */}
        </p>
        <p>
          <FormattedMessage id={"privacy.policy.description.25"} />
          {/* You have the right to ask for a copy of any personal information we
          hold about you, and to ask for it to be corrected if you think it is
          wrong. */}
          <FormattedMessage id={"privacy.policy.description.26"} />
          {/* We will destroy or de-identify personal information we hold once it is
          no longer needed for a valid purpose or required to be kept by law. */}
        </p>
        <p>
          <FormattedMessage id={"privacy.policy.description.27"} />
          {/* If you would like to ask for a copy of your information, or to have it
          corrected please reach out to our local branch EHS Managers. */}
        </p>
      </div>
      <Footer>
        <Button
          formatID="privacy.button"
          text="I agree"
          type="button"
          onClick={() => {
            setPrivacyPolicyOpen(false);
            setPrivacyPolicyAgreed(true);
            setFieldValue("checkbox", true);
          }}
        />
      </Footer>
    </div>
  );
};

export default PrivacyPolicy;
