import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ClickOutside } from "utils/helper";

import classes from "./filterSelector.module.scss";
import { isEmpty, isNil } from "lodash";
import ArrowIcon from "./arrow.svg";
import Checkbox from "../checkbox";

const FilterSelector = ({
  title,
  options,
  onChange,
  disabled,
  selectedPeriod,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  useEffect(() => {
    if (!isEmpty(options) && !isNil(options)) {
      setActiveOption(options[0]);
    }
  }, []);

  useEffect(() => {
    if (!isNil(activeOption)) {
      onChange(activeOption.label);
    }
  }, [activeOption]);

  const checkedOptions = options?.filter((item) => item.checked);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div className={cn(classes.selectbox)}>
        <button
          className={cn(classes.selectbox_head)}
          onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
        >
          <span className={classes.title}>
            {" "}
            {!isNil(checkedOptions) &&
            !isEmpty(checkedOptions) &&
            checkedOptions?.length > 0 ? (
              <>
                {title}
                {checkedOptions?.length > 0 && (
                  <div className={classes.counterLabel}>
                    {checkedOptions?.length}
                  </div>
                )}
              </>
            ) : (
              title
            )}
          </span>
          <img
            src={ArrowIcon}
            className={cn(classes.icon, isDropdownOpen && classes.rotate)}
          />
        </button>
        <div className={cn(classes.dropdown, isDropdownOpen && classes.open)}>
          <ul>
            {options &&
              !!options.length &&
              options.map((option, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setActiveOption(option);
                    }}
                  >
                    <Checkbox checked={option.checked ? true : false} />
                    <div className={classes.indicatorItem}>
                      <div style={{ background: option.color }} />
                      <span>{option.label}</span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </ClickOutside>
  );
};

export default FilterSelector;
