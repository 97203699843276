import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./postLogin.module.scss";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";

import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "store/auth/selector";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";

const PostLoginSuccess = (props) => {
  return (
    <div className={classes.PopUpContainer}>
      <div className={classes.PopUpTextWrapper}>
        <span className={classes.PopUpText}>
          <FormattedMessage
            id={"postlogin.success.title"}
            values={{ name: props.user && props.user.first_name }}
          />
          !
          <br />
          <br />
          <FormattedHTMLMessage id={"postlogin.success.description"} />
        </span>
      </div>
      <Footer>
        <Button
          formatID={"postlogin.success.button"}
          theme={"gray"}
          textColor={"#1d1d1d"}
          text={"OK! great"}
          onClick={() => props.history.push("/welcome")}
          color={"#efefef"}
        />
      </Footer>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

export default connect(mapStateToProps)(withRouter(PostLoginSuccess));
