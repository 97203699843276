import produce, { enableES5 } from "immer";
import {
  ASSESMENT_REQUEST,
  ASSESMENT_REQUEST_SUCCESS,
  ASSESMENT_REQUEST_ERROR,
  SET_ASSESMENT_REQUEST,
  SET_ASSESMENT_REQUEST_SUCCESS,
  SET_ASSESMENT_REQUEST_ERROR,
  SET_ASSESMENT_REQUEST_CANCELLED,
  CREATE_ASSESMENT_REQUEST,
  CREATE_ASSESMENT_REQUEST_SUCCESS,
  CREATE_ASSESMENT_REQUEST_ERROR,
  RESET_ASSESMENT_REQUEST,
} from "./constants";

enableES5();

export const initialState = {
  error: null,
  loading: false,
  aloading: false,
  assesment: null,
  assesmentResult: null,
  currentAssesment: null,
  assesmentTryId: null,
};

const LayoutReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ASSESMENT_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case ASSESMENT_REQUEST_SUCCESS:
        draftState.loading = true;
        draftState.assesment = action.data;
        break;
      case ASSESMENT_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SET_ASSESMENT_REQUEST:
        draftState.aloading = true;
        draftState.error = null;
        break;
      case SET_ASSESMENT_REQUEST_SUCCESS:
        draftState.aloading = false;
        draftState.assesmentResult = action.data;
        break;
      case SET_ASSESMENT_REQUEST_ERROR:
        draftState.aloading = false;
        draftState.error = action.data;
        break;
      case SET_ASSESMENT_REQUEST_CANCELLED:
        draftState.aloading = false;
        draftState.error = action.data;
        break;
      case CREATE_ASSESMENT_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_ASSESMENT_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.currentAssesment = action.data;
        draftState.assesmentTryId = action.data.assesmentTryId;
        break;
      case CREATE_ASSESMENT_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case RESET_ASSESMENT_REQUEST:
        draftState.loading = false;
        draftState.assesmentResult = null;
        break;
      default:
        break;
    }
  });
};

export default LayoutReducer;
