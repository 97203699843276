import produce from "immer";
import {
  GET_CONTRACTOR_LIST_REQUEST,
  GET_CONTRACTOR_LIST_REQUEST_ERROR,
  GET_CONTRACTOR_LIST_REQUEST_SUCCESS,
  GET_CONTRACTOR_STATS_REQUEST,
  GET_CONTRACTOR_STATS_REQUEST_ERROR,
  GET_CONTRACTOR_STATS_REQUEST_SUCCESS,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_REQUEST_ERROR,
  GET_DASHBOARD_REQUEST_SUCCESS,
  GET_ROLES_REQUEST,
  GET_ROLES_REQUEST_ERROR,
  GET_ROLES_REQUEST_SUCCESS,
  SET,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_REQUEST_ERROR,
  GET_PERMISSIONS_REQUEST_SUCCESS,
  CREATE_ROLE,
  CREATE_ROLE_ERROR,
  CREATE_ROLE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_ERROR,
  DELETE_ROLE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_REQUEST_ERROR,
  GET_USERS_REQUEST_SUCCESS,
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  IMPORT_STAFF,
  IMPORT_STAFF_ERROR,
  IMPORT_STAFF_SUCCESS,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_REQUEST_ERROR,
  GET_USER_BY_ID_REQUEST_SUCCESS,
  GET_ROLE_BY_ID_REQUEST,
  GET_ROLE_BY_ID_REQUEST_ERROR,
  GET_ROLE_BY_ID_REQUEST_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES,
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  MERGE_COMPANY,
  MERGE_COMPANY_ERROR,
  MERGE_COMPANY_SUCCESS,
  GET_SITE_REQUEST,
  GET_SITE_REQUEST_ERROR,
  GET_SITE_REQUEST_SUCCESS,
  CREATE_SITE,
  CREATE_SITE_ERROR,
  CREATE_SITE_SUCCESS,
  DELETE_SITE,
  DELETE_SITE_ERROR,
  DELETE_SITE_SUCCESS,
  UPDATE_SITE,
  UPDATE_SITE_ERROR,
  UPDATE_SITE_SUCCESS,
  GET_SITE_BY_ID_REQUEST,
  GET_SITE_BY_ID_REQUEST_ERROR,
  GET_SITE_BY_ID_REQUEST_SUCCESS,
  GET_SITE_STATS_REQUEST,
  GET_SITE_STATS_REQUEST_ERROR,
  GET_SITE_STATS_REQUEST_SUCCESS,
  GET_CONTRACTORS_BY_SITE,
  GET_CONTRACTORS_BY_SITE_ERROR,
  GET_CONTRACTORS_BY_SITE_SUCCESS,
  UPDATE_CONTRACTOR_REQUEST,
  UPDATE_CONTRACTOR_REQUEST_ERROR,
  UPDATE_CONTRACTOR_REQUEST_SUCCESS,
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_ERROR,
  GET_ALL_REPORTS,
  GET_ALL_REPORTS_SUCCESS,
  GET_ALL_REPORTS_ERROR,
  GET_WEEKLY_ACTIVITY,
  GET_WEEKLY_ACTIVITY_ERROR,
  GET_WEEKLY_ACTIVITY_SUCCESS,
  GET_ALL_CONTRACTORS_BY_SITE,
  GET_ALL_CONTRACTORS_BY_SITE_ERROR,
  GET_ALL_CONTRACTORS_BY_SITE_SUCCESS,
  SET_PERMISSION_ERROR,
  GET_GENERIC_STAFF_LIST_REQUEST,
  GET_GENERIC_STAFF_LIST_REQUEST_ERROR,
  GET_GENERIC_STAFF_LIST_REQUEST_SUCCESS,
  GET_VISITOR_LIST_REQUEST,
  GET_VISITOR_LIST_REQUEST_ERROR,
  GET_VISITOR_LIST_REQUEST_SUCCESS,
  GET_CONTRACTOR_CARDS,
  GET_CONTRACTOR_CARDS_SUCCESS,
  GET_CONTRACTOR_CARDS_ERROR,
  GET_CARD_REPORTS,
  GET_CARD_REPORTS_ERROR,
  GET_CARD_REPORTS_SUCCESS,
  GET_ALL_CARD_REPORTS,
  GET_ALL_CARD_REPORTS_ERROR,
  GET_ALL_CARD_REPORTS_SUCCESS,
  GET_ATTENDANCE_REPORTS,
  GET_ATTENDANCE_REPORTS_ERROR,
  GET_ATTENDANCE_REPORTS_SUCCESS,
  GET_ALL_ATTENDANCE_REPORTS,
  GET_ALL_ATTENDANCE_REPORTS_ERROR,
  GET_ALL_ATTENDANCE_REPORTS_SUCCESS,
  GET_ATTENDANCE_DETAIL,
  GET_ATTENDANCE_DETAIL_ERROR,
  GET_ATTENDANCE_DETAIL_SUCCESS,
  CREATE_CONTRACTOR_CARD,
  CREATE_CONTRACTOR_CARD_ERROR,
  CREATE_CONTRACTOR_CARD_SUCCESS,
  UPDATE_CONTRACTOR_CARD,
  UPDATE_CONTRACTOR_CARD_ERROR,
  UPDATE_CONTRACTOR_CARD_SUCCESS,
  CREATE_REGION_REQUEST,
  CREATE_REGION_REQUEST_ERROR,
  CREATE_REGION_REQUEST_SUCCESS,
  DELETE_REGION_REQUEST,
  DELETE_REGION_REQUEST_ERROR,
  DELETE_REGION_REQUEST_SUCCESS,
  EDIT_REGION_REQUEST,
  EDIT_REGION_REQUEST_ERROR,
  EDIT_REGION_REQUEST_SUCCESS,
  GET_REGION_REQUEST,
  GET_REGION_REQUEST_ERROR,
  GET_REGION_REQUEST_SUCCESS,
  SET_DATE_FILTER,
  DELETE_CONTRACTOR_REQUEST,
  DELETE_CONTRACTOR_REQUEST_ERROR,
  DELETE_CONTRACTOR_REQUEST_SUCCESS,
} from "./constants";

export const initialState = {
  error: null,
  loading: false,
  contractorList: {
    data: undefined,
  },
  dashboard: null,
  contractorStats: null,
  sidebar: "responsive",
  roles: null,
  currentRole: null,
  permissions: null,
  weeklyActivity: null,
  weeklyLoading: null,
  users: null,
  currentUser: null,
  companies: null,
  sites: null,
  currentSite: null,
  siteStats: null,
  contractors: null,
  reports: null,
  allReports: null,
  attendanceReports: null,
  allAttendanceReports: null,
  cardReports: null,
  allCardsReports: null,
  aloading: null,
  permissionError: false,
  cards: null,
  attendanceDetail: null,
  regions: null,
  visitorList: null,
  staffList: null,
  reportingDateFilter: null,
  filterLoading: null,
};

const supervisorReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case GET_CONTRACTOR_LIST_REQUEST:
        if (action.filter) {
          draftState.filterLoading = true;
        } else {
          draftState.loading = true;
        }
        draftState.error = null;
        break;
      case GET_CONTRACTOR_LIST_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.filterLoading = false;
        draftState.contractorList = action.data;
        break;
      case GET_CONTRACTOR_LIST_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        draftState.filterLoading = false;
        break;
      case GET_VISITOR_LIST_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_VISITOR_LIST_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.visitorList = action.data;
        break;
      case GET_VISITOR_LIST_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_GENERIC_STAFF_LIST_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_GENERIC_STAFF_LIST_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.staffList = action.data;
        break;
      case GET_GENERIC_STAFF_LIST_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_CONTRACTOR_STATS_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_CONTRACTOR_STATS_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.contractorStats = action.data;
        break;
      case GET_CONTRACTOR_STATS_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_DASHBOARD_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_DASHBOARD_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.dashboard = action.data;
        break;
      case GET_DASHBOARD_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ROLES_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_ROLES_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.roles = action.data;
        draftState.currentRole = null;
        break;
      case GET_ROLES_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_PERMISSIONS_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_PERMISSIONS_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.permissions = action.data;
        break;
      case GET_PERMISSIONS_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CREATE_ROLE:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_ROLE_SUCCESS:
        draftState.loading = false;
        break;
      case CREATE_ROLE_ERROR:
        draftState.loading = false;
        break;
      case UPDATE_ROLE:
        draftState.loading = true;
        draftState.error = null;
        break;
      case UPDATE_ROLE_SUCCESS:
        draftState.loading = false;
        break;
      case UPDATE_ROLE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_ROLE:
        draftState.loading = false;
        draftState.error = null;
        break;
      case DELETE_ROLE_SUCCESS:
        draftState.loading = false;
        break;
      case DELETE_ROLE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_USERS_REQUEST:
        if (action.filter) {
          draftState.filterLoading = true;
        } else {
          draftState.loading = true;
        }
        draftState.error = null;
        break;
      case GET_USERS_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.filterLoading = false;
        draftState.users = action.data;
        draftState.currentUser = null;
        break;
      case GET_USERS_REQUEST_ERROR:
        draftState.loading = false;
        draftState.filterLoading = false;

        break;
      case UPDATE_USER:
        draftState.loading = true;
        draftState.error = null;
        break;
      case UPDATE_USER_SUCCESS:
        draftState.loading = false;
        break;
      case UPDATE_USER_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CREATE_USER:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_USER_SUCCESS:
        draftState.loading = false;
        break;
      case CREATE_USER_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_USER:
        draftState.loading = false;
        draftState.error = null;
        break;
      case DELETE_USER_SUCCESS:
        draftState.loading = false;
        break;
      case DELETE_USER_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case IMPORT_STAFF:
        draftState.loading = true;
        draftState.error = null;
        break;
      case IMPORT_STAFF_SUCCESS:
        draftState.loading = false;
        break;
      case IMPORT_STAFF_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ROLE_BY_ID_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_ROLE_BY_ID_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.currentRole = action.data;
        break;
      case GET_ROLE_BY_ID_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_USER_BY_ID_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_USER_BY_ID_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.currentUser = action.data;
        break;
      case GET_USER_BY_ID_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_COMPANIES:
        if (action.filter) {
          draftState.filterLoading = true;
        } else {
          draftState.loading = true;
        }
        draftState.error = null;
        break;
      case GET_COMPANIES_SUCCESS:
        draftState.filterLoading = false;
        draftState.loading = false;
        draftState.companies = action.data;
        break;
      case GET_COMPANIES_ERROR:
        draftState.filterLoading = false;
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CREATE_COMPANY:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_COMPANY_SUCCESS:
        draftState.loading = false;
        break;
      case CREATE_COMPANY_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case MERGE_COMPANY:
        draftState.loading = true;
        draftState.error = null;
        break;
      case MERGE_COMPANY_SUCCESS:
        draftState.loading = false;
        break;
      case MERGE_COMPANY_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_COMPANY:
        draftState.loading = true;
        draftState.error = null;
        break;
      case DELETE_COMPANY_SUCCESS:
        draftState.companies = {
          ...state.companies,
          results: state.companies.results.filter(
            (item) => item.id !== action.data.data.id
          ),
        };
        draftState.loading = false;
        break;
      case DELETE_COMPANY_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case UPDATE_COMPANY:
        draftState.loading = true;
        draftState.error = null;
        break;
      case UPDATE_COMPANY_SUCCESS:
        draftState.loading = false;
        break;
      case UPDATE_COMPANY_ERROR:
        draftState.error = action.data;
        break;
      case GET_SITE_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_SITE_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.sites = action.data;
        break;
      case GET_SITE_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_SITE_STATS_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.siteStats = null;
        break;
      case GET_SITE_STATS_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.siteStats = action.data;
        break;
      case GET_SITE_STATS_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CREATE_SITE:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_SITE_SUCCESS:
        draftState.loading = false;
        break;
      case CREATE_SITE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case UPDATE_SITE:
        draftState.loading = true;
        draftState.error = null;
        break;
      case UPDATE_SITE_SUCCESS:
        draftState.loading = false;
        break;
      case UPDATE_SITE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_SITE:
        draftState.loading = true;
        draftState.error = null;
        break;
      case DELETE_SITE_SUCCESS:
        draftState.loading = false;
        break;
      case DELETE_SITE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_SITE_BY_ID_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_SITE_BY_ID_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.currentSite = action.data;
        break;
      case GET_SITE_BY_ID_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_CONTRACTORS_BY_SITE:
        draftState.loading = true;
        draftState.error = null;
        draftState.contractors = null;
        break;
      case GET_CONTRACTORS_BY_SITE_SUCCESS:
        draftState.loading = false;
        draftState.contractors = action.data;
        break;
      case GET_CONTRACTORS_BY_SITE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ALL_CONTRACTORS_BY_SITE:
        draftState.loading = true;
        draftState.error = null;
        draftState.allContractors = null;
        break;
      case GET_ALL_CONTRACTORS_BY_SITE_SUCCESS:
        draftState.loading = false;
        draftState.allContractors = action.data;
        break;
      case GET_ALL_CONTRACTORS_BY_SITE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case UPDATE_CONTRACTOR_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case UPDATE_CONTRACTOR_REQUEST_SUCCESS:
        draftState.loading = false;
        break;
      case UPDATE_CONTRACTOR_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_CONTRACTOR_REQUEST:
        draftState.loading = false;
        draftState.error = null;
        break;
      case DELETE_CONTRACTOR_REQUEST_SUCCESS:
        draftState.loading = false;
        break;
      case DELETE_CONTRACTOR_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_REPORTS:
        draftState.loading = true;
        draftState.error = null;
        draftState.reports = null;
        break;
      case GET_REPORTS_SUCCESS:
        draftState.loading = false;
        draftState.reports = action.data;
        break;
      case GET_REPORTS_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ALL_REPORTS:
        draftState.aloading = true;
        draftState.error = null;
        break;
      case GET_ALL_REPORTS_SUCCESS:
        draftState.aloading = false;
        draftState.allReports = action.data;
        break;
      case GET_ALL_REPORTS_ERROR:
        draftState.aloading = false;
        draftState.error = action.data;
        break;
      case GET_CARD_REPORTS:
        draftState.loading = true;
        draftState.error = null;
        draftState.cardReports = null;
        break;
      case GET_CARD_REPORTS_SUCCESS:
        draftState.loading = false;
        draftState.cardReports = action.data;
        break;
      case GET_CARD_REPORTS_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ALL_CARD_REPORTS:
        draftState.aloading = true;
        draftState.error = null;
        break;
      case GET_ALL_CARD_REPORTS_SUCCESS:
        draftState.aloading = false;
        draftState.allCardsReports = action.data;
        break;
      case GET_ALL_CARD_REPORTS_ERROR:
        draftState.aloading = false;
        draftState.error = action.data;
        break;
      case GET_ATTENDANCE_REPORTS:
        draftState.loading = true;
        draftState.error = null;
        draftState.attendanceReports = null;
        break;
      case GET_ATTENDANCE_REPORTS_SUCCESS:
        draftState.loading = false;
        draftState.attendanceReports = action.data;
        break;
      case GET_ATTENDANCE_REPORTS_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ALL_ATTENDANCE_REPORTS:
        draftState.aloading = true;
        draftState.error = null;
        break;
      case GET_ALL_ATTENDANCE_REPORTS_SUCCESS:
        draftState.aloading = false;
        draftState.allAttendanceReports = action.data;
        break;
      case GET_ALL_ATTENDANCE_REPORTS_ERROR:
        draftState.aloading = false;
        draftState.error = action.data;
        break;
      case GET_WEEKLY_ACTIVITY:
        draftState.weeklyLoading = true;
        draftState.error = null;
        draftState.weeklyActivity = null;
        break;
      case GET_WEEKLY_ACTIVITY_SUCCESS:
        draftState.weeklyLoading = false;
        draftState.weeklyActivity = action.data;
        break;
      case GET_WEEKLY_ACTIVITY_ERROR:
        draftState.weeklyLoading = false;
        draftState.error = action.data;
        break;
      case SET:
        draftState.sidebar = action.data;
        break;
      case SET_PERMISSION_ERROR:
        draftState.permissionError = action.data;
        break;
      case GET_CONTRACTOR_CARDS:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_CONTRACTOR_CARDS_SUCCESS:
        draftState.loading = false;
        draftState.cards = action.data;
        break;
      case GET_CONTRACTOR_CARDS_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_ATTENDANCE_DETAIL:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_ATTENDANCE_DETAIL_SUCCESS:
        draftState.loading = false;
        draftState.attendanceDetail = action.data;
        break;
      case GET_ATTENDANCE_DETAIL_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CREATE_CONTRACTOR_CARD:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_CONTRACTOR_CARD_SUCCESS:
        draftState.loading = false;
        // draftState.cards = action.data;
        break;
      case CREATE_CONTRACTOR_CARD_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case UPDATE_CONTRACTOR_CARD:
        draftState.loading = true;
        draftState.error = null;
        break;
      case UPDATE_CONTRACTOR_CARD_SUCCESS:
        draftState.loading = false;
        // draftState.cards = action.data;
        break;
      case UPDATE_CONTRACTOR_CARD_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_REGION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_REGION_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.regions = action.data;
        break;
      case GET_REGION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case CREATE_REGION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case CREATE_REGION_REQUEST_SUCCESS:
        draftState.loading = false;
        break;
      case CREATE_REGION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case EDIT_REGION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case EDIT_REGION_REQUEST_SUCCESS:
        draftState.loading = false;
        break;
      case EDIT_REGION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_REGION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case DELETE_REGION_REQUEST_SUCCESS:
        draftState.loading = false;
        break;
      case DELETE_REGION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SET_DATE_FILTER:
        draftState.reportingDateFilter = action;
        break;

      case "AUTH/REMOVE_TOKEN":
        draftState.error = null;
        draftState.loading = false;
        draftState.contractorList = null;
        draftState.dashboard = null;
        draftState.contractorStats = null;
        draftState.sidebar = "responsive";
        draftState.roles = null;
        draftState.currentRole = null;
        draftState.permissions = null;
        draftState.weeklyActivity = null;
        draftState.weeklyLoading = null;
        draftState.users = null;
        draftState.currentUser = null;
        draftState.companies = null;
        draftState.cards = null;
        draftState.sites = null;
        draftState.currentSite = null;
        draftState.siteStats = null;
        draftState.contractors = null;
        draftState.reports = null;
        draftState.allReports = null;
        draftState.aloading = null;
        draftState.permissionError = false;
        draftState.attendanceDetail = null;
        break;
      default:
        break;
    }
  });
};

export default supervisorReducer;
