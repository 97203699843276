const CloseIcon = process.env.PUBLIC_URL + "/checkin/close.svg";
const RenewalIcon = process.env.PUBLIC_URL + "/checkin/renewal.svg";
const TickIcon = process.env.PUBLIC_URL + "/checkin/tick.svg";
const WarningIcon = process.env.PUBLIC_URL + "/checkin/warning.svg";

const RenewalIconSet = {
  icon: RenewalIcon,
  iconWidth: "110px",
  iconHeight: "94px",
};

const WarningIconSet = {
  icon: WarningIcon,
  iconWidth: "100px",
  iconHeight: "89px",
};

const CloseIconSet = {
  icon: CloseIcon,
  iconWidth: "73px",
  iconHeight: "73px",
};

const TickIconSet = {
  icon: TickIcon,
  iconWidth: "88px",
  iconHeight: "66px",
};

const colors = {
  green: "#6FCF97",
  gradient: "linear-gradient(180deg, #6FCF97 0%, #F2C75E 100%)",
  yellow: "#F2C75E",
  red: "#E26C82",
  blue: "#7185BE",
};

export const types = [
  {
    id: 1,
    title: "No site found with given code",
    titleId: "checkin.message.1",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 2,
    title: "User Not Found, Pending Induction",
    titleId: "checkin.message.2",
    key: "pending_induction",
    icon: RenewalIconSet,
    color: colors.blue,
  },
  {
    id: 3,
    htmlDescription:
      "You have been identified as a <b>Disqualified Contractor</b> and are not permitted to enter our site(s).",
    htmlDescriptionId: "checkin.htmlDescription.3",
    description: "Please contact your Host to discuss requirements for entry.",
    descriptionId: "checkin.description.3",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 4,
    title: "Pending Induction",
    titleId: "checkin.message.4",
    description:
      "To enter our site, you are required to complete an online Induction.",
    descriptionId: "checkin.description.4",
    key: "pending_induction",
    icon: RenewalIconSet,
    color: colors.blue,
    footer: {
      text: "Start induction",
      textID: "checkin.pendingInduction.footer.text",
    },
    pushPath: "/post-login",
  },
  {
    id: 5,
    title: "Incomplete Induction",
    titleId: "checkin.message.5",
    description:
      "Thank you for getting started, but to enter our site, you need to complete your online Induction.",
    descriptionId: "checkin.description.5",
    key: "incomplete_induction",
    icon: WarningIconSet,
    color: colors.yellow,
    footer: {
      text: "Continue induction",
      textID: "induction.button.continue",
    },
    pushPath: "/post-login",
  },
  {
    id: 6,
    title:
      "User does not have a company record. Some of the user data is missing or corrupted.",
    titleId: "checkin.message.6",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 7,
    title: "Renewal Required",
    titleId: "checkin.message.7",
    key: "renewal_required",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 8,
    title: "On-site induction required",
    titleId: "checkin.message.8",
    key: "site_specific_induction_required",
    description:
      "To enter our site, you are required to complete an on-site Induction.",
    descriptionId: "checkin.description.8",
    subDescription:
      "Please advise your host that you have yet to complete one of our site specific inductions.",
    subDescriptionId: "checkin.subDescription.8",
    icon: TickIconSet,
    color: colors.gradient,
  },
  {
    id: 9,
    title: "Renewal Required for Site Specific Induction",
    titleId: "checkin.message.9",
    key: "site_specific_induction_renewal_required",
    icon: RenewalIconSet,
    color: colors.gradient,
  },
  {
    id: 10,
    title: "User has already checked in",
    titleId: "checkin.message.10",
    key: "already_checked_in",
    icon: TickIconSet,
    color: colors.green,
  },
  {
    id: 11,
    title: "Checked-in",
    titleId: "checkin.message.11",
    key: "renewal_required_soon",
    description: "When leaving our sites, please remember to Check Out.",
    descriptionId: "checkin.description.11",
    htmlDescription: "<b>Renew my Induction now</b>",
    htmlDescriptionId: "checkin.htmlDescription.3",
    dateText: "We’ve noticed your Induction status will expire in {day} days.",
    dateTextId: "checkin.dateText.11",
    timeCount: true,
    icon: RenewalIconSet,
    color: colors.gradient,
  },
  {
    id: 12,
    title: "User checked in",
    titleId: "checkin.message.12",
    description: "When leaving our site, please remember to Check Out.",
    descriptionId: "checkin.description.12",
    key: "checked_in",
    timeCount: true,
    icon: TickIconSet,
    color: colors.green,
  },
  {
    id: 13,
    title: "No site manager found with given code",
    titleId: "checkin.message.13",
    key: "site_specific",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 14,
    title: "Site manager has incorrect permissions",
    titleId: "checkin.message.14",
    key: "site_specific",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 15,
    title: "Site manager does not have permission for the site",
    titleId: "checkin.message.15",
    key: "site_specific",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 16,
    title: "Site specific induction is completed",
    titleId: "checkin.message.16",
    description:
      "You have {day} days left before you must renew your Induction.",
    descriptionId: "checkin.description.16",
    key: "site_specific",
    icon: TickIconSet,
    color: colors.green,
    footer: {
      text: "Check-in",
      textId: "checkin.button.site.specific",
    },
  },
  {
    id: 18,
    title: "There is no check in found, no checkout is needed",
    titleId: "checkin.message.18",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 19,
    title: "Check out",
    titleId: "checkin.message.19",
    description: "You just checked out of our {site} site.",
    descriptionId: "checkin.description.19",
    key: "checked_in",
    icon: TickIconSet,
    color: colors.green,
    popUp: true,
    bottomDescription: "Please take care returning home!",
    bottomDescriptionId: "checkin.description.20",
  },
];
