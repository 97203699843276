import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//styles
import classes from "./site.module.scss";

//actions
import {
  getSitesRequest,
  deleteSite,
  getSiteStatsRequest,
} from "store/supervisor/actions";

//selectors
import {
  makeSelectLoading,
  makeSelectSites,
  makeSelectSiteStats,
} from "store/supervisor/selector";

//components
import Counter from "components/Supervisor/counter";
import Button from "components/Supervisor/button";
import Modal from "components/Supervisor/modal";
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";

//assets
import PlusIcon from "assets/supervisorIcons/plusIcon";

//utils
import { isNil } from "lodash";

const informationList = [
  { title: "Your Sites", count: 0, key: "sites_count" },
  { title: "Contractors", count: 0, key: "contractors_count" },
  { title: "Valid induction", count: 0, key: "valid_count" },
  { title: "Pending induction", count: 0, key: "pending_count" },
  { title: "Renewal required", count: 0, key: "renewal_count" },
  { title: "Excluded", count: 0, key: "disqualified" },
];

const Site = ({
  loading,
  history,
  getSites,
  sites,
  deleteSiteRequest,
  getSiteStats,
  siteStats,
}) => {
  const [siteList, setSiteList] = useState([]);
  const [siteStatsList, setSiteStatsList] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [searchState, setSearchState] = useState("");

  const [modal, setModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  useEffect(() => {
    getSites();
    getSiteStats();
  }, []);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getSites();
      setSelectedPage(1);
    }
  };

  const sitesTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            id: a.id,
            raw: item,
            value: a.name,
          },
          {
            value: a.code,
          },
          {
            value: a.address1,
          },
          {
            value: a.address2,
          },
        ],
      };
    });

    const labels = [
      { label: "Name" },
      { label: "Code" },
      { label: "Address 1" },
      { label: "Address 2" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) => {
        history.push(`/supervisor-sites-detail?id=${val.id}`);
      },
    },
    {
      name: "Delete",
      action: (val) => {
        setModal({
          open: true,
          type: "delete",
          action: () => {
            deleteSiteRequest({ id: val.id, callback: () => getSites() });
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        });
      },
    },
  ];

  useEffect(() => {
    if (!isNil(sites)) {
      setSiteList(sitesTableData(sites.results));
      const newRows = sites.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
    }
  }, [sites]);

  useEffect(() => {
    if (!isNil(siteStats)) {
      setSiteStatsList(siteStats);
    }
  }, [siteStats]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getSites({ search: event.target.value });
    }
  };

  return (
    <>
      <div className={classes.CounterContainer}>
        {siteStatsList &&
          informationList.map((item) => (
            <Counter
              title={item.title}
              count={
                siteStatsList[
                  Object.keys(siteStatsList).find((a) => a === item.key)
                ]
              }
            />
          ))}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => history.push("/supervisor-sites-create")}
          icon={() => {
            return <PlusIcon />;
          }}
          text={"New Site"}
        />
      </div>
      <div className={classes.tableWrapper}>
        <Table
          handleKeyDown={handleKeyDown}
          isSearchable={true}
          searchState={searchState}
          loading={loading}
          onSearch={(e) => onSearch(e)}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          data={siteList}
          rowActions={rowActions}
          clickable={true}
        />
      </div>
      <Pagination
        totalCount={sites?.count}
        itemsPerPage={sites?.items_per_page}
        currentPage={selectedPage}
        onChange={(val) => {
          getSites({ page: val });
          setSelectedPage(val);
        }}
      />

      <Modal
        open={modal.open}
        cancel={modal.cancel}
        action={modal.action}
        type={modal.type}
        loading={loading}
        deleteTitle={"You are about to delete site"}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  sites: makeSelectSites(),
  siteStats: makeSelectSiteStats(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSites: (val) => dispatch(getSitesRequest(val)),
    getSiteStats: (val) => dispatch(getSiteStatsRequest(val)),
    deleteSiteRequest: (val) => dispatch(deleteSite(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Site);
