import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//action start
import {
  supervisorLoginRequest,
  resetError,
  roleClaimRequest,
} from "store/auth/actions";
//action end

import classes from "./auth.module.scss";

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "store/auth/selector";
//selectors end

import MicrosoftSignOn from "assets/microsoft/ms-symbollockup_signin_dark.svg";
import MicrosoftText from "./Microsoft.svg";
import MicrosofLogo from "./MicrosoftText.svg";
import { credentials } from "utils/helper/crypto";
import queryString from "query-string";
import axios from "axios";
import instance from "services/api";
import ReactLoading from "react-loading";
import { isNil } from "lodash";
import BackArrow from "./back.svg";

const Login = ({ setSupervisor, history, roleClaim }) => {
  const query = queryString.parse(window.location.search);

  const tenant_id = process.env.REACT_APP_TENANT_ID;
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    (async () => {
      setLoading(true);
      window.localStorage.setItem("redirectRoute", query.redirectRoute);

      if (isNil(query?.code) && isNil(query.error) && isNil(query.login)) {
        const microsoftUrl = (tenant_id, credentials) => {
          return `https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/authorize?${new URLSearchParams(
            credentials()
          )}`;
        };

        window.location.href = microsoftUrl(tenant_id, credentials);
      }
      await delay(2000);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!isNil(query?.error) && !isNil(query?.error_description)) {
      setErrorMessage(query?.error_description.toString());
    }
  }, [query?.error]);

  useEffect(() => {
    if (query?.code) {
      var bodyFormData = new FormData();
      bodyFormData.append("client_id", process.env.REACT_APP_CLIENT_ID);
      bodyFormData.append("redirect_uri", process.env.REACT_APP_REDIRECT_URI);
      bodyFormData.append("response_mode", "query");
      bodyFormData.append("state", "1234");
      bodyFormData.append("scope", "openid");
      bodyFormData.append("code", query.code);
      bodyFormData.append("grant_type", "authorization_code");

      bodyFormData.append(
        "code_verifier",
        window.localStorage.getItem("CODEVERIFIER")
      );

      const config = {
        method: "post",
        url: `https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/token`,
        headers: {
          "Content-Type": "application/x-www-url-form-urlencoded",
          Origin: process.env.REACT_APP_REDIRECT_URI,
        },
        data: bodyFormData,
      };
      setLoading(true);
      axios(config)
        .then(function (response) {
          //handle success
          instance.defaults.headers.common[
            "X-Access-Token"
          ] = `${response.data.access_token}`;

          roleClaim({
            idtoken: response.data.id_token,
            access_token: response.data.access_token,
            callBack: () => {
              window.localStorage.setItem(
                "access_token",
                response.data.access_token
              );

              window.localStorage.setItem(
                "refresh_token",
                response.data.refresh_token
              );
              var now = new Date();
              let expiryDate = new Date(
                now.getTime() + response.data.expires_in * 1000
              );

              window.localStorage.setItem("expiry_date", expiryDate.getTime());

              setSupervisor();

              setLoading(false);
            },
          });
        })
        .catch(function (response) {
          //handle error

          if (!isNil(response?.data?.message)) {
            setErrorMessage(response?.data?.message);
          }
          console.log(response);
          setLoading(false);
        });
    }
  }, [query?.code]);

  const MainLogo = process.env.PUBLIC_URL + "/logo/frl_logo.png";

  return (
    <>
      <div className={classes.container}>
        <div className={classes.box}>
          <img className={classes.logo} src={MainLogo} />

          <>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "350px",
                }}
              >
                <ReactLoading
                  type={"spin"}
                  color={"#20a8d8"}
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <div className={classes.wrapper}>
                {!isNil(query?.userType) && !isNil(query?.site) && (
                  <div
                    className={classes.backArrowWrapper}
                    onClick={() => {
                      history.push(`/persona-selection?site=${query.site}`);
                    }}
                  >
                    <img className={classes.backArrow} src={BackArrow} />
                  </div>
                )}
                <div className={classes.brandWrapper}>
                  <img src={MicrosofLogo} className={classes.brandLogo} />
                  <img src={MicrosoftText} className={classes.brandText} />
                </div>
                {!isNil(errorMessage) ? (
                  <div className={classes.errorWrapper}>
                    <p className={classes.errorMessage}>{errorMessage}</p>
                  </div>
                ) : (
                  <p className={classes.label}>Sign in</p>
                )}
                {/* Hide Button except logout redirect */}
                {!isNil(query.login) && (
                  <img
                    alt={"microsoft-sign-on"}
                    className={classes.signButton}
                    src={MicrosoftSignOn}
                    onClick={() => {
                      setLoading(true);
                      const microsoftUrl = (tenant_id, credentials) => {
                        return `https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/authorize?${new URLSearchParams(
                          credentials()
                        )}`;
                      };
                      window.location.href = microsoftUrl(
                        tenant_id,
                        credentials
                      );
                    }}
                  />
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSupervisor: (val) => dispatch(supervisorLoginRequest(val)),
    resetErrorReq: (val) => dispatch(resetError(val)),
    roleClaim: (val) => dispatch(roleClaimRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
