import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Card from "components/Supervisor/card";
import Input from "components/Supervisor/input";
import Switch from "components/Supervisor/switch";
import Modal from "components/Supervisor/modal";

import { isEmpty, isNil } from "lodash";
import classes from "./form.module.scss";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const SiteForm = ({
  id,
  updateAction,
  create,
  history,
  loading,
  editData,
  deleteSiteRequest,
  siteManagers,
  regions,
}) => {
  const [siteManagerOptions, setSiteManagerOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);

  useEffect(() => {
    if (!isNil(siteManagers)) {
      const options = siteManagers.results.map((item) => {
        return {
          value: item.id,
          label: `${item.first_name} ${item.last_name} - ${item.email}`,
        };
      });
      setSiteManagerOptions(options);
    }
  }, [siteManagers]);

  useEffect(() => {
    if (!isNil(regions)) {
      const options = regions.results.map((item) => {
        return {
          value: item.id,
          label: `${item.code} - ${item.name}`,
        };
      });
      setRegionOptions(options);
    }
  }, [regions]);

  const [modal, setModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });
  const initialValues = {
    name: id ? editData?.name : "",
    address1: id ? editData?.address1 : "",
    address2: id ? editData?.address2 : "",
    branch:
      id && editData && !isEmpty(regionOptions)
        ? regionOptions?.find(
            (item) => item?.value?.toString() === editData?.region?.toString()
          )
        : "",
    siteManager: id
      ? editData?.sitestaff?.map((item) => {
          return {
            label: `${item?.user?.first_name} ${item?.user?.last_name} `,
            value: item?.user?.id,
          };
        })
      : [],
    enabled: true,
    induction_id: 1,
  };

  return (
    <div className={classes.formWrapper}>
      <img
        alt={"back icon"}
        onClick={() => history.push("/supervisor-sites")}
        src={BackIcon}
        style={{
          width: "50px",
          height: "50px",
          cursor: "pointer",
          margin: "10px 10px 10px 0px",
        }}
      />
      <Formik
        enableReinitialize={true}
        initialValues={isNil(id) ? initialValues : initialValues}
        onSubmit={(values) => {
          if (!isNil(id)) {
            updateAction({
              id: id,
              values: values,
            });
          }
          if (isNil(id)) {
            create({
              values: values,
            });
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <Card title={"Site Details"}>
              <Field
                name={"name"}
                value={values.name}
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                }}
                label={"Site name"}
                placeholder={"Site name"}
                component={Input}
              />

              <Field
                onChange={(val, type) => {
                  setFieldValue("branch", val);
                }}
                value={values.branch}
                options={regionOptions}
                type={"select"}
                label={"Branch"}
                placeholder={"Branch"}
                isMulti={false}
                component={Input}
              />
            </Card>
            <Card title={"Address Details"}>
              <Field
                name={"address1"}
                value={values.address1}
                onChange={(e) => {
                  setFieldValue("address1", e.target.value);
                }}
                label={"Address 1"}
                placeholder={"Address 1"}
                component={Input}
              />
              <Field
                name={"address2"}
                value={values.address2}
                onChange={(e) => {
                  setFieldValue("address2", e.target.value);
                }}
                label={"Address 2"}
                placeholder={"Address 2"}
                component={Input}
              />
            </Card>
            {!isNil(id) && (
              <Card title={"Site manager"}>
                <Field
                  onChange={(val, type) => {
                    setFieldValue("siteManager", val);
                  }}
                  value={values?.siteManager?.map((item) => {
                    return {
                      label: item.label.split("-")[0],
                      value: item.value,
                    };
                  })}
                  options={siteManagerOptions}
                  type={"select"}
                  label={"Site manager"}
                  placeholder={"Site manager"}
                  isMulti
                  component={Input}
                />
              </Card>
            )}
            <Card
              title={"Site-specific induction"}
              style={{ marginBottom: "200px" }}
            >
              <Field
                value={true}
                onChange={(val) => {}}
                checked={true}
                label={"Enabled"}
                component={Switch}
              />
              <Field
                tabSelectedValue={{
                  label: "Site Specific Induction",
                  value: 1,
                }}
                value={{ label: "Site Specific Induction", value: 1 }}
                type={"select"}
                onChange={(val) => {}}
                label={"Induction"}
                placeholder={"Select Induction"}
                options={[{ label: "Site Specific Induction", value: 1 }]}
                component={Input}
              />
            </Card>
            <div className={classes.buttonContainer}>
              <div
                style={{
                  width: "75%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ justifyContent: "flex-start" }}
                  className={classes.flex}
                >
                  {!isNil(id) && (
                    <div
                      id={"delete"}
                      type={"delete"}
                      style={{ paddingTop: "8.5px", cursor: "pointer" }}
                      className={classes.lightButton}
                      onClick={() => {
                        setModal({
                          open: true,
                          type: "delete",
                          action: () => {
                            deleteSiteRequest({
                              id: id,
                              callback: history.push("/supervisor-sites"),
                            });
                            setModal({
                              open: false,
                              type: null,
                              action: null,
                              cancel: null,
                            });
                          },
                          cancel: () =>
                            setModal({
                              open: false,
                              type: null,
                              action: null,
                              cancel: null,
                            }),
                        });
                      }}
                    >
                      Delete
                    </div>
                  )}
                </div>
                <div
                  style={{ justifyContent: "flex-end" }}
                  className={classes.flex}
                >
                  <button
                    onClick={() => history.push("/supervisor-sites")}
                    className={classes.lightButton}
                  >
                    Cancel
                  </button>
                  <button
                    id={"save"}
                    type="submit"
                    disabled={loading}
                    className={classes.darkButton}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={modal.open}
        cancel={modal.cancel}
        action={modal.action}
        type={modal.type}
        loading={loading}
        deleteTitle={"You are about to delete site"}
      />
    </div>
  );
};

export default SiteForm;
