import React from "react";
//styling
import classes from "../reporting.module.scss";
//utils
import { isNil, isEmpty } from "lodash";
import { secondToHours, msToTime } from "utils/helper";

export const tableIndicator = [
  {
    color: "#03CE91",
    name: "On-site",
    type: "on_site",
  },
  {
    color: "#999999",
    name: "Off-site",
    type: "off_site",
  },
];

export const personaDefault = [
  { value: 1, label: "Contractor", checked: false },
  { value: 3, label: "Staff", checked: false },
  { value: 2, label: "Visitor", checked: false },
  { value: 4, label: "Admin User", checked: false },
];

const labels = [
  { label: "Branch", sortable: true, key: "branch" },
  { label: "Site", sortable: true, key: "site" },
  { label: "Persona", sortable: true, key: "persona" },
  { label: "Name", sortable: true, key: "name" },
  { label: "Company", sortable: true, key: "company" },
  { label: "Status", sortable: true, key: "status" },
  { label: "Total hours", sortable: true, key: "total_hours" },
];

export const reportingTableData = (item, branches) => {
  const data = item?.map((a) => {
    return {
      id: a.user.id,
      email: a.email,
      firstName: !isNil(a.user) ? a.user.first_name : "-",
      lastName: !isNil(a.user) ? a.user.last_name : "-",
      values: [
        {
          value: !isNil(
            branches?.results?.find((item) => item.id === a.site.region)?.code
          )
            ? branches?.results?.find((item) => item.id === a.site.region)?.code
            : "-",
        },
        {
          value: !isNil(a?.site?.name) ? a.site.name : "-",
        },
        {
          value: !isNil(a.persona) ? a.persona : "-",
        },
        {
          value: !isNil(a.user)
            ? `${a.user.first_name} ${a.user.last_name}`
            : "-",
        },
        {
          value:
            !isNil(a.company_data) &&
            !isNil(a.company_data.company) &&
            !isEmpty(a.company_data.company)
              ? a?.company_data?.company
              : "-",
        },
        {
          value:
            a.type !== 0
              ? "Off-site"
              : msToTime(Math.abs(new Date(a?.checkin) - new Date())),
          component: (
            <div className={classes.statusWrapper}>
              {a.type !== 0 ? (
                <div
                  className={classes.status}
                  style={{
                    background: "gray",
                  }}
                />
              ) : (
                <span className={classes.checkinStatus}>
                  {msToTime(Math.abs(new Date(a?.checkin) - new Date()))}
                </span>
              )}
            </div>
          ),
        },
        {
          value: !isNil(a.total_seconds) ? secondToHours(a.total_seconds) : "-",
        },
      ],
    };
  });

  return {
    columns: data,
    labels: labels,
  };
};
