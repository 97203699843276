import React from "react";
import { Route, withRouter } from "react-router-dom";
import { Suspense } from "react";
import Loading from "components/UI/loading";
import { connect } from "react-redux";
const NonAuthmiddleware = ({ component: Component, path, layout: Layout }) => (
  <Route
    path={path}
    render={(props) => {
      return (
        <Suspense fallback={Loading}>
          <Layout menu={false}>
            <Component {...props} />
          </Layout>
        </Suspense>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    hasCards: state.auth.hasCards,
  };
};

export default withRouter(connect(mapStateToProps)(NonAuthmiddleware));
