import React, { useEffect, useState } from "react";
import classes from "./siteInduction.module.scss";
import queryString from "query-string";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//action start
import { siteInductionRequest } from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectSiteInduction,
  makeSelectUser,
} from "store/auth/selector";
//selectors end
import { isNil } from "lodash";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import Loading from "components/Supervisor/loading";
import { types } from "../CheckIn/types";

const SiteInduction = ({
  siteInductionReq,
  loading,
  user,
  error,
  siteInduction,
  history,
}) => {
  const [params, setParams] = useState(null);

  const [variable, setVariable] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    siteInductionReq({
      site: parsed?.site,
      siteManager: parsed?.sitemanager,
      token: user?.token,
    });
    setParams(parsed);
  }, []);

  useEffect(() => {
    if (!isNil(siteInduction)) {
      const selectedVariable = types.find(
        (item) => item.id === siteInduction?.messageId
      );
      setVariable(selectedVariable);
      setData(siteInduction);
    }
  }, [siteInduction]);

  useEffect(() => {
    if (!isNil(error)) {
      const selectedVariable = types.find(
        (item) => item.id === error?.messageId
      );

      setVariable(selectedVariable);
      setData(error);
    }
  }, [error]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      {!isNil(variable) && !isNil(data) && (
        <div
          className={classes.container}
          style={{ background: variable?.color }}
        >
          <img
            alt={"icon"}
            className={classes.icon}
            style={{
              width: variable?.icon?.iconWidth,
              height: variable.icon?.iconHeight,
            }}
            src={variable?.icon?.icon}
          />
          <span className={classes.name}>
            {user && user?.first_name} {user && user?.last_name}
          </span>
          {variable?.title && (
            <span className={classes.title}>
              <FormattedMessage
                defaultMessage={variable?.title}
                id={variable.titleId}
              />
            </span>
          )}
          {variable?.dateText && (
            <span className={classes.description}>
              <FormattedMessage
                defaultMessage={variable?.dateText}
                id={variable.dateTextId}
                values={{ day: data.remaining_days }}
              />
            </span>
          )}
          {variable?.htmlDescription && (
            <span className={classes.htmlDescription}>
              <FormattedHTMLMessage
                defaultMessage={variable?.htmlDescription}
                id={variable.htmlDescription}
              />
            </span>
          )}

          {!isNil(variable?.description) && (
            <span className={classes.description}>
              {variable?.title && (
                <span className={classes.description}>
                  <FormattedMessage
                    defaultMessage={variable.description}
                    id={variable.descriptionId}
                    values={{ day: data?.remaining_days }}
                  />
                </span>
              )}
            </span>
          )}
          {!isNil(variable?.subDescription) && (
            <span className={classes.subDescription}>
              <FormattedMessage
                defaultMessage={variable.subDescription}
                id={variable.subDescriptionId}
              />
            </span>
          )}
          <div className={classes.infoContainer}>
            <div className={classes.infoWrapper}>
              <span className={classes.infoTitle}>
                <FormattedMessage
                  defaultMessage={"Company"}
                  id={"checkin.company"}
                />
              </span>
              <span className={classes.text}>{data?.site?.company?.name}</span>
            </div>

            <div className={classes.infoWrapper}>
              <span className={classes.infoTitle}>
                <FormattedMessage defaultMessage={"Site"} id={"checkin.site"} />
              </span>
              <span className={classes.text}>{data?.site?.name}</span>
            </div>
          </div>
          {variable?.footer && (
            <>
              <div
                className={classes.footer}
                onClick={() => {
                  history.push(`/check-in?site=${params.site}`);
                }}
              >
                <span>
                  <FormattedMessage
                    defaultMessage={variable?.footer?.text}
                    id={variable.footer.textId}
                  />
                </span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
  user: makeSelectUser(),
  siteInduction: makeSelectSiteInduction(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    siteInductionReq: (val) => dispatch(siteInductionRequest(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteInduction)
);
