import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ClickOutside } from "utils/helper";

import classes from "./filterSelector.module.scss";
import { isEmpty, isNil } from "lodash";
import ArrowIcon from "./arrow.svg";
import Checkbox from "../checkbox";
import SearchIcon from "./search.svg";
import CloseIcon from "./close.svg";
import ReactLoading from "react-loading";

const FilterSelector = ({
  title,
  options,
  onChange,
  disabled,
  searchable,
  onSearch,
  loading,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  useEffect(() => {
    if (!isEmpty(options) && !isNil(options)) {
      setActiveOption(options[0]);
    }
  }, []);

  useEffect(() => {
    if (!isNil(activeOption)) {
      onChange(activeOption.value);
    }
  }, [activeOption]);

  const checkedOptions = options?.filter((item) => item.checked);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div className={cn(classes.selectbox)}>
        <button
          className={cn(classes.selectbox_head)}
          onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
        >
          <span className={classes.title} style={{ color: disabled && "grey" }}>
            {checkedOptions?.length > 0 ? (
              <>
                {title}
                {checkedOptions?.length > 0 && (
                  <div className={classes.counterLabel}>
                    {checkedOptions?.length}
                  </div>
                )}
              </>
            ) : (
              title
            )}
          </span>
          <img
            src={ArrowIcon}
            className={cn(classes.icon, isDropdownOpen && classes.rotate)}
            alt={"arrow icon"}
          />
        </button>
        <div className={cn(classes.dropdown, isDropdownOpen && classes.open)}>
          {searchable && (
            <>
              <div className={classes.filterInput}>
                <img
                  className={classes.searchIcon}
                  alt={"Search Icon"}
                  src={SearchIcon}
                />
                <input
                  onChange={(e) => onSearch(e.target.value)}
                  className={classes.input}
                  placeholder={"Search"}
                />
              </div>
              <div className={classes.optionBoxWrapper}>
                {!isEmpty(checkedOptions) ? (
                  checkedOptions?.map((item) => (
                    <div className={classes.optionBox} alt={item.label}>
                      <span>
                        {item.label.length > 10
                          ? `${item.label.slice(0, 7)}...`
                          : item.label}
                      </span>
                      <img
                        onClick={() => setActiveOption(item)}
                        src={CloseIcon}
                        alt={"Close Icon"}
                      />
                    </div>
                  ))
                ) : (
                  <div className={cn(classes.gray, classes.optionBox)}>
                    <span>No Filter Applied</span>
                  </div>
                )}
              </div>
            </>
          )}
          {loading ? (
            <div className={classes.loadingWrapper}>
              <ReactLoading
                type={"spin"}
                color={"#20a8d8"}
                height={30}
                width={30}
              />
            </div>
          ) : (
            <ul>
              {options &&
                !!options.length &&
                options.map((option, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setActiveOption(option);
                      }}
                    >
                      <Checkbox checked={option.checked ? true : false} />
                      <a>{option.label}</a>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </ClickOutside>
  );
};

export default FilterSelector;
