import classes from "./welcome.module.scss";
import React, { useEffect } from "react";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import { Link } from "react-router-dom";
import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectProgress } from "store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import VideoPlayer from "components/UI/videoPlayer";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import { FormattedMessage } from "react-intl";
const Welcome = (props) => {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") != null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }

    props.setSection({
      section: 1,
      callBack: () =>
        props.getSectionTimeReq({ induction_id: "2", section: 1 }),
    });
    props.getProgress();
  }, []);

  useEffect(() => {
    if (props.progressData && props.progressData.section === 0) {
      props.setProgress({ section: 1, progress: 0, induction_id: 2 });
    }
  }, [props.progressData]);

  return (
    <div className={classes.Container}>
      <div className={classes.TextContainer}>
        <span className={classes.Heading}>
          <FormattedMessage id="welcome.title" />
        </span>
        <span className={classes.Description}>
          <FormattedHTMLMessage id={"welcome.description"} />
        </span>
      </div>
      <div style={{ width: "100%", marginBottom: "80px" }}>
        <VideoPlayer
          formatID={"video.url"}
          defaultUrl={"https://vimeo.com/483475050/a1edf94934"}
        />
      </div>
      <Footer>
        <Link
          className={classes.Link}
          to={isIE ? "/induction" : "/take-a-look"}
        >
          <Button
            formatID="welcome.button"
            id="apply-button"
            text="Get Started"
            type="submit"
          />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
