import React from "react";

const AvatarSvg = (props) => (
  <svg
    width="211px"
    height="211px"
    viewBox="0 0 211 211"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Rule-5-tick Copy 2</title>
    <defs>
      <path
        d="M0,61.3236364 C0,27.4555272 27.4555272,0 61.3236364,0 C95.1917455,0 122.647273,27.4555272 122.647273,61.3236364 C122.647273,95.1917455 95.1917455,122.647273 61.3236364,122.647273 C27.4555272,122.647273 0,95.1917455 0,61.3236364 Z"
        id="path-1"
      ></path>
      <filter
        x="-60.3%"
        y="-44.0%"
        width="220.6%"
        height="220.6%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="3.95636364"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="20"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="20"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0.043672883   0 0 0 0 0.781702899   0 0 0 0 0.389059963  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMorphology
          radius="3.95636364"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter2"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="4"
          in="shadowSpreadOuter2"
          result="shadowOffsetOuter2"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="12"
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter2"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter2"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
          type="matrix"
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g
      id="Contractor-(mobile-large)"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Rule-5-tick-Copy-2" transform="translate(44.000000, 24.000000)">
        <g id="accept-path">
          <use
            fill="black"
            fill-opacity="1"
            filter="url(#filter-2)"
            href="#path-1"
          ></use>
          <use
            stroke="#FFFFFF"
            stroke-width="7.91272727"
            fill={props.color}
            fill-rule="evenodd"
            href="#path-1"
          ></use>
        </g>
        <polyline
          id="Path-2"
          stroke="#FFFFFF"
          stroke-width="9.89090909"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="40.5527273 69.2363636 55.6653409 84.3489773 82.1660227 37.5854545"
        ></polyline>
      </g>
    </g>
  </svg>
);

export default AvatarSvg;
