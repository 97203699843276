import React from "react";
import Logo from "assets/logo/splashLogo.png";
import classes from "./splash.module.scss";
import SoundIcon from "assets/icons/sound.svg";
import LifeSavingRules from "../Induction/mockData";

const s1 = process.env.PUBLIC_URL + "/splash/1.png";
const s2 = process.env.PUBLIC_URL + "/splash/2.png";
const s3 = process.env.PUBLIC_URL + "/splash/3.png";
const s4 = process.env.PUBLIC_URL + "/splash/4.png";
const s5 = process.env.PUBLIC_URL + "/splash/5.png";
const s6 = process.env.PUBLIC_URL + "/splash/6.png";

const images = [s1, s2, s3, s4, s5, s6];

const SplashScreen = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.LogoWrapper}>
        <img alt={"Splash Logo"} className={classes.Logo} src={Logo} />
      </div>
      <div className={classes.SoundContainerLarge}>
        <img
          alt={"Sound Logo"}
          className={classes.SoundLogoLarge}
          src={SoundIcon}
        />
        <span className={classes.SoundTextLarge}>
          You’ll need sound for this training.
        </span>
      </div>
      <div className={classes.TextWrapper}>
        <span className={classes.Text}>
          Your safety is important to us!
          <br />
          Welcome to our online Health & Safety Induction
        </span>
      </div>

      <div
        className={classes.iconWrapper}
        style={{ flexDirection: "row", margin: "0 auto" }}
      >
        {LifeSavingRules.map((item, index) => (
          <img
            alt={"index"}
            style={{
              borderRadius: "100px",
              border: `10px solid ${item.color} `,
              width: "calc(5vh + 4vw)",
              height: "calc(5vh + 4vw)",
              margin: "15px",
            }}
            src={images[index]}
          />
        ))}
      </div>

      <div className={classes.BottomContainer}>
        <div className={classes.Skew} />
        <div className={classes.Skew1}></div>
        <div className={classes.SoundContainer}>
          <img
            alt={"Sound Logo 2"}
            className={classes.SoundLogo}
            src={SoundIcon}
          />
          <span className={classes.SoundText}>
            You’ll need sound <br />
            for this training.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
