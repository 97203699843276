import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeSelectUser } from "store/auth/selector";
import { makeSelectAssesmentResult } from "store/assesment/selector";

import { createStructuredSelector } from "reselect";

import { withRouter } from "react-router-dom";
import classes from "./completion.module.scss";
import Tick from "./tickIcon";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
const Complete = ({ user, section, getProgress, result }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getProgress();
  }, []);
  return (
    <div
      className={classes.CompletionContainer}
      style={{
        backgroundColor: section.color,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <span className={classes.CompletionTitle}>
          <FormattedMessage
            defaultMessage={section.title}
            id={section.titleID}
          />
        </span>
        <Tick color={section.color} />
        <>
          <span className={classes.CompletionText}>
            {result && result?.passed === 1 ? (
              <FormattedMessage
                values={{ name: user?.first_name }}
                id={"induction.assesment.completion.text.success"}
              />
            ) : (
              result &&
              result?.passed === 0 && (
                <FormattedMessage
                  id={"induction.assesment.completion.text.fail"}
                />
              )
            )}
          </span>
          {section.id === 8 ? (
            <span className={classes.CompletionSubText}>
              <FormattedMessage
                id={"induction.assesment.completion.description.1"}
              />
            </span>
          ) : (
            <span className={classes.CompletionSubText}>
              <FormattedHTMLMessage
                id={"induction.assesment.completion.description.1"}
              />
            </span>
          )}
        </>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  result: makeSelectAssesmentResult(),
});

export default connect(mapStateToProps)(withRouter(Complete));
