import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//action start
import { getVisitorListRequest } from "store/supervisor/actions";
//action end

//selectors start
import {
  makeSelectVisitorList,
  makeSelectError,
  makeSelectLoading,
} from "store/supervisor/selector";
import { makeSelectSupervisor } from "store/auth/selector";

//styles
import classes from "../people.module.scss";

//components
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";

//utils
import { statusIndicator } from "utils/enums";
import { formatDate } from "utils/helper";
import { isNil, isEmpty } from "lodash";

const VisitorList = ({ visitors, getVisitorList, history, loading }) => {
  const [contractorList, setContractorList] = useState([]);

  const [rawList, setRawList] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [PAGE_COUNT, setPageCount] = useState(null);

  const [searchState, setSearchState] = useState("");

  const [sortStatus, setSortStatus] = useState({ type: "", value: "asc" });

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getVisitorList({
        page: 1,
        searchItem: "",
        sort: "",
      });
      setSelectedPage(1);
    }
  };

  useEffect(() => {
    getVisitorList({
      page: 1,
      searchItem: "",
      sort: "",
    });
  }, []);

  const contractorTableData = (item) => {
    const data = item?.map((a) => {
      return {
        id: a.id,
        email: a.email,
        values: [
          {
            id: a.id,
            raw: item,
            value: `${a.first_name} ${a.last_name} `,
          },
          {
            value: a?.company_data?.company,
          },
          {
            value: a?.site?.branch,
          },
          {
            value: a?.site?.site,
          },

          {
            value: !isNil(a?.site_status?.status) ? a.site_status.status : "-",
            component: (
              <div className={classes.statusWrapper}>
                <div
                  className={classes.status}
                  style={{
                    background:
                      !isEmpty(a?.site_status?.status) &&
                      !isNil(a.site_status.status)
                        ? statusIndicator.find(
                            (item) => item.type === a.site_status.status
                          )?.color
                        : "gray",
                  }}
                />
              </div>
            ),
          },
          {
            value:
              !isNil(a?.site_status?.completed) &&
              !isEmpty(a?.site_status.completed)
                ? formatDate(a?.site_status?.completed)
                : "-",
          },

          {
            value:
              !isNil(a?.site_status?.renewal) &&
              !isEmpty(a?.site_status.renewal)
                ? formatDate(a?.site_status?.renewal)
                : "-",
          },
        ],
      };
    });

    const labels = [
      { label: "Name", sortable: true, sortType: "name" },
      { label: "Company" },
      { label: "Branch", sortable: true, sortType: "email" },
      { label: "Site", sortable: true, sortType: "company" },
      {
        label: "On-site induction",
        sortable: true,
        sortType: "progress",
      },
      { label: "Completed" },
      { label: "Renewal" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    if (!isNil(visitors)) {
      setContractorList(contractorTableData(visitors.results));
      const newRows = visitors?.results?.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      setRawList(visitors?.results);
      setPageCount(visitors?.number_of_pages);
    }
  }, [visitors]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getVisitorList({
        page: 1,
        searchItem: event.target.value,
        sort: "",
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.indicatorWrapper}>
          {statusIndicator.map((item) => (
            <div className={classes.indicatorItem}>
              <div style={{ background: item.color }} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
        <div className={classes.tableWrapper}>
          <Table
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={contractorList}
            apiSort
          />
        </div>
        <Pagination
          totalCount={visitors?.number_of_items}
          pageCount={visitors?.number_of_pages}
          onChange={(val) => {
            getVisitorList({
              page: val,
              searchItem: searchState,
              sort: "",
              sort_type: "",
            });
            setSelectedPage(val);
          }}
          currentPage={selectedPage}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  visitors: makeSelectVisitorList(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  supervisor: makeSelectSupervisor(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getVisitorList: (val) => dispatch(getVisitorListRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorList);
