import styled, { keyframes } from "styled-components";
export const NEXT = "NEXT";
export const PREV = "PREV";

export const Item = styled.div`
  text-align: center;
  padding: 100px;
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
`;

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${(props) => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${(props) => {
    return "translateX(calc(-41%))";
  }};
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 100px;
  height: 100%;
  overflow: hidden;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 60%;
  cursor: pointer;
  height: 350px;
  visibility: ${(props) =>
    props.rightEnd && props.order === 0
      ? "hidden"
      : props.leftEnd && props.order === 2
      ? "hidden"
      : ""};
  @media screen and (max-width: 380px) {
    height: 325px;
  }
  background-color: white;
  z-index: ${(props) => (!props.sliding && props.order === 1 ? "91" : "90")};
  box-shadow: 0 2px 14px 0px rgb(115, 129, 143);
  order: ${(props) => props.order};
  animation: ${(props) =>
      props.order === 0 && props.dir === NEXT
        ? slideNext
        : props.order === 1 && props.dir === NEXT
        ? slideNext1
        : props.order === 2 && props.dir === NEXT
        ? slideNext2
        : props.order === props.length && props.dir === NEXT
        ? slideNext2
        : props.order === 0 && props.dir === PREV
        ? slidePrev
        : props.order === 1 && props.dir === PREV
        ? slidePrev1
        : props.order === 2 && props.dir === PREV
        ? slidePrev2
        : props.order === 3 && props.dir === PREV
        ? slidePrev3
        : fadeNext}
    0.5s linear;
  transform: ${(props) =>
    props.order === 0
      ? "rotate(-10deg) translateX(100px)"
      : props.order === 2
      ? "rotate(10deg) translateY(65px) translateX(-50px)"
      : ""};
`;

export const AppContainer = styled.div`
  font-family: sans-serif;
  text-align: center;
  width: 75%;
  height: 1000px;
`;

export const Code = styled.code`
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  margin: 0;
  padding: 0.2em 0.4em;
`;

const slideNext2 = keyframes`
  from {
     transform:rotate(0deg) translateX(300px)  translateY(100px);
     opacity:0.5;
  }

  to {
    transform:rotate(10deg) translateY(65px) translateX(0px);
    opacity:0.5;

  }
`;

const slideNext1 = keyframes`
  from {
    z-index:998;
    opacity: 0.5;
    transform:rotate(10deg) translateX(200px) translateY(65px);

  }

  to {
    opacity: 1;
    z-index:1200;
    transform:rotate(0) translateX(0)  translateY(0);
  }
`;

const slideNext = keyframes`
  0% {
    opacity:1;
    transform:rotate(0deg) translateX(200px);
  }

  50% {
    opacity:1;
  }
  100% {
    opacity:1;
    transform:rotate(-10deg) translateX(0px);
  }
`;
const slidePrev3 = keyframes`
  from {
    opacity:1;
    transform:rotate(10deg) translateX(calc(-41% - 200px)) translateY(85px);
  }
  to {
    transform:rotate(10deg) translateX(0) translateY(200px);
  }
`;

const slidePrev2 = keyframes`
from {
    opacity: 1;
    z-index:999;
    z-index:1000;
    transform:rotate(0deg) translateX(-250px) translateY(0);
  }
  to {
    opacity: 1;
    z-index:9;
    transform:rotate(10deg) translateY(65px) translateX(-50px);
  }
`;

const slidePrev1 = keyframes`
from {
    opacity: 0.5;
    z-index:9;
    transform:rotate(-10deg) translateX(-200px);
  }
  to {
    opacity: 1;
    z-index:1000;
    transform:rotate(0) translateX(0);
  } 
`;

const slidePrev = keyframes`

from {
    opacity:0.5;
    transform:rotate(-10deg) translateX(-100px);
  }
  to {
    opacity:1;
    transform:rotate(-10deg) translateX(100px);
  }

  
`;

const fadeNext = keyframes`
  from {
    opacity: 0;
    scale:0.5;
  }

  to {
    opacity: 1;
    scale:1;
  }
`;
