import React from "react";
import classes from "./PermissionModal.module.scss";

const MainLogo =
  "https://immersivefrl.s3-ap-southeast-2.amazonaws.com/fl_logo_small.png";

const PermissionModal = () => {
  return (
    <div className={classes.PermissionModal}>
      <div className={classes.box}>
        <img className={classes.logo} src={MainLogo} />
        <p className={classes.title}>
          It seems you do not have sufficient permissions to access the Fletcher
          Living EHS dashboard.
        </p>
        <p className={classes.description}>
          If you feel this is in error, please contact your branch EHS Manager.
        </p>
      </div>
    </div>
  );
};

export default PermissionModal;
