export const sections = {
  0: "Induction",
  1: "Welcome",
  2: "Take a look around",
  3: "Drugs & Alcohol",
  4: "Exclusion zones",
  5: "Speed limits",
  6: "Scaffold",
  7: "Falls",
  8: "Dust",
  9: "Card System",
  10: "Site Rules",
  11: "Induction Complete",
};

export const assesment = {
  3: "Drugs & Alcohol",
  4: "Exclusion zones",
  5: "Speed limits",
  8: "Falls",
  6: "Scaffold",
  7: "Dust",
};

export const statusIndicator = [
  // {
  //   color: "#DDDDDD",
  //   name: "Not completed",
  // },
  {
    color: "#BB6BD9",
    name: "Pending",
    type: "pending_induction",
  },
  {
    color: "#03CE91",
    name: "Successful",
    type: "permitted",
  },
  {
    color: "#F2C75E",
    name: "Renewal required",
    type: "renewal_required",
  },

  {
    color: "#E26C82",
    name: "Excluded",
    type: "not_permitted",
  },
];

export const contractorIndicator = [
  {
    color: "#BB6BD9",
    name: "Pending",
    type: "pending_induction",
  },
  {
    color: "#03CE91",
    name: "Successful",
    type: "permitted",
  },
  {
    color: "#F2C75E",
    name: "Renewal required",
    type: "renewal_required",
  },

  {
    color: "#E26C82",
    name: "Excluded",
    type: "not_permitted",
  },
];
