import classes from "./induction.module.scss";
import React, { useState, useEffect } from "react";
import Card from "components/UI/card";
import Rules from "./mockData";
import Section from "./Section";
import { connect } from "react-redux";

//action start
import {
  getAssesmentRequest,
  setAssesmentRequest,
  createAssesmentRequest,
  resetAssesmentResult,
} from "store/assesment/actions";
import { setProgressRequest, getProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { resetSelectedStep } from "store/steps/actions";
//action end

//selectors start

import { createStructuredSelector } from "reselect";
import {
  makeSelectAssesmentResult,
  makeSelectAssesmentTryId,
  makeSelectAssesment,
  makeSelectLoading,
  makeSelectError,
} from "store/assesment/selector";
import { makeSelectProgress } from "store/progress/selector";
import { makeSelectStep } from "store/steps/selector";
import { FormattedMessage } from "react-intl";

//selectors end

const Induction = ({
  assesmentTryId,
  assesmentData,
  loading,
  error,
  getAssesment,
  setAssesmentQuestion,
  createNewAssesment,
  getProgress,
  setProgressReq,
  progressData,
  resetCurrentResult,
  selectedStepId,
  result,
  setCurrentSectionReq,
  getSectionTimeReq,
}) => {
  const [section, setSection] = useState(false);
  const [step, setStep] = useState();
  const [progress, setProgress] = useState(16.666);

  useEffect(() => {
    if (window.document.getElementById("launcher-frame") !== null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
    setSelectedSection(selectedStepId);
  }, [selectedStepId]);

  const setSelectedSection = (val) => {
    const nextItem = Rules.find((item) => {
      return item?.id === selectedStepId?.id;
    });
    setSection(nextItem);
    setStep(1);
    setProgress(16.666);
    resetSelectedStep();
    getAssesment({ id: nextItem && nextItem?.aid });
  };

  useEffect(() => {
    setSection(false);
    if (
      progressData &&
      progressData?.section &&
      section &&
      progressData?.section <= section?.id
    ) {
    } else {
      getProgress();
    }
  }, []);

  return (
    <React.Fragment>
      {section ? (
        <Section
          rules={Rules}
          section={section}
          setSection={setSection}
          step={step}
          setStep={setStep}
          progress={progress}
          setProgress={setProgress}
          assesmentData={assesmentData}
          assesmentTryId={assesmentTryId}
          loading={loading}
          error={error}
          assesmentResult={result}
          createNewAssesment={createNewAssesment}
          getAssesment={getAssesment}
          setAssesmentQuestion={setAssesmentQuestion}
          setProgressReq={setProgressReq}
          getProgress={getProgress}
          progressData={progressData}
          resetCurrentResult={resetCurrentResult}
          result={result}
          setCurrentSectionReq={setCurrentSectionReq}
          getSectionTimeReq={getSectionTimeReq}
        />
      ) : (
        <div className={classes.InductionContainer}>
          <span className={classes.Heading}>
            <FormattedMessage
              defaultMessage={"Life Saving Rules"}
              id={"lifesavingrules.title"}
            />
          </span>
          <div className={classes.InductionWrapper}>
            {Rules &&
              Rules?.map((item) => (
                <Card
                  onClick={() => {
                    if (
                      item.questions &&
                      progressData &&
                      progressData?.section >= item.id
                    ) {
                      setSection(item);
                      setStep(1);
                      setProgress(16.666);
                      setCurrentSectionReq({
                        section: item.id,
                        callBack: () =>
                          getSectionTimeReq({
                            induction_id: "2",
                            section: item.id,
                          }),
                      });
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    } else if (item.id === 3) {
                      setSection(item);
                      setStep(1);
                      setProgress(16.666);
                      setCurrentSectionReq({
                        section: item.id,
                        callBack: () =>
                          getSectionTimeReq({
                            induction_id: "2",
                            section: item.id,
                          }),
                      });
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                  }}
                  id={item.id}
                  icon={item.icon}
                  image={item.image}
                  key={item.id}
                  title={item.title}
                  progress={
                    progressData && progressData?.section > item.id
                      ? "100%"
                      : progressData?.section === item.id
                      ? `${progressData.progress}%`
                      : "0%"
                  }
                  color={item.color}
                  completed={
                    progressData && progressData?.section > item.id
                      ? true
                      : progressData.section === item.id &&
                        progressData.progress === 100
                      ? true
                      : false
                  }
                />
              ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  assesmentTryId: makeSelectAssesmentTryId(),
  assesmentData: makeSelectAssesment(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  progressData: makeSelectProgress(),
  selectedStepId: makeSelectStep(),
  result: makeSelectAssesmentResult(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    setAssesmentQuestion: (val) => dispatch(setAssesmentRequest(val)),
    createNewAssesment: (val) => dispatch(createAssesmentRequest(val)),
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgressReq: (val) => dispatch(setProgressRequest(val)),
    resetCurrentResult: (val) => dispatch(resetAssesmentResult(val)),
    setCurrentSectionReq: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Induction);
