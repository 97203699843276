import React from "react";
import classes from "./sectionStats.module.scss";
import { intervalToDuration } from "date-fns";
import { isNil, isNaN } from "lodash";
const Icon1 = process.env.PUBLIC_URL + "/splash/1.png";
const Icon2 = process.env.PUBLIC_URL + "/splash/2.png";
const Icon3 = process.env.PUBLIC_URL + "/splash/3.png";
const Icon4 = process.env.PUBLIC_URL + "/splash/4.png";
const Icon5 = process.env.PUBLIC_URL + "/splash/5.png";
const Icon6 = process.env.PUBLIC_URL + "/splash/6.png";

function minutesToHMS(d) {
  const duration = Number(d);
  if (!isNil(duration) && !isNaN(duration)) {
    const seconds = duration / 0.016667;
    const calculatedTime = intervalToDuration({
      start: 0,
      end: seconds * 1000,
    });

    const hour =
      calculatedTime?.hours < 10
        ? `0${calculatedTime.hours}`
        : calculatedTime?.hours;
    const minute =
      calculatedTime.minutes < 10
        ? `0${calculatedTime.minutes}`
        : calculatedTime?.minutes;
    const second =
      calculatedTime.seconds < 10
        ? `0${calculatedTime?.seconds}`
        : calculatedTime?.seconds;

    return `${hour}:${minute}:${second}`;
  }
}

const SectionStats = ({ dashboardData }) => {
  const sectionData = [
    {
      icon: Icon1,
      title: "We always work free of alcohol and drugs.",
      passRate: `${dashboardData?.assesment_stats?.drugs_and_alcohol.toFixed(
        0
      )}%`,
      avgTime: `${minutesToHMS(
        dashboardData?.assesment_stats?.drugs_and_alcohol_average_time
      )}`,
      color: "#158FCE",
    },
    {
      icon: Icon2,
      title: "We always set and obey exclusion zones.",
      passRate: `${dashboardData?.assesment_stats?.exclusion_zones.toFixed(
        0
      )}%`,
      avgTime: `${minutesToHMS(
        dashboardData?.assesment_stats?.exclusion_zones_average_time
      )}`,
      color: "#F86C6B",
    },
    {
      icon: Icon3,
      title: "We always obey the site speed limit.",
      passRate: `${dashboardData?.assesment_stats?.speed_limit?.toFixed(0)}%`,
      avgTime: `${minutesToHMS(
        dashboardData?.assesment_stats?.speed_limit_average_time
      )}`,
      color: "#63C2DE",
    },
    {
      icon: Icon4,
      title: "We never alter fall protection.",
      passRate: `${dashboardData?.assesment_stats?.fall?.toFixed(0)}%`,
      avgTime: `${minutesToHMS(
        dashboardData?.assesment_stats?.fall_average_time
      )}`,
      color: "#4DBD74",
    },
    {
      icon: Icon5,
      title: "We never alter scaffold protection.",
      passRate: `${dashboardData?.assesment_stats?.scaffold?.toFixed(0)}%`,
      avgTime: `${minutesToHMS(
        dashboardData?.assesment_stats?.scaffold_average_time
      )}`,
      color: "#FFC107",
    },
    {
      icon: Icon6,
      title: "We protect everyone from dust and harmful substances.",
      passRate: `${dashboardData?.assesment_stats?.dust?.toFixed(0)}%`,
      avgTime: `${minutesToHMS(
        dashboardData?.assesment_stats?.dust_average_time
      )}`,
      color: "#2F353A",
    },
  ];

  return (
    <>
      <div className={classes.dashboardSection1}>
        <div className={classes.progressColumn}>
          {sectionData.map((item) => (
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <img
                    alt={"Progress Icon"}
                    style={{ borderColor: item.color }}
                    className={classes.progressIcon}
                    src={item.icon}
                  />
                </div>
                <div className={classes.progressItemSection2}>
                  <p>{item.title}</p>
                  <span>
                    Avg time: <b>{item.avgTime}</b>
                  </span>
                </div>

                <div className={classes.progressItemSection3}>
                  <span className={classes.passRateText}>Pass Rate:</span>
                  <span className={classes.percentageText}>
                    {item.passRate}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SectionStats;
