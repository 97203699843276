// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_ERROR,
  UPDATE_SITE,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* updateSiteRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("id", values.id);
    params.append("name", values.values.name);
    params.append("address1", values.values.address1);
    params.append("address2", values.values.address2);
    params.append("region", values.values.branch.value);
    params.append("induction_id", values.values.induction_id);
    if (
      !isEmpty(values.values.siteManager) &&
      !isNil(values.values.siteManager)
    ) {
      params.append(
        "staff",
        values.values.siteManager.map((item) => item.value).toString()
      );
    }

    const response = yield call(axios.put, `/sites/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_SITE_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: UPDATE_SITE_SUCCESS,
        data: response.data,
      });
    }
    yield call(success, `Site Updated Succesfully`);
    yield put(push("/supervisor-sites"));
  } catch (error) {
    yield put({
      type: UPDATE_SITE_ERROR,
      data: error,
    });
  }
}

export default function* updateSiteRequestSaga() {
  yield takeLatest(UPDATE_SITE, updateSiteRequest);
}
