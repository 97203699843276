import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//styles
import classes from "./contractors.module.scss";

//actions
import {
  getContractorsBySite,
  getAllContractorsBySite,
} from "store/supervisor/actions";

//selectors
import {
  makeSelectLoading,
  makeSelectSiteContractors,
  makeSelectAllContractors,
} from "store/supervisor/selector";

//components
import Menu from "components/Supervisor/menu";
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";
import { CSVLink } from "react-csv";

//utils
import { pages, contractorList } from "../utils";
import { isEmpty, isNil } from "lodash";
import { msToTime } from "../../../../utils/helper";
import { format, parseISO } from "date-fns";
import queryString from "query-string";

const formatDateTime = (val) =>
  !isNil(val) ? format(parseISO(val), `MM/dd/yyyy`) : "-";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const Contractors = ({
  loading,
  history,
  getContractors,
  contractors,
  contractorReport,
  getContractorsReport,
}) => {
  const [contractorsList, setContractorList] = useState([]);

  const [rawList, setRawList] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [searchState, setSearchState] = useState("");
  const [csvData, setcsvData] = useState([]);
  const csvRef = useRef();

  const id = queryString.parse(window.location.search).id;

  useEffect(() => {
    if (id) {
      getContractors({ id: id, page: 1 });
      getContractorsReport({ id: id });
    }
  }, []);

  useEffect(() => {
    if (!isNil(contractorReport)) {
      const { labels, columns } = contractorTableData(contractorReport);
      const labelData = labels?.map((item) => item.label);
      const columnData = columns?.map((item) => item.values);
      const mergedData = [labelData, ...columnData];

      const handledCsvData = mergedData?.map((item, index) => {
        if (index === 0) {
          return item;
        } else return item.map((item) => item.value);
      });

      setcsvData(handledCsvData);
    }
  }, [contractorReport]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getContractors({ id: id, page: selectedPage });
      setSelectedPage(1);
    }
  };

  const getStatusColor = (type) => {
    switch (type) {
      case "not_permitted":
        return "#E26C82";
      case "permitted":
        return "#03CE91";
      case "pending_induction":
        return "#7185BE";
      case "renewal_required":
        return "#F2C75E";
      default:
    }
  };

  const contractorTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.user.id,
        email: a.user.email,
        values: [
          {
            id: a.id,
            raw: item,
            value: `${a.user.first_name} ${a.user.last_name}`,
          },
          {
            value: a.company_data.company,
          },
          {
            value: !isNil(a?.status?.status) ? a.status.status : "-",
            component: (
              <div className={classes.statusWrapper}>
                <div
                  className={classes.status}
                  style={{
                    backgroundColor: getStatusColor(a?.status?.status),
                  }}
                />
              </div>
            ),
          },
          {
            value:
              !isNil(a.status?.renewal) && !isEmpty(a.status?.renewal)
                ? formatDateTime(a.status.renewal)
                : "-",
          },
          {
            value: !isNil(a.onsite) ? a.onsite : "-",
            component: (
              <div className={classes.statusWrapper}>
                {isEmpty(a.onsite) ||
                !isNil(a?.onsite?.checkout) ||
                !isEmpty(a?.onsite?.checkout) ||
                isNil(a?.onsite?.checkin) ? (
                  <div className={classes.status} />
                ) : (
                  <span className={classes.checkinStatus}>
                    {msToTime(
                      Math.abs(new Date() - new Date(a?.onsite.checkin))
                    )}
                  </span>
                )}
              </div>
            ),
          },
        ],
      };
    });

    const labels = [
      { label: "Name" },
      { label: "Company" },
      { label: "Status" },
      { label: "Renewal" },
      { label: "On Site" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) => {
        history.push(
          `/supervisor-sites-contractors-detail?id=${val.id}&returnUrl=supervisor-sites-contractors?id=${id}`
        );
      },
    },
    {
      name: "Delete",
      action: () => {},
    },
  ];

  useEffect(() => {
    if (!isNil(contractors)) {
      setContractorList(contractorTableData(contractors.results));
      const newRows = contractorList.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      setRawList(contractors.results);
    }
  }, [contractors]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getContractors({ id: id, search: searchState });
    }
  };

  return (
    <>
      <Menu pages={pages} id={id} />
      <div className={classes.container}>
        <div className={classes.heading}>
          <img
            alt={"backIcon"}
            onClick={() => history.push("/supervisor-sites")}
            src={BackIcon}
            className={classes.backIcon}
          />
          <span className={classes.title}>Contractors</span>
          <button
            onClick={() => {
              csvRef.current.link.click();
            }}
            type="submit"
            className={classes.darkButton}
          >
            Export
          </button>
        </div>
        <div className={classes.tableWrapper}>
          <CSVLink
            filename={"contractors-report.csv"}
            className="hidden"
            data={csvData}
            ref={csvRef}
            target="_blank"
          />
          <Table
            clickable={true}
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={contractorsList}
            rowActions={rowActions}
          />

          <Pagination
            itemsPerPage={contractorsList?.items_per_page}
            totalCount={contractorsList?.count}
            currentPage={selectedPage}
            onChange={(val) => {
              getContractors({
                id: id,
                page: val,
              });
              setSelectedPage(val);
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  contractors: makeSelectSiteContractors(),
  contractorReport: makeSelectAllContractors(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getContractors: (val) => dispatch(getContractorsBySite(val)),
    getContractorsReport: (val) => dispatch(getAllContractorsBySite(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
