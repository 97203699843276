import React, { useState, useEffect } from "react";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Switch from "components/Supervisor/switch/custom";
import classes from "./autoLogout.module.scss";
import cn from "classnames";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const AutoLogout = ({}) => {
  const [isAutoLogoutEnabled, setAutoLogout] = useState(true);

  useEffect(() => {
    Cookies.set("autoLogout", true);
  }, []);

  return (
    <div className={classes.Container}>
      <div className={classes.TextContainer}>
        <span className={cn(classes.Heading)}>Auto Logout</span>
        <span className={classes.Description}>
          When auto logout is enabled, user is logged out 10 seconds after check
          in & check out processes. Only recommended to be enabled on devices
          dedicated to sites for public use.
        </span>
        <div className={classes.switchWrapper}>
          <Switch
            option2={"Enabled"}
            option1={"Disabled"}
            value={isAutoLogoutEnabled}
            onChange={(val) => {
              if (isAutoLogoutEnabled === true) {
                setAutoLogout(false);
                Cookies.remove("autoLogout");
              } else {
                setAutoLogout(true);
                Cookies.set("autoLogout", true);
              }
            }}
            checked={isAutoLogoutEnabled}
            label={"Enabled / Disabled"}
          />
        </div>
      </div>
      <Link to="/">
        <Footer>
          <Button id="apply-button" text="Continue" type="submit" />
        </Footer>
      </Link>
    </div>
  );
};

export default AutoLogout;
