import React, { useEffect, useState } from "react";

//redux utils//
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//actions
import {
  getContractorStats,
  updateContractorRequest,
  getCompaniesRequest,
  getContractorCardsRequest,
  createContractorCard,
  updateContractorCard,
  getSitesRequest,
  deleteContractorRequest,
} from "store/supervisor/actions";

import { getMeRequest } from "store/auth/actions";

//selectors
import {
  makeSelectLoading,
  makeSelectContractorStats,
  makeSelectCompanies,
  makeSelectContractorCards,
  makeSelectFilterLoading,
  makeSelectContractorList,
} from "store/supervisor/selector";

import { makeSelectMe } from "store/auth/selector";

//components
import Card from "components/Supervisor/card";
import Switch from "components/Supervisor/switch/custom";
import TextArea from "components/Supervisor/textArea";
import Input from "components/Supervisor/input";
import SearchableSelect from "components/Supervisor/searchableSelect";
import ReactLoading from "react-loading";
import Table from "components/Supervisor/table"; //halilk: for the cards table
import CardModal from "components/Supervisor/modal/card";
import PhoneInput from "components/UI/phoneInput";
import Modal from "components/Supervisor/modal";

//styles
import classes from "./detail.module.scss";

//assets
import UserIcon from "./assets/user.svg";
import PreviewIcon from "./assets/preview.svg";
import TickIcon from "./assets/tick.svg";
import CrossIcon from "./assets/cross.svg";
import cardType1 from "./assets/card_type_1.svg";
import cardType2 from "./assets/card_type_2.svg";
import ExcludedIcon from "./assets/excludedRed.svg";

//app data
import mockData from "pages/Induction/mockData";

//utils
import { format, parseISO } from "date-fns";
import { isNil, isEmpty } from "lodash";
import queryString from "query-string";

const ContractorDetails = ({
  getContractorStats,
  getContractorCards,
  contractorDetail,
  loading,
  updateContractor,
  history,
  getCompanies,
  companies,
  cards,
  createCard,
  updateCard,
  getSites,
  filterLoading,
  getMe,
  me,
  deleteContractor,
  contractorList,
}) => {
  const isSuperUser = !isNil(me) ? me?.is_superuser : false;

  const id = queryString.parse(window.location.search).id;

  const returnUrl = queryString.parse(window.location.search).returnUrl;

  const formatDate = (val) =>
    !isNil(val) ? format(parseISO(val), `dd/MM/yyyy`) : "-";

  const daysLeft = (val) => {
    const date = new Date();
    var difference = Math.round(new Date(val).getTime() - date.getTime());
    return Math.floor(difference / 1000 / 60 / 60 / 24);
  };

  useEffect(() => {
    getContractorStats({ id: id });
    getCompanies();
    getContractorCards({ id: id });
    getSites();
    getMe();
  }, []);

  const [contractor, setContractor] = useState(null);
  const [notes, setNotes] = useState();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [isActive, setIsActive] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [companyOptions, setCompanyOptions] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [cardList, setCardList] = useState([]);
  const [greenCard, setGreenCard] = useState([]);
  const [redCard, setRedCard] = useState([]);
  const [cardModal, setCardModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
    data: null,
  });
  const [modal, setModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  const cardTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a?.id,
        values: [
          {
            value: (
              <img
                alt={"card"}
                className={classes.cardIcon}
                src={a?.type === 1 ? cardType1 : cardType2}
              />
            ),
            id: a?.id,
            site_id: a?.site?.id,
            type: a?.type,
            raw: item,
          },
          {
            value: formatDate(a?.created),
          },
          {
            value: a?.site?.name,
          },
          {
            value: a?.issuer?.first_name + " " + a?.issuer?.last_name,
          },
          {
            value: a?.summary,
          },
          {
            value: a?.action,
          },
        ],
      };
    });

    const labels = [
      { label: "" },
      { label: "Date Issued" },
      { label: "Site Name" },
      { label: "By Whom" },
      { label: "Summary" },
      { label: "Action" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    if (!isNil(contractorDetail)) {
      setContractor(contractorDetail);
      setNotes(contractorDetail?.notes === null ? "" : contractorDetail?.notes);
      setIsActive(contractorDetail?.is_active);
      setPhoneNumber(contractorDetail?.phone);
      setEmail(contractorDetail?.email);
      setFirstName(contractorDetail?.first_name);
      setLastName(contractorDetail?.last_name);
    }
  }, [contractorDetail]);

  useEffect(() => {
    if (
      !isNil(companies) &&
      !isNil(contractorDetail) &&
      !isNil(contractorList)
    ) {
      const selectedUser = contractorList?.results.find(
        (item) => item.id === Number(id)
      );

      if (
        !isNil(
          companies?.results.find(
            (item) => selectedUser?.company_data?.companyrelation === item?.id
          )
        )
      ) {
        setCompanyOptions(
          companies?.results?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
        setSelectedCompany({
          name: contractorDetail?.company,
          id: companies?.results?.find(
            (item) => item.name === contractorDetail.company
          )?.id,
        });
      } else {
        let companyList = [];
        companyList = companies?.results?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        companyList.push({
          value: selectedUser?.company_data?.companyrelation,
          label: selectedUser?.company_data?.company,
        });
        setCompanyOptions(companyList);
        setSelectedCompany({
          name: selectedUser?.company_data?.company,
          id: selectedUser?.company_data?.companyrelation,
        });
      }
    }
  }, [contractorDetail, companies, contractorList]);

  useEffect(() => {
    if (!isNil(cards)) {
      setCardList(cardTableData(cards.results));

      var greenCardCount = 0;
      var redCardCount = 0;

      //count the numbers just like it's 90s :)
      //If there is a pagination, this will fail for sure but it's improbable

      for (var index = 0; index < cards.results.length; index++) {
        if (cards.results[index].type === 1) greenCardCount += 1;
        else redCardCount += 1;
      }

      setGreenCard(greenCardCount);
      setRedCard(redCardCount);
    }
  }, [cards]);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <ReactLoading
          type={"spin"}
          color={"#20a8d8"}
          height={300}
          width={300}
        />
      </div>
    );
  }

  const HSEChecks = [
    { title: "Safe Card", visible: true, complete: true },
    { title: "Company task analysis", visible: true, complete: true },
  ];

  const handleAddCard = () => {
    //alert('test');
    setCardModal({
      open: true,
      data: null,
      cancel: () =>
        setCardModal({
          open: false,
          type: null,
          save: null,
          cancel: null,
        }),
      save: (val) => {
        createCard({
          user_id: id,
          cardType: val.cardType,
          action: val.action,
          site_id: val.site_id,
          summary: val.summary,
          callBack: () => {
            getContractorCards({ id: id });
            setCardModal({
              open: false,
              type: null,
              save: null,
              cancel: null,
            });
          },
        });
      },
    });

    //TODO: Card modal
  };

  //halilk: editing card on cards table
  const rowActions = [
    {
      name: "Edit",
      action: (val) => {
        //TODO: Define the action here
        setCardModal({
          open: true,
          data: val,
          cancel: () =>
            setCardModal({
              open: false,
              type: null,
              save: null,
              cancel: null,
            }),
          save: (val) => {
            updateCard({
              user_id: id,
              id: val.id,
              cardType: val.cardType,
              action: val.action,
              site_id: val.site_id,
              summary: val.summary,
              callBack: () => {
                getContractorCards({ id: id });
                setCardModal({
                  open: false,
                  type: null,
                  save: null,
                  cancel: null,
                });
              },
            });
          },
        });
      },
    },
  ];

  return (
    <>
      {contractor && (
        <div className={classes.mainContainer}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Card error={!contractor?.is_active ? true : false}>
              <div style={{ display: "flex", width: "100%" }}>
                <img
                  alt={"user icon"}
                  style={{ width: "48px", height: "48px", margin: "15px" }}
                  src={UserIcon}
                />
                <div className={classes.nameWrapper}>
                  <span className={classes.text}>{contractor.first_name}</span>
                  <span className={classes.lastName}>
                    {contractor.last_name}
                  </span>
                </div>
              </div>
              <div>
                <span className={classes.text}>Company:</span>{" "}
                <span className={classes.desc}>{contractor.company}</span>
              </div>
              <div>
                <span className={classes.text}>Email:</span>{" "}
                <span className={classes.desc}>{contractor.email}</span>
              </div>
              <div style={{ marginTop: "16px" }}>
                <span className={classes.text}>Joined:</span>{" "}
                <span className={classes.desc}>
                  {formatDate(contractor.date_joined)}
                </span>
              </div>
              {!contractor?.is_active && (
                <div className={classes.excludedWarning}>
                  <img src={ExcludedIcon} alt={"excluded icon"} />
                  <span>Excluded</span>
                </div>
              )}
            </Card>
            <Card
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
              bodyStyle={{ justifyContent: "flex-start" }}
              title={"EHS Checks"}
            >
              {HSEChecks.map((item) => (
                <div
                  className={classes.hseChecks}
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "45px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", width: "70%" }}>
                    <span>{item.title}</span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {item.visible && (
                      <img
                        alt={"preview"}
                        style={{ width: "15px", height: "10px" }}
                        src={PreviewIcon}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "15%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt={"status"}
                      style={
                        item.complete
                          ? { width: "11px", height: "8px" }
                          : { width: "9px", height: "9px" }
                      }
                      src={item.complete ? TickIcon : CrossIcon}
                    />
                  </div>
                </div>
              ))}
            </Card>
            <Card
              title={"EHS Progress"}
              bodyStyle={{ justifyContent: "flex-start" }}
            >
              {contractor?.inductions.map((item) => (
                <div
                  className={classes.hseChecks}
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "45px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.name}</span>
                  </div>
                  <div style={{ width: "30%", display: "flex" }}>
                    <span>
                      {!isEmpty(item.complete_log) ? "Completed" : "Pending"}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20%",
                    }}
                  >
                    {!isEmpty(item.complete_log) && (
                      <img
                        alt={"tick"}
                        style={{ width: "9px", height: "9px" }}
                        src={TickIcon}
                      />
                    )}
                  </div>
                </div>
              ))}
            </Card>
          </div>
          <Card title={"Details"}>
            {isSuperUser && (
              <>
                <Input
                  label={"First Name"}
                  placeholder={"First Name"}
                  order={"column"}
                  style={{ flexDirection: "column" }}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                  name="firstname"
                  disabled={!isSuperUser}
                />
                <Input
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  order={"column"}
                  style={{ flexDirection: "column" }}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastName}
                  name="lastname"
                  disabled={!isSuperUser}
                />
                <Input
                  label={"Email"}
                  placeholder={"Email"}
                  order={"column"}
                  style={{ flexDirection: "column" }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  name="email"
                  disabled={!isSuperUser}
                />
              </>
            )}
            <PhoneInput
              placeholder={"login.phoneNumber.placeholder"}
              label={"login.phoneNumber"}
              defaultLabel={"Phone number"}
              defaultPlaceholder={"Phone number"}
              name="phoneNumber"
              value={phoneNumber}
              onChange={setPhoneNumber}
              noTranslate={true}
            />

            <SearchableSelect
              searchable={true}
              label={"Company"}
              placeholder={"Company"}
              onSearch={(val) =>
                getCompanies({ filter: true, page: 1, search: val ? val : "" })
              }
              onSelect={(val) => {
                setSelectedCompany({ name: val.label, id: val.value });
              }}
              options={companyOptions}
              value={companyOptions?.find(
                (item) => item.label === selectedCompany.name
              )}
              loading={filterLoading}
            />
          </Card>
          {contractor.inductions.map((item) => (
            <Card title={item.name}>
              <div className={classes.genericWrapper}>
                {!isEmpty(item.assesment_tries) &&
                  mockData.map((item) => (
                    <div className={classes.genericBox}>
                      {contractor.inductions[0]?.assesment_tries.filter(
                        (tries) =>
                          tries.assesment === item.aid && tries.passed === 1
                      )?.length > 0 ? (
                        <img
                          alt={"tick 2"}
                          style={{
                            width: "17px",
                            height: "13px",
                            display: "flex",
                          }}
                          src={TickIcon}
                        />
                      ) : null}
                      <span
                        className={classes.sectionName}
                        style={{ display: "flex", textAlign: "center" }}
                      >
                        {item.slug}
                      </span>
                      <span
                        className={classes.attempts}
                        style={{ display: "flex", textAlign: "center" }}
                      >
                        {
                          contractor.inductions[0]?.assesment_tries.filter(
                            (tries) => tries.assesment === item.aid
                          )?.length
                        }{" "}
                        {contractor.inductions[0]?.assesment_tries.filter(
                          (tries) => tries.assesment === item.aid
                        )?.length === 1
                          ? "Attempt"
                          : "Attempts"}
                      </span>
                    </div>
                  ))}
              </div>
              {!isEmpty(item.assesment_tries) && (
                <div className={classes.divider} />
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: "37px",
                  marginBottom: "37px",
                }}
              >
                <div>
                  <span className={classes.date}>Completed:</span>{" "}
                  <span className={classes.date}>
                    {isNil(item?.complete_log?.created)
                      ? "-"
                      : formatDate(item?.complete_log?.created)}
                  </span>
                </div>
                <div style={{ marginLeft: "49px" }}>
                  <span className={classes.date}>Renewal:</span>{" "}
                  {isNil(item?.complete_log?.renewal) ? (
                    <>
                      {" "}
                      <span className={classes.date}>-</span>{" "}
                    </>
                  ) : (
                    <>
                      <span className={classes.date}>
                        {daysLeft(item?.complete_log?.renewal) > 0 ? (
                          <>
                            {daysLeft(item?.complete_log?.renewal)} days left{" "}
                          </>
                        ) : (
                          <>
                            {daysLeft(item?.complete_log?.renewal) * -1} days
                            passed due{" "}
                          </>
                        )}
                      </span>
                      <span className={classes.date}>
                        {" "}
                        •{" "}
                        {item?.complete_log?.renewal &&
                          formatDate(item?.complete_log?.renewal)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Card>
          ))}

          <div className={classes.contractorCardWrapper}>
            <Card
              title={"Cards"}
              action={"Add card"}
              onClick={handleAddCard}
              style={{ padding: "0" }}
              bodyStyle={{ display: "block", padding: "0" }}
              //halilk: Pills that appear next to title
              pills={[
                { name: greenCard, style: { backgroundColor: "#6FCF97" } },
                { name: redCard, style: { backgroundColor: "#E26C82" } },
              ]}
            >
              <Table
                width={"100%"}
                isSearchable={false}
                loading={loading}
                data={cardList}
                rowActions={rowActions}
                clickable={false}
                style={{ border: "none", boxShadow: "none" }}
              />
            </Card>
          </div>
          <Card title={"Notes"}>
            <TextArea
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              value={notes}
            />
          </Card>
          <Card title={"Excluded from Site Access"}>
            <span className={classes.dangerZoneText}>
              Contractors marked as “Excluded from Site” are not permitted to
              enter Fletcher Living sites due to a failure to meet with the
              minimum site requirements. Contractors must talk to their Employer
              (in the first instance) to gain access.
            </span>
            <Switch
              option2={"Permitted to enter"}
              option1={"Not permitted to enter"}
              onChange={() => setIsActive(!isActive)}
              checked={isActive}
            />
          </Card>
          <div className={classes.buttonContainer}>
            <div
              style={{
                width: "75%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ justifyContent: "flex-start" }}
                className={classes.flex}
              >
                {" "}
                {!isNil(id) && isSuperUser && (
                  <div
                    id={"delete"}
                    type={"delete"}
                    style={{ paddingTop: "8.5px", cursor: "pointer" }}
                    className={classes.lightButton}
                    onClick={() => {
                      setModal({
                        open: true,
                        type: "delete",
                        action: () => {
                          deleteContractor({
                            id: id,
                            redirectUrl: `/${returnUrl}`,
                          });
                          setModal({
                            open: false,
                            type: null,
                            action: null,
                            cancel: null,
                          });
                        },
                        cancel: () =>
                          setModal({
                            open: false,
                            type: null,
                            action: null,
                            cancel: null,
                          }),
                      });
                    }}
                  >
                    Delete
                  </div>
                )}
              </div>
              <div
                style={{ justifyContent: "flex-end" }}
                className={classes.flex}
              >
                <button
                  onClick={() => {
                    history.push(`/${returnUrl}`);
                  }}
                  className={classes.lightButton}
                >
                  Cancel
                </button>

                <button
                  className={classes.darkButton}
                  onClick={() => {
                    if (isSuperUser) {
                      updateContractor({
                        id: contractor.id,
                        notes: notes,
                        isActive: isActive,
                        phoneNumber: phoneNumber,
                        companyId: selectedCompany.id,
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        callBack: () => getContractorStats({ id: id }),
                      });
                    } else {
                      updateContractor({
                        id: contractor.id,
                        notes: notes,
                        isActive: isActive,
                        phoneNumber: phoneNumber,
                        companyId: selectedCompany.id,
                        callBack: () => getContractorStats({ id: id }),
                      });
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <CardModal
            open={cardModal.open}
            cancel={cardModal.cancel}
            save={cardModal.save}
            type={cardModal.type}
            data={cardModal.data}
          />
          <Modal
            open={modal.open}
            cancel={modal.cancel}
            action={modal.action}
            type={modal.type}
            loading={loading}
            deleteTitle={"You are about to delete contractor"}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  contractorDetail: makeSelectContractorStats(),
  companies: makeSelectCompanies(),
  cards: makeSelectContractorCards(),
  filterLoading: makeSelectFilterLoading(),
  me: makeSelectMe(),
  contractorList: makeSelectContractorList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getContractorStats: (val) => dispatch(getContractorStats(val)),
    updateContractor: (val) => dispatch(updateContractorRequest(val)),
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getContractorCards: (val) => dispatch(getContractorCardsRequest(val)),
    createCard: (val) => dispatch(createContractorCard(val)),
    updateCard: (val) => dispatch(updateContractorCard(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
    getMe: (val) => dispatch(getMeRequest(val)),
    deleteContractor: (val) => dispatch(deleteContractorRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorDetails);
