import classes from "./lookAround.module.scss";
import React, { useEffect } from "react";
import Button from "components/UI/button";

import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectSelectedLanguage } from "store/language/selector";

import { makeSelectProgress } from "store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
const VRScene1Image = process.env.PUBLIC_URL + "/vr1-updated.png";
const VRScene2Image = process.env.PUBLIC_URL + "/vr2-updated.png";

const LookAround = ({
  history,
  progressData,
  getProgress,
  setProgress,
  selectedLanguage,
  setSection,
  getSectionTimeReq,
}) => {
  useEffect(() => {
    setSection({
      section: 2,
      callBack: () => getSectionTimeReq({ induction_id: "2", section: 2 }),
    });
    getProgress();
  }, []);

  useEffect(() => {
    if (progressData !== null || progressData !== undefined) {
      if (progressData?.section < 2) {
        setProgress({ section: 2, progress: 0, induction_id: 2 });
      }
    }
  }, [progressData]);

  return (
    <div className={classes.Container}>
      <div className={classes.TextContainer}>
        <span className={classes.Heading}>
          <FormattedMessage id={"takealookaround.title"} />
        </span>
        <span className={classes.Description}>
          <FormattedMessage id={"takealookaround.description"} />
        </span>
      </div>
      <div className={classes.VRContainer}>
        <img
          alt="VR Experience 1"
          className={classes.Image}
          src={VRScene1Image}
        />
        <div className={classes.ButtonContainer}>
          <Button
            formatID={"takealookaround.button1"}
            onClick={() => {
              window.location =
                "/frl_vr/vr_experience_1.html?language=" +
                selectedLanguage.code;
            }}
            text={"VR Scene 1"}
          />
        </div>
      </div>
      <div className={classes.VRContainer}>
        <img
          alt="VR Experience 2"
          className={classes.Image}
          src={VRScene2Image}
        />
        <div className={classes.ButtonContainer}>
          <Button
            formatID={"takealookaround.button2"}
            onClick={() => {
              window.location =
                "/frl_vr/vr_experience_2.html?language=" +
                selectedLanguage.code;
            }}
            text={"VR Scene 2"}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedLanguage: makeSelectSelectedLanguage(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LookAround);
