// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_CONTRACTOR_REQUEST_SUCCESS,
  UPDATE_CONTRACTOR_REQUEST_ERROR,
  UPDATE_CONTRACTOR_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";
import { error as err, success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* updateContractorRequest(data) {
  try {
    const params = new URLSearchParams();
    params.append("id", data.id);
    params.append("notes", data.notes);
    params.append("is_active", data.isActive ? "True" : "False");
    params.append("phone", data.phoneNumber);
    params.append("jdenumber", data.jdeNumber);
    params.append("company_id", data.companyId);

    if (!isNil(data?.email)) {
      params.append("email", data.email);
    }

    if (!isNil(data?.firstName)) {
      params.append("first_name", data.firstName);
    }

    if (!isNil(data?.lastName)) {
      params.append("last_name", data.lastName);
    }

    const response = yield call(axios.put, `/contractors/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_CONTRACTOR_REQUEST_ERROR,
        data: response.error,
      });
      yield call(err, response.data.error);

      if (
        !isNil(response?.data?.details) &&
        !isEmpty(response?.data?.details)
      ) {
        Object.keys(response.data.details).map((item) => {
          err(`${item} : ${response.data.details[item]}`);
        });
      }
    } else {
      yield put({
        type: UPDATE_CONTRACTOR_REQUEST_SUCCESS,
        data: response.data,
      });
      yield call(success, "Contractor updated");

      data.callBack();
    }
  } catch (error) {
    console.log("error", { error });
    yield call(
      err,
      !isNil(error?.response?.data?.detail)
        ? error.response.data.detail
        : "Something went wrong"
    );
    // yield call(err, error?.data?.detail);

    yield put({
      type: UPDATE_CONTRACTOR_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* updateContractorRequestSaga() {
  yield takeLatest(UPDATE_CONTRACTOR_REQUEST, updateContractorRequest);
}
