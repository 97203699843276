import React, { useState, useEffect } from "react";

import { Formik } from "formik";
import { connect } from "react-redux";
import queryString from "query-string";
import TickIcon from "./tick.png";
//action start
import { staffVerifyRequest, resetError } from "store/auth/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import { makeSelectLoading, makeSelectError } from "store/auth/selector";
//selectors end
import Input from "components/Supervisor/input";
import classes from "./verify.module.scss";
import validationSchema from "./validationSchema";

// https://hsdemoui.immersivetechnology.nz/staff-verify?token=0UnddF7rxCYIPYIzBJJBuXVegoY2QCmQ&email=abdullahcangoktas@gmail.com

const MainLogo =
  "https://immersivefrl.s3-ap-southeast-2.amazonaws.com/fl_logo_small.png";

const StaffVerify = ({ onSubmitForm, resetErrorReq, loading, history }) => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setEmail(parsed.email);
    setToken(parsed.token);
    resetErrorReq();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <img className={classes.logo} src={MainLogo} />
        {!isSuccess ? (
          <>
            <div className={classes.wrapper}>
              <span className={classes.title}>Set password</span>
              <span className={classes.desc}>
                You're one step away from your account creation. Set your
                password and you're good to enjoy Keep Safe.
              </span>
              <span className={classes.info}>
                Requirements: min 8 and max 32 characters
              </span>
              <Formik
                validationSchema={validationSchema}
                initialValues={{ rePassword: "", password: "" }}
                onSubmit={(values) => {
                  onSubmitForm({
                    data: {
                      email: email,
                      token: token,
                      password: values.password,
                    },
                    callback: () => setSuccess(true),
                  });
                }}
              >
                {({ values, handleSubmit, setFieldValue, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                      order={"column"}
                      error={errors.password}
                      type={"password"}
                      label={"Set Password"}
                      placeholder={"Set Password"}
                    />
                    <Input
                      value={values.rePassword}
                      onChange={(e) =>
                        setFieldValue("rePassword", e.target.value)
                      }
                      error={errors.rePassword}
                      type={"password"}
                      order={"column"}
                      label={"Confirm password"}
                      placeholder={"Confirm your new password"}
                    />
                    <button className={classes.button}>Set My Password</button>
                  </form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <>
            <div className={classes.wrapper}>
              <img alt={"Tick"} className={classes.succesIcon} src={TickIcon} />
              <span className={classes.title} style={{ fontSize: "16px" }}>
                Your new password is successfully set
              </span>
              <span className={classes.desc} style={{ textAlign: "center" }}>
                You can now use your password to log in to your account
              </span>
              <button
                onClick={() => history.push("/supervisor-login")}
                className={classes.button}
              >
                Login
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(staffVerifyRequest(val)),
    resetErrorReq: (val) => dispatch(resetError(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffVerify);
