// @flow
import { all, fork } from "redux-saga/effects";
import assesmentRequestSaga from "./sagas/assesmentRequest";
import setAssesmentRequestSaga from "./sagas/setAssesmentRequest";
import createAssesmentRequestSaga from "./sagas/createAssesmentRequest";

function* assesmentSaga() {
  yield all([
    fork(assesmentRequestSaga),
    fork(setAssesmentRequestSaga),
    fork(createAssesmentRequestSaga),
  ]);
}

export default assesmentSaga;
