import React, { useEffect, useState } from "react";
import Menu from "components/Supervisor/menu";
import Button from "components/Supervisor/button";
import { pages } from "../utils";
import QRCode from "react-qr-code";
import classes from "./qr.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getSiteById } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectCurrentSite,
} from "store/supervisor/selector";
import ReactLoading from "react-loading";
import { isNil } from "lodash";

import DownloadIcon from "assets/supervisorIcons/downloadIcon";
import QRCodeScanIcon from "assets/supervisorIcons/qrcodeScan.svg";
import KeepSafe from "assets/supervisorIcons/keepsafe.svg";
import BottomShadow from "assets/supervisorIcons/bottomShadow.svg";
import queryString from "query-string";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const QRCodeGenerator = ({
  getSiteByIdRequest,
  loading,
  currentSite,
  history,
}) => {
  const id = queryString.parse(window.location.search).id;

  const LogoLink =
    "https://immersivefrl.s3-ap-southeast-2.amazonaws.com/fl_logo_small.png";

  const onImageDownload = () => {
    window.print();

    /*
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    */
  };

  const [qrData, setQrData] = useState(false);
  const [qrCodeTypeCheckout, setQrCodeTypeCheckout] = useState(true);
  const [qrCodeTypeSiteInduction, setQrCodeTypeSiteInduction] = useState(true);

  useEffect(() => {
    getSiteByIdRequest({ id });
  }, []);

  useEffect(() => {
    if (!isNil(currentSite) && !isNil(id)) {
      setQrData(currentSite.find((item) => item.id.toString() === id));
    }
  }, [currentSite]);

  return (
    <>
      <Menu pages={pages} id={id} />
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            alt={"back icon"}
            onClick={() => history.push("/supervisor-sites")}
            src={BackIcon}
            className={classes.backIcon}
          />
          <span className={classes.title}>QR Code</span>
          <Button
            width={"167px"}
            onClick={() => onImageDownload()}
            icon={() => {
              return <DownloadIcon />;
            }}
            text={"Download PNG"}
          />
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <div className={classes.qrWrapper}>
            <div className={classes.qrCodeCheckIn}>
              <div className={classes.qrCodeLogoBar}>
                <img src={LogoLink} />
              </div>

              <h2 className={classes.qrTitle}>Check In</h2>

              <div className={classes.qrCodeWrapper}>
                <QRCode
                  id="QRCode"
                  value={`${window.location.origin}/check-in?site=${qrData?.code}`}
                  size={250}
                />
              </div>

              <div className={classes.qrCodeFooter}>
                <div className={classes.qrCodeFooterInner}>
                  <img src={QRCodeScanIcon} />
                  <h2>Scan the QR code above to check in</h2>
                </div>
              </div>

              <br />

              <div className={classes.qrBottomShadow}>
                <img src={BottomShadow} />
              </div>

              <div className={classes.keepsafeBar}>
                <a href="https://keepsafe.work" target="_blank">
                  www.KeepSafe.work
                </a>
                <img src={KeepSafe} />
              </div>
            </div>

            <br />

            <div className={classes.qrCodeCheckOut}>
              <div className={classes.qrCodeLogoBar}>
                <img src={LogoLink} />
              </div>

              <h2 className={classes.qrTitle}>Check out</h2>

              <div className={classes.qrCodeWrapper}>
                <QRCode
                  id="QRCodeCheckOut"
                  value={`${window.location.origin}/check-out?site=${qrData?.code}`}
                  size={250}
                />
              </div>

              <div className={classes.qrCodeFooter}>
                <div className={classes.qrCodeFooterInner}>
                  <img src={QRCodeScanIcon} />
                  <h2>Scan the QR code above to check out</h2>
                </div>
              </div>

              <br />

              <div className={classes.qrBottomShadow}>
                <img src={BottomShadow} />
              </div>

              <div className={classes.keepsafeBar}>
                <a href="https://keepsafe.work" target="_blank">
                  www.KeepSafe.work
                </a>
                <img src={KeepSafe} />
              </div>
            </div>

            <div className={classes.qrCodeSiteInduction}>
              <div className={classes.qrCodeLogoBar}>
                <img src={LogoLink} />
              </div>

              <h2 className={classes.qrTitle}>Site Induction Completion</h2>
              <div className={classes.qrCodeWrapper}>
                <QRCode
                  id="QRCodeSite"
                  value={`${window.location.origin}/site-induction?site=${qrData?.code}&sitemanager=${process.env.REACT_APP_SITEMANAGER_TOKEN}`}
                  size={250}
                />
              </div>

              <div className={classes.qrCodeFooter}>
                <div
                  className={
                    classes.qrCodeFooterInner +
                    " " +
                    classes.qrCodeFooterInnerSiteInduction
                  }
                >
                  <img src={QRCodeScanIcon} />
                  <h2>Scan the QR code above to complete site induction</h2>
                </div>
              </div>

              <br />

              <div className={classes.qrBottomShadow}>
                <img src={BottomShadow} />
              </div>

              <div className={classes.keepsafeBar}>
                <a href="https://keepsafe.work" target="_blank">
                  www.KeepSafe.work
                </a>
                <img src={KeepSafe} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  currentSite: makeSelectCurrentSite(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSiteByIdRequest: (val) => dispatch(getSiteById(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerator);
