import React, { useEffect } from "react";
import classes from "./assesment.module.scss";
import MultipleChoise from "./Questions/MultipleChoise";
import SelecTheImages from "./Questions/SelectTheImages";
import Completion from "../Completion";

const Assesment = ({
  completion,
  assesmentData,
  assesmentStep,
  createNewAssesment,
  setSelectedAssesment,
  setSelectedOption,
  section,
  getProgress,
  result,
  retry,
  setRetry,
}) => {
  useEffect(() => {
    createNewAssesment({ id: section.aid });
  }, []);

  const questions = assesmentData.questions.map((item, index) => {
    if (assesmentStep === index + 1 && item.type === "frl-multi-select") {
      return (
        <div className={classes.questionWrapper}>
          <SelecTheImages
            setSelectedAssesment={setSelectedAssesment}
            assesmentData={assesmentData}
            questionData={item}
            setSelectedOption={setSelectedOption}
            result={result}
            retry={retry}
            setRetry={setRetry}
          />
        </div>
      );
    } else if (assesmentStep === index + 1) {
      return (
        <div className={classes.questionWrapper}>
          <MultipleChoise
            setSelectedAssesment={setSelectedAssesment}
            assesmentData={assesmentData}
            questionData={item}
            setSelectedOption={setSelectedOption}
            result={result}
            section={section}
            retry={retry}
            setRetry={setRetry}
          />
        </div>
      );
    }
  });
  return (
    <div className={classes.Container}>
      {completion ? (
        <div className={classes.CompletionWrapper}>
          <Completion getProgress={getProgress} section={section} />
        </div>
      ) : (
        questions
      )}
    </div>
  );
};

export default Assesment;
