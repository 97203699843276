// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_REGION_REQUEST,
  GET_REGION_REQUEST_ERROR,
  GET_REGION_REQUEST_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";

function* getRegionsRequest(data) {
  try {
    const response = yield call(
      axios.get,
      data.search !== undefined && data.page !== undefined
        ? `/regions/?search=${data.search}?page=${data.page}`
        : data.search !== undefined
        ? `/regions/?search=${data.search}`
        : data.page !== undefined
        ? `/regions/?page=${data.page}`
        : data.id !== undefined
        ? `/regions/?id=${data.id}`
        : "/regions/"
    );
    if (response?.data?.error) {
      yield put({
        type: GET_REGION_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_REGION_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_REGION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getRegionsRequestSaga() {
  yield takeLatest(GET_REGION_REQUEST, getRegionsRequest);
}
