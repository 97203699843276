import {
  LOGIN_REQUEST,
  SUPERVISOR_LOGIN_REQUEST,
  POST_LOGIN_REQUEST,
  SUPERVISOR_LOGOUT,
  RESET_ERROR,
  REMOVE_TOKEN,
  SET_ME_REQUEST,
  GET_ME_REQUEST,
  STAFF_VERIFY_REQUEST,
  CHECK_IN_REQUEST,
  CHECK_IN_STATUS_REQUEST,
  CHECK_OUT_REQUEST,
  SITE_INDUCTION_REQUEST,
  GET_COVID_QUESTION_REQUEST,
  SET_COVID_QUESTION_REQUEST,
  ROLE_CLAIM_REQUEST,
} from "./constants";

export const loginRequest = (payload) => {
  return {
    type: LOGIN_REQUEST,
    ...payload,
  };
};
export const supervisorLoginRequest = (payload) => {
  return {
    type: SUPERVISOR_LOGIN_REQUEST,
    ...payload,
  };
};
export const supervisorLogout = (payload) => {
  return {
    type: SUPERVISOR_LOGOUT,
    ...payload,
  };
};

export const postLoginRequest = (payload) => {
  return {
    type: POST_LOGIN_REQUEST,
    payload,
  };
};
export const removeToken = (payload) => {
  return {
    type: REMOVE_TOKEN,
    payload,
  };
};

export const resetError = (payload) => {
  return {
    type: RESET_ERROR,
    payload,
  };
};

export const getMeRequest = (payload) => {
  return {
    type: GET_ME_REQUEST,
    payload,
  };
};

export const setMeRequest = (payload) => {
  return {
    type: SET_ME_REQUEST,
    payload,
  };
};

export const staffVerifyRequest = (payload) => {
  return {
    type: STAFF_VERIFY_REQUEST,
    payload,
  };
};

export const checkInRequest = (payload) => {
  return {
    type: CHECK_IN_REQUEST,
    payload,
  };
};

export const checkInStatusRequest = (payload) => {
  return {
    type: CHECK_IN_STATUS_REQUEST,
    payload,
  };
};

export const checkOutRequest = (payload) => {
  return {
    type: CHECK_OUT_REQUEST,
    payload,
  };
};

export const siteInductionRequest = (payload) => {
  return {
    type: SITE_INDUCTION_REQUEST,
    payload,
  };
};

export const getCovidQuestionRequest = (payload) => {
  return {
    type: GET_COVID_QUESTION_REQUEST,
    payload,
  };
};

export const seCovidQuestionsRequest = (payload) => {
  return {
    type: SET_COVID_QUESTION_REQUEST,
    payload,
  };
};

export const roleClaimRequest = (payload) => {
  return {
    type: ROLE_CLAIM_REQUEST,
    payload,
  };
};
