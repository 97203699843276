// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_LANGUAGE_REQUEST,
  GET_LANGUAGE_REQUEST_SUCCESS,
  GET_LANGUAGE_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/public";
import { error as err } from "../../../main.js";

function* getLanguages() {
  try {
    const response = yield call(axios.get, "/language/");

    if (response?.data?.error) {
      yield put({
        type: GET_LANGUAGE_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: GET_LANGUAGE_REQUEST_SUCCESS,
        data: response?.data?.languages,
      });
    }
  } catch (error) {
    yield put({
      type: GET_LANGUAGE_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getLanguagesSaga() {
  yield takeLatest(GET_LANGUAGE_REQUEST, getLanguages);
}
