import React from "react";
import classes from "./pagination.module.scss";
import ArrowIcon from "./arrow.svg";
import DisabledArrowIcon from "./arrow-disabled.svg";
import cn from "classnames";
import { isNil } from "lodash";

const getVisiblePages = (page, total) => {
  if (total < 7) {
    return Array.from(
      Array.from(
        {
          length: total,
        },
        (_, i) => i + 1
      )
    );
  } else {
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 2, page - 1, page, page + 1, page + 2, total];
    } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 4, total - 3, total - 2, total - 1, total];
    } else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

const Pagination = ({
  pageCount,
  totalCount,
  itemsPerPage,
  currentPage,
  onChange,
}) => {
  const totalPage = pageCount
    ? pageCount
    : totalCount / Number(itemsPerPage) >
      (totalCount / Number(itemsPerPage)).toFixed(0)
    ? Number((totalCount / Number(itemsPerPage)).toFixed(0)) + 1
    : (totalCount / Number(itemsPerPage)).toFixed(0);

  const visiblePages = getVisiblePages(currentPage, totalPage);

  const firstGroup = visiblePages < 3 ? visiblePages : visiblePages.slice(0, 1);

  if (isNaN(totalCount) || totalCount <= 1 || isNil(totalCount)) {
    return null;
  }

  return (
    <div className={classes.paginationContainer}>
      <span
        onClick={() => {
          if (currentPage !== 1 || totalPage === 1) {
            onChange(currentPage - 1);
          }
        }}
      >
        <img
          className={cn(
            currentPage === 1 ? classes.disabledLeftArrow : classes.leftArrow
          )}
          alt={"left arrow"}
          src={currentPage === 1 ? DisabledArrowIcon : ArrowIcon}
        />
      </span>
      {firstGroup?.map((item, i) => (
        <span
          onClick={() => {
            onChange(item);
          }}
          style={{
            background: item === currentPage && " rgba(165, 180, 201, 0.2)",
          }}
        >
          {item}
        </span>
      ))}{" "}
      {visiblePages?.length >= 3 && (
        <>
          {currentPage >= 5 && (
            <>
              <span> ........ </span>
            </>
          )}
          {visiblePages?.slice(1, visiblePages?.length - 1).map((item, i) => (
            <span
              onClick={() => {
                onChange(item);
              }}
              style={{
                background: item === currentPage && " rgba(165, 180, 201, 0.2)",
              }}
            >
              {item}
            </span>
          ))}
          {Number(currentPage) <= Number(Number(totalPage) - 5) && (
            <span> ........ </span>
          )}
          {visiblePages
            ?.slice(visiblePages?.length - 1, visiblePages?.length)
            ?.map((item, i) => (
              <span
                onClick={() => {
                  onChange(item);
                }}
                style={{
                  background:
                    item === currentPage && " rgba(165, 180, 201, 0.2)",
                }}
              >
                {item}
              </span>
            ))}
        </>
      )}
      <span
        onClick={() => {
          if (currentPage !== totalPage) {
            onChange(currentPage + 1);
          }
        }}
      >
        <img
          className={
            currentPage !== totalPage
              ? classes.rightArrow
              : classes.disabledRightArrow
          }
          alt={"right arrow"}
          src={currentPage !== totalPage ? ArrowIcon : DisabledArrowIcon}
        />
      </span>
    </div>
  );
};

export default Pagination;
