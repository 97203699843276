// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_DASHBOARD_REQUEST_SUCCESS,
  GET_DASHBOARD_REQUEST_ERROR,
  GET_DASHBOARD_REQUEST,
  SET_PERMISSION_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { isNil } from "lodash";

function* getDashboardRequest(data) {
  const params = new URLSearchParams();
  if (!isNil(data?.siteIds)) {
    params.append("siteIds", data.siteIds);
  }
  try {
    const response = yield call(axios.get, `/dashboard/`, { params });
    if (response.data.error) {
      yield put({
        type: GET_DASHBOARD_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_DASHBOARD_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put({
        type: SET_PERMISSION_ERROR,
        data: true,
      });
    }

    yield put({
      type: GET_DASHBOARD_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getDashboardRequestSaga() {
  yield takeLatest(GET_DASHBOARD_REQUEST, getDashboardRequest);
}
