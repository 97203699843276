// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_COVID_QUESTION_REQUEST,
  SET_COVID_QUESTION_REQUEST_SUCCESS,
  SET_COVID_QUESTION_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { isNil } from "lodash";
import { push } from "connected-react-router";

function* setCovidQuestion(data) {
  const values = data.payload;
  const params = new URLSearchParams();
  params.append("is_fully_vaccinated", values.is_fully_vaccinated);
  params.append("is_no_symptoms", values.is_no_symptoms);
  params.append("is_not_awaiting_results", values.is_not_awaiting_results);
  params.append("is_not_isolating", values.is_not_isolating);
  params.append("site", values.site);

  try {
    const response = yield call(axios.post, "/covid-questionnaire/", params);
    if (
      !isNil(values.site) &&
      values.is_fully_vaccinated === 1 &&
      values.is_no_symptoms === 1 &&
      values.is_not_awaiting_results === 1 &&
      values.is_not_isolating === 1
    ) {
      yield put(push(`/${values.redirect}?site=${values.site}`));
    } else if (
      values.is_fully_vaccinated === 1 &&
      values.is_no_symptoms === 1 &&
      values.is_not_awaiting_results === 1 &&
      values.is_not_isolating === 1
    ) {
      yield put(push("/post-login"));
    }
    yield put({
      type: SET_COVID_QUESTION_REQUEST_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: SET_COVID_QUESTION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* setCovidQuestionSaga() {
  yield takeLatest(SET_COVID_QUESTION_REQUEST, setCovidQuestion);
}
