import React, { useEffect } from "react";
import classes from "./step2.module.scss";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import { FormattedMessage } from "react-intl";
//What Could Happen Type
const Step2 = ({ data, section }) => {
  useEffect(() => {
    if (window.document.getElementById("launcher-frame") !== null) {
      window.document.getElementById("launcher-frame").style.bottom = "80px";
    }
  }, []);
  return (
    <div className={classes.StepContainer}>
      <span className={classes.title}>
        <FormattedMessage
          defaultMessage={"What Could Happen?"}
          id={"induction.whatcouldhappen.title"}
        />
      </span>
      <div className={classes.contentWrapper}>
        {data &&
          data.content.map((item, index) => (
            <div key={index}>
              {item.title && (
                <span className={classes.contentTitle}>
                  <FormattedMessage
                    defaultMessage={item.title ? item.title : ""}
                    id={`induction.${section.id}.whatcouldhappen.title.${
                      index + 1
                    }`}
                  />
                </span>
              )}
              <div className={classes.contentText}>
                <FormattedHTMLMessage
                  id={`induction.${section.id}.whatcouldhappen.text.${
                    index + 1
                  }`}
                />
              </div>
            </div>
          ))}
        <div className={classes.ImageContainer}>
          {data &&
            data?.images &&
            data?.images.length !== 0 &&
            data?.images
              .slice(0, 2)
              .map((item, index) => (
                <div
                  key={index}
                  style={{ backgroundImage: `url(${item})` }}
                  className={classes.ImageWrapper}
                ></div>
              ))}
        </div>
        {data?.images?.length === 3 && (
          <div
            className={classes.ImageContainer}
            style={{ marginTop: "-35px" }}
          >
            <div
              style={{ backgroundImage: `url(${data.images[2]})` }}
              className={classes.ImageWrapper}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
