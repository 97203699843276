// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_GENERIC_STAFF_LIST_REQUEST_SUCCESS,
  GET_GENERIC_STAFF_LIST_REQUEST_ERROR,
  GET_GENERIC_STAFF_LIST_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";

function* getStaffList(data) {
  try {
    const response = yield call(
      axios.get,
      data.sort_type !== undefined
        ? `/generic-staff/?search=${data.searchItem}&page=${data.page}&sort=${data.sort}&sort_type=${data.sort_type}`
        : data.sort !== undefined
        ? `/generic-staff/?search=${data.searchItem}&page=${data.page}&sort=${data.sort}`
        : `/generic-staff/?search=${data.searchItem}&page=${data.page}`
    );
    if (response.data.error) {
      yield put({
        type: GET_GENERIC_STAFF_LIST_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_GENERIC_STAFF_LIST_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_GENERIC_STAFF_LIST_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getStaffListSaga() {
  yield takeLatest(GET_GENERIC_STAFF_LIST_REQUEST, getStaffList);
}
