import React from "react";
import classses from "./menuIcon.module.scss";
const MenuIcon = (props) => {
  return (
    <div className={classses.Rectangle} onClick={props.onClick}>
      <div className={classses.Circle}>
        <div className={classses.LineContainer}>
          <div className={classses.Line1} />
          <div className={classses.Line2} />
          <div className={classses.Line3} />
        </div>
      </div>
    </div>
  );
};

export default MenuIcon;
