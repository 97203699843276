import { randomBytes, createHash } from "crypto";

export const base64URLEncode = (str) => {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

export const sha256 = (buffer) => {
  return createHash("sha256").update(buffer).digest();
};

export const credentials = () => {
  return {
    client_id: process.env.REACT_APP_CLIENT_ID,
    response_type: "code",
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_mode: "query",
    state: "1234",
    code_challenge: createPKCECodes(),
    code_challenge_method: "S256",
    scope: "openid",
  };
};

export const staffLoginCredentials = () => {
  return {
    client_id: process.env.REACT_APP_STAFF_CLIENT_ID,
    response_type: "code",
    redirect_uri: process.env.REACT_APP_STAFF_REDIRECT_URI,
    response_mode: "query",
    state: "1234",
    code_challenge: createPKCECodes(),
    code_challenge_method: "S256",
    scope: "openid",
  };
};

export const createPKCECodes = () => {
  const codeVerifier = base64URLEncode(randomBytes(64));
  window.localStorage.setItem("CODEVERIFIER", codeVerifier);
  const codeChallenge = base64URLEncode(sha256(Buffer.from(codeVerifier)));
  const createdAt = new Date();
  const codePair = {
    codeVerifier,
    codeChallenge,
    createdAt,
  };
  return codeChallenge;
};
