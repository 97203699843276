export default [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/supervisor-dashboard",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "People",
    to: "/supervisor-people",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Company",
    to: "/supervisor-company",
    icon: "cil-bar-chart",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sites",
    to: "/supervisor-sites",
    icon: "cil-bar-chart",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Settings",
    to:
      "/supervisor-settings-translations" ||
      "/supervisor-settings-role" ||
      "/supervisor-settings-users",
    icon: "cil-settings",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Reporting",
    to: "/supervisor-reporting-inductions",
    icon: "cil-bar-chart",
  },
];
