// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SITE_REQUEST_SUCCESS,
  GET_SITE_REQUEST_ERROR,
  GET_SITE_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";

function* getSitesRequest(data) {
  try {
    const response = yield call(
      axios.get,
      data.search !== undefined && data.page !== undefined
        ? `/sites/?search=${data.search}?page=${data.page}`
        : data.search !== undefined
        ? `/sites/?search=${data.search}`
        : data.page !== undefined
        ? `/sites/?page=${data.page}`
        : data.id !== undefined
        ? `/sites/?id=${data.id}`
        : "/sites/"
    );
    if (response?.data?.error) {
      yield put({
        type: GET_SITE_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_SITE_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_SITE_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getSitesRequestSaga() {
  yield takeLatest(GET_SITE_REQUEST, getSitesRequest);
}
