import React, { useRef, useState } from "react";
import { Header } from "components";
import classes from "./contractor.module.scss";
import { useOnClickOutside } from "utils/hooks";
import Blur from "components/UI/blur";
const Layout = ({ children, menu }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  useOnClickOutside(node, () => setOpen(false));
  const isLogin = window.location.pathname === "/login";
  return (
    <React.Fragment>
      <div id={"layout"} className={classes.contractorLayout}>
        <div id={"wrapper"} ref={node} className={classes.wrapper}>
          {!isLogin && <Header menu={menu} open={open} setOpen={setOpen} />}
          <Blur radius={open ? "5px" : "0px"} transition="400ms">
            <div
              o
              className={`c-body bg-white ${
                open ? classes.blur : classes.contractorBody
              }`}
            >
              {children}
            </div>
          </Blur>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
