import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//action start
import {
  getStaffListRequest,
  getContractorStats,
} from "store/supervisor/actions";
//action end

//selectors start
import {
  makeSelectStaffList,
  makeSelectError,
  makeSelectLoading,
} from "store/supervisor/selector";
import { makeSelectSupervisor } from "store/auth/selector";

//styles
import classes from "../people.module.scss";

//components
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";

//utils
import { isNil, isEmpty } from "lodash";
import { statusIndicator } from "utils/enums";
import { formatDate } from "utils/helper";

const StaffList = ({ staffList, getStaffList, loading }) => {
  const [staffs, setStaffs] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [searchState, setSearchState] = useState("");

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getStaffList({
        page: 1,
        searchItem: "",
        sort: "",
      });
      setSelectedPage(1);
    }
  };

  useEffect(() => {
    getStaffList({
      page: 1,
      searchItem: "",
      sort: "",
    });
  }, []);

  const staffTabledData = (item) => {
    const data = item?.map((a) => {
      return {
        id: a.id,
        email: a.email,
        values: [
          {
            id: a.id,
            raw: item,
            value: `${a.first_name} ${a.last_name} `,
          },
          {
            value: !isNil(a?.site_status?.status) ? a.site_status.status : "-",
            component: (
              <div className={classes.statusWrapper}>
                <div
                  className={classes.status}
                  style={{
                    background:
                      !isEmpty(a?.site_status?.status) &&
                      !isNil(a?.site_status.status)
                        ? statusIndicator.find(
                            (item) => item.type === a.site_status.status
                          )?.color
                        : "gray",
                  }}
                />
              </div>
            ),
          },

          {
            value:
              !isNil(a?.site_status?.completed) &&
              !isEmpty(a?.site_status.completed)
                ? formatDate(a?.site_status?.completed)
                : "-",
          },

          {
            value:
              !isNil(a?.site_status?.renewal) &&
              !isEmpty(a?.site_status.renewal)
                ? formatDate(a?.site_status?.renewal)
                : "-",
          },
        ],
      };
    });

    const labels = [
      { label: "Name", sortable: true, sortType: "name" },
      { label: "On site induction", width: "200px" },
      { label: "Completed", sortable: true, sortType: "email" },
      { label: "Renewal", sortable: true, sortType: "company" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    if (!isNil(staffList)) {
      setStaffs(staffTabledData(staffList.results));
      const newRows = staffList?.results?.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
    }
  }, [staffList]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getStaffList({
        page: 1,
        searchItem: event.target.value,
        sort: "",
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.indicatorWrapper}>
          {statusIndicator.map((item) => (
            <div className={classes.indicatorItem}>
              <div style={{ background: item.color }} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
        <div className={classes.tableWrapper}>
          <Table
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={staffs}
            apiSort
          />
        </div>
        <Pagination
          totalCount={staffList?.number_of_items}
          pageCount={staffList?.number_of_pages}
          onChange={(val) => {
            getStaffList({
              page: val,
              searchItem: searchState,
              sort: "",
              sort_type: "",
            });
            setSelectedPage(val);
          }}
          currentPage={selectedPage}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  staffList: makeSelectStaffList(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  supervisor: makeSelectSupervisor(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffList: (val) => dispatch(getStaffListRequest(val)),
    getContactorStatistics: (val) => dispatch(getContractorStats(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
