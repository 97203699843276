import React, { useEffect, useState } from "react";
import classes from "./roles.module.scss";

import {
  getRolesRequest,
  getPermissionsRequest,
  createRole,
  deleteRole,
  updateRole,
} from "store/supervisor/actions";
import {
  makeSelectRoles,
  makeSelectLoading,
  makeSelectPermissions,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { groupBy, map } from "lodash";

import Table from "components/Supervisor/table";
import Tags from "components/Supervisor/tags";
import Button from "components/Supervisor/button";
import Modal from "components/Supervisor/modal";
import Pagination from "components/Supervisor/pagination";

import DeleteIcon from "assets/supervisorIcons/deleteIcon.js";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";
import { delay, incrementString } from "utils/helper";

const Roles = ({
  getRoles,
  loading,
  roles,
  getPermissions,
  createRoleRequest,
  deleteRoleRequest,
  history,
}) => {
  const [rolesList, setRolesList] = useState([]);

  const [rawRoles, setRawRoles] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [PAGE_COUNT, setPageCount] = useState(null);
  const [modal, setModal] = useState([]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getRoles({ search: event.target.value });
    }
  };

  const rolesTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: a.name,
            id: a.id,
            raw: item,
          },
          {
            value: a.narrative,
            component: <Tags tags={a.narrative} permissions={a.permissions} />,
          },
          {
            value: a.type,
          },
        ],
      };
    });

    const labels = [
      { label: "Role Name" },
      { label: "Narratives", width: "70%" },
      { label: "Type" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  const rowActions = [
    {
      name: "Edit",
      action: (val) =>
        history.push(`/supervisor-settings-roles-edit?id=${val.id}`),
    },
    {
      name: "Duplicate ",
      action: (val) => {
        const flat = val.values[1].value.flatMap((item) =>
          item.map((a) => a.codename)
        );
        createRoleRequest({
          name: incrementString(`${val.values[0].value}`, rawRoles),
          permissions: flat.toString(),
          key: "duplicate",
          callback: () => getRoles(),
        });
      },
    },
    {
      name: "Delete",
      action: (val) => {
        setModal({
          open: true,
          type: "delete",
          action: () => {
            deleteRoleRequest({
              id: val.id,
              callback: async () => {
                await delay(1000);
                getRoles();
              },
            });
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        });
      },
    },
  ];

  const [searchState, setSearchState] = useState("");

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getRoles();
      setSelectedPage(1);
    }
  };

  const handlePermissions = (item) =>
    map(groupBy(item, "content_type"), (clist) => clist.map((item) => item));

  useEffect(() => {
    if (roles) {
      const newRoles = roles.results.map((item) => {
        return {
          id: item.id,
          name: item.name,
          narrative: handlePermissions(item.permissions),
          permissions: item.permissions,
          type: "Default",
        };
      });
      setRolesList(rolesTableData(newRoles));

      const newRows = roles.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      setRawRoles(roles.results);
      setPageCount(roles.items_per_page);
    }
  }, [roles]);

  return (
    <>
      <>
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "20px",
              }}
            >
              {selectedRows &&
                selectedRows.filter((item) => item.checked).length > 0 && (
                  <Button
                    onClick={() => {
                      setModal({
                        open: true,
                        type: "delete",
                        action: () => {
                          selectedRows
                            .filter((item) => item.checked)
                            .forEach((item, index) => {
                              deleteRoleRequest({
                                id: item.id,
                                end:
                                  index + 1 === selectedRows.length
                                    ? null
                                    : {
                                        callback: () => getRoles(),
                                      },
                              });
                            });
                          setModal({
                            open: false,
                            type: null,
                            action: null,
                            cancel: null,
                          });
                        },
                        cancel: () =>
                          setModal({
                            open: false,
                            type: null,
                            action: null,
                            cancel: null,
                          }),
                      });
                    }}
                    icon={() => {
                      return <DeleteIcon />;
                    }}
                    text={"Delete"}
                    color={"#E26C82"}
                  />
                )}
              <Button
                onClick={() =>
                  history.push("/supervisor-settings-roles-create")
                }
                icon={() => {
                  return <PlusIcon />;
                }}
                text={"New Role"}
              />
            </div>
            <Table
              handleKeyDown={handleKeyDown}
              isSearchable={true}
              searchState={searchState}
              loading={loading}
              onSearch={(e) => onSearch(e)}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              data={rolesList}
              rowActions={rowActions}
              clickable={true}
            />
          </div>
        </>
        {PAGE_COUNT && roles && roles.count > PAGE_COUNT && (
          <Pagination
            totalCount={roles?.count}
            itemsPerPage={roles?.items_per_page}
            currentPage={selectedPage}
            onChange={(val) => {
              getRoles({ page: val });
              setSelectedPage(val);
            }}
          />
        )}
      </>
      <Modal
        open={modal.open}
        cancel={modal.cancel}
        action={modal.action}
        type={modal.type}
        loading={loading}
        deleteTitle={"You are about to delete a role."}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
  loading: makeSelectLoading(),
  permissions: makeSelectPermissions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: (val) => dispatch(getRolesRequest(val)),
    getPermissions: (val) => dispatch(getPermissionsRequest(val)),
    createRoleRequest: (val) => dispatch(createRole(val)),
    deleteRoleRequest: (val) => dispatch(deleteRole(val)),
    updateRoleRequest: (val) => dispatch(updateRole(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
