import React from "react";
import classes from "./checkbox.module.scss";
import CheckIcon from "./checked";
const Checkbox = ({ checked, onClick }) => {
  return (
    <>
      <div
        onClick={onClick}
        className={checked ? classes.checked : classes.unchecked}
      >
        <CheckIcon checked={checked} />
      </div>
    </>
  );
};

export default Checkbox;
